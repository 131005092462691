import styled from 'styled-components';
import ButtonBase from '@/components/app/ButtonBase';
import TextCopy from '@/components/app/TextCopy';
import Icon, { IconVariant } from '@/components/app/Icon';
import {
  ButtonSize,
  ButtonTheme,
  ButtonType,
  ChangeTransitionState,
  LinkTarget,
} from '@/types';
import {
  PropsWithChildren,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import { a11yFocus, fillContainer } from '@/styles/mixins';
import useDeviceProperties from '@/hooks/useDeviceProperties';

import gsap from 'gsap';
import {
  useChangeTransitionState,
  useMountTransition,
} from '@/utils/transition-controller/react';

import {
  setupClickTimeline,
  setupMouseEnterTimeline,
  setupMouseLeaveTimeline,
  setupTransitionInTimeline,
  setupTransitionOutTimeline,
} from '@/hooks/useTransitionsButtonPrimary';
import { useScrollTransition } from '@/utils/scroll/composables/useScrollTransition';
import {
  defaultCanTransitionIn,
  defaultDisableTriggerInOnScroll,
} from '@/utils/transition-controller/react/types/transitionComponent.types';
import { media, mediaDown } from '@/utils/breakpoints';

const StyledButtonBase = styled(ButtonBase)`
  border: 1px solid var(--cl-black);
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  transform: scale(1); // fix safari-desktop glitch hover
  ${a11yFocus()}

  .hover-content {
    pointer-events: none;
    ${fillContainer({})};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hover-background {
    ${fillContainer({ offset: '-1px' })};
    transform-origin: center center;
  }

  .icon {
    position: relative;
    width: 1.9rem;
    margin-left: 0.5rem;
  }

  .arrow-up {
    path {
      fill: currentColor;
    }
  }

  //THEMES

  &.primary-theme {
    background-color: var(--cl-white);
    color: var(--cl-black);

    .hover-content {
      color: var(--cl-black);
    }

    .hover-background {
      background-color: var(--cl-white);
    }
  }

  &.secondary-theme {
    background-color: var(--cl-white);
    color: var(--cl-black);

    .hover-content {
      color: var(--cl-white);
    }

    .hover-background {
      background-color: var(--cl-black);
    }
  }

  // Sizes
  &.small-size {
    height: 4.8rem;
    font-size: 3rem;
    padding: 0 32px;
    border-radius: 2.4rem;
  }

  &.medium-size {
    height: 5.4rem;
    font-size: 2rem;
    padding: 0 25px;
    border-radius: 2.7rem;

    .arrow-up {
      width: 1.9rem;
    }
  }

  &.large-size {
    height: 6.6rem;
    font-size: 3rem;
    padding: 0 35px;
    border-radius: 3.3rem;

    .icon {
      margin-left: 1rem;
    }

    .arrow-up {
      width: 3.1rem;
    }
  }

  ${mediaDown.md2`
    &.small-size,
    &.medium-size{
      height: 3.4rem;
      font-size: 1.5rem;
      padding: 0 2rem;
      border-radius: 1.7rem;

      .arrow-up{
        width: 1.3rem;
      }
    }

    &.large-size{
      height: 4rem;
      font-size: 2rem;
      padding: 0 2rem;
      border-radius: 2rem;

      .icon{
        margin-left: 0.5rem;
      }

      .arrow-up{
        width: 1.3rem;
      }
    }
    .icon{
      width: 1.4rem;
    }
  `}
`;

type ButtonPrimaryProps = {
  className?: string;
  disabled?: boolean;
  'aria-label'?: string;
  'aria-hidden'?: boolean;
  title?: string;
  label?: string;
  rel?: string;
  href?: string;
  type?: ButtonType;
  target?: LinkTarget;
  buttonSize?: ButtonSize;
  buttonTheme?: ButtonTheme;
  icon?: IconVariant;
  isMagnetic?: boolean;
  progress?: number;
  /** transition props **/
  canTransitionIn?: boolean;
  isVisible?: ChangeTransitionState;
  transitionOnMount?: boolean;
  disableTriggerInOnScroll?: boolean;
  mountTransitionDelay?: number;
  transitionInDelay?: number;
  onClick?: (e: SyntheticEvent) => void;
};

const ButtonPrimary = forwardRef(
  (
    {
      buttonSize = 'medium',
      buttonTheme = 'primary',
      /** transition props **/
      disableTriggerInOnScroll = defaultDisableTriggerInOnScroll,
      canTransitionIn = defaultCanTransitionIn,
      transitionOnMount = true,
      isMagnetic = false,
      progress = 0,
      onClick,
      ...props
    }: PropsWithChildren<ButtonPrimaryProps>,
    ref
  ) => {
    const device = useDeviceProperties();
    const mouseEnterTimeline = useRef<gsap.core.Timeline>();
    const mouseLeaveTimeline = useRef<gsap.core.Timeline>();
    const clickTimeline = useRef<gsap.core.Timeline>();

    const elementRef = useRef<typeof ButtonBase>(null);
    const labelRef = useRef<typeof TextCopy>(null);
    const hoverBackgroundRef = useRef<HTMLDivElement>(null);
    const hoverLabelRef = useRef<typeof TextCopy>(null);
    const hoverIconRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<HTMLDivElement>(null);

    const isMouseEnter = useRef<boolean>(false);
    const mouseEnterFinished = useRef<boolean>(true);

    useImperativeHandle(ref, () => elementRef.current, []);

    const { transitionController } = useScrollTransition({
      scroll: {
        canTransitionIn: canTransitionIn,
        disableTriggerInOnScroll: disableTriggerInOnScroll,
        trigger: elementRef,
        transitionInDelay: props.transitionInDelay,
      },
      setupOptions: () => ({
        refs: {
          elementRef,
          labelRef,
          hoverLabelRef,
          hoverIconRef,
          iconRef,
        },
        ref: elementRef,
        setupTransitionInTimeline,
        setupTransitionOutTimeline,
        onStart: (direction) => {
          if (direction === 'out') {
            gsap.set(elementRef?.current, {
              pointerEvents: 'none',
            });

            handleMouseLeave();
          }
        },
      }),
    });

    useMountTransition(
      transitionOnMount,
      transitionController,
      props.mountTransitionDelay,
      canTransitionIn
    );
    useChangeTransitionState(transitionController, props.isVisible);

    async function handleMouseEnter(): Promise<void> {
      if (device.isTouch() || progress > 0) return;
      // if(transitionController.isHidden) return
      mouseEnterFinished.current = false;
      isMouseEnter.current = true;
      // await mouseLeavePromise;
      if (mouseLeaveTimeline.current) {
        mouseLeaveTimeline.current?.kill();
      }
      mouseEnterTimeline.current?.restart().then(() => {
        mouseEnterFinished.current = true;
      });
    }

    function handleClick(event: SyntheticEvent): void {
      onClick && onClick(event);
      clickTimeline.current?.restart();
    }

    function handleMouseLeave(): void {
      if (device.isTouch() || progress > 0) return;
      // if(transitionController.isHidden) return

      isMouseEnter.current = false;

      if (!mouseEnterFinished.current) {
        mouseEnterTimeline.current?.reverse();
      } else {
        if (mouseEnterTimeline.current) {
          mouseEnterTimeline.current?.kill();
        }

        mouseLeaveTimeline?.current?.restart();
      }
    }

    useEffect(() => {
      mouseEnterTimeline.current = gsap.timeline({ paused: true });
      mouseLeaveTimeline.current = gsap.timeline({ paused: true });

      setupMouseEnterTimeline(mouseEnterTimeline.current, {
        hoverBackgroundRef,
        hoverLabelRef,
        hoverIconRef,
        iconRef,
      });

      //if (!device.isTouch()) {
      setupMouseLeaveTimeline(mouseLeaveTimeline.current, {
        hoverBackgroundRef,
        hoverLabelRef,
        hoverIconRef,
        iconRef,
      });
      //}
      clickTimeline.current = setupClickTimeline({ elementRef });

      return () => {
        if (mouseEnterTimeline.current) {
          mouseEnterTimeline.current.kill();
          mouseEnterTimeline.current.revert();
        }
        if (mouseLeaveTimeline.current) {
          mouseLeaveTimeline.current.kill();
          mouseLeaveTimeline.current.revert();
        }
        if (clickTimeline.current) {
          clickTimeline.current.kill();
          clickTimeline.current.revert();
        }
      };
    }, []);

    return (
      <StyledButtonBase
        ref={elementRef}
        className={`${props.className} ${buttonTheme}-theme ${buttonSize}-size`}
        type={props.type}
        disabled={props.disabled}
        href={props.href}
        target={props.target}
        rel={props.rel}
        label={props.label}
        title={props.title}
        ariaLabel={props['aria-label']}
        ariaHidden={props['aria-hidden']}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <TextCopy
          ref={labelRef}
          label={props.label}
          transitionInType={'splitCharFadeIn'}
          transitionOutType={'fadeOut'}
          transitionOnMount={false}
          ariaHidden={true}
          className={'label'}
        />

        {props.icon && (
          <div ref={iconRef} className="icon-holder">
            <Icon
              className={`icon ${props.icon as string}`}
              name={props.icon}
            />
          </div>
        )}

        <div className={'hover-content'}>
          <div ref={hoverBackgroundRef} className={'hover-background'} />
          <TextCopy
            ref={hoverLabelRef}
            label={props.label}
            transitionInType={'splitCharFadeIn'}
            transitionOutType={'fadeOut'}
            transitionOnMount={false}
            className={'label'}
            ariaHidden={true}
          />

          {props.icon && (
            <div ref={hoverIconRef} className="hover-icon-holder">
              <Icon
                className={`icon ${props.icon as string}`}
                name={props.icon}
              />
            </div>
          )}
        </div>
      </StyledButtonBase>
    );
  }
);

export default ButtonPrimary;
