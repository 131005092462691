import { ButtonType, LinkTarget } from "@/types";
import { useCta } from "@/hooks/useCta";
import { Link } from "react-router-dom";
import { MouseEventHandler, PropsWithChildren, forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";



type ButtonBaseProps = {
  className: string;
  onClick?: MouseEventHandler<HTMLElement>;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  ariaLabel?: string;
  ariaHidden?: boolean;
  title?: string;
  label?: string;
  rel?: string;
  href?: string;
  type?: ButtonType;
  target?: LinkTarget;
};

const StyledButtonBase = styled.button`
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    text-decoration: none;
    color: currentColor;
    cursor: pointer;
    overflow: visible;
    //text-align: center;;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;

    &:focus{
      outline: 0;
    }

    &[disabled]{
      cursor: default;
    }

    .is-submitting & {
      pointer-events: none;
    }
`;


const ButtonBase = forwardRef((props: PropsWithChildren<ButtonBaseProps>, ref) => {
  const {
    url,
    rel,
    buttonType,
    target,
    title,
    /* handleClick, isExternalUrl */ ariaLabel,
  } = useCta(props);

  const elementRef = useRef(null);
  useImperativeHandle(ref, () => elementRef.current, []);
  return (
    <>
      {
        url
          ? <StyledButtonBase
            as={Link}
            ref={elementRef}
            className={`${props.className}`}
            to={url}
            rel={rel}
            title={title}
            /* disabled={props.disabled || false} */
            aria-label={ariaLabel || props.label}
            aria-hidden={props.ariaHidden || undefined}
            target={target}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
          >
            {props.children}
          </StyledButtonBase >
          : <StyledButtonBase
            as="button"
            ref={elementRef}
            className={`${props.className}`}
            title={title}
            disabled={props.disabled || false}
            aria-label={ariaLabel || props.label}
            aria-hidden={props.ariaHidden || undefined}
            type={buttonType}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
          >
            {props.children}
          </StyledButtonBase >
      }
    </>
  );
})

export default ButtonBase;
