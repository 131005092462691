import { Brand } from "@/interfaces/Brand";

const brand: Brand = {
  id: "paolo-carzana",
  name: "Paolo Carzana",
  designers: [
    {
      id: "paolo-carzana",
      name: "Paolo Carzana",
    },
  ],
  components: [
    {
      componentId: 'DesignerVideoBlock',
      content: {
        id: 'video1',
        videoSrc: '/videos/paolo-carzana/walk.mp4'
      }
    },
    {
      componentId: 'DesignerShowroom',
      content: {
        id: 'showroom1',
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection1'
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection2'
      }
    }
  ]
};
export default brand;
