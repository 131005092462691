export class LogEase {
  constructor ({ current = 0, ease = 0.3, minDist = 0.0001 }) {
    this.current = current
    this.to = current
    this.ease = ease
    this.minDist = minDist
  }

  process = () => {
    let ease = this.ease
    if (isNaN(ease)) { ease = 1 }

    this.current += (this.to - this.current) * ease
    if (Math.abs(this.to - this.current) < this.minDist) {
      this.current = this.to
    }
    return this.current
  }

  update = (to) => {
    if (to !== undefined && !isNaN(to)) {
      this.to = to
    }
    this.process()
    return this.current
  }

  set = (to) => {
    if (to !== undefined && !isNaN(to)) {
      this.to = to
    }
    this.current = this.to
  }
}

export default LogEase
