import styled from 'styled-components';
import { media, mediaDown } from '@/utils/breakpoints';

export const HeaderText = styled.h2`
  font-family: 'HelveticaNeue';
  font-weight: 400;
  font-style: normal;

  font-size: 25px;
  line-height: 20px;
  letter-spacing: -1.75px;

  margin: 0;
  padding: 0;

  ${media.sm`
    font-size: 33px;
    line-height: 28.05px;
    letter-spacing: -1.65px;
  `}

  ${media.md`
    font-size: 45px;
    line-height: 37.8px;
    letter-spacing: -2.25px;
  `}
`;

export const FooterText = styled.p`
  font-family: 'HelveticaNeue';
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;

  font-size: 14px;
  line-height: 11.76px;
  letter-spacing: -0.42px;

  margin: 0;
  padding: 0;

  ${media.sm`
    font-size: 15px;
    line-height: 12.6px;
    letter-spacing: -0.45px;
  `}

  ${media.md`
    font-size: 16px;
    line-height: 13.44px;
    letter-spacing: -0.48px;
  `}
`;

export const MarkerText = styled.h3`
  font-family: 'PermanentMarker';
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;

  margin: 0;
  padding: 0;

  font-size: 19px;
  line-height: 25.77px;

  ${media.sm`
    font-size: 26px;
    line-height: 37.88px;
  `}

  ${media.md`
    font-size: 22px;
    line-height: 29.14px;
  `}
`;

export const PortraitBrandText = styled.h4`
  font-family: 'HelveticaNeue';
  font-weight: 500;
  font-style: normal;

  margin: 0;
  padding: 0;

  font-size: 11px;
  line-height: 7.12px;
  letter-spacing: -0.36px;

  ${media.sm`
    font-size: 14px;
    line-height: 11.2px;
    letter-spacing: -0.56px;
  `}

  ${media.md`
    font-size: 12px;
    line-height: 10.46px;
    letter-spacing: -0.48px;
  `}
`;

export const PortraitNameText = styled.h5`
  font-family: 'HelveticaNeue';
  font-weight: 400;
  font-style: italic;

  margin: 0;
  padding: 0;

  font-size: 17.09px;
  line-height: 14.65px;

  ${media.sm`
    font-size: 14.55px;
    line-height: 12.56px;
    letter-spacing: -0.58px;
  `}

  ${media.md`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 12.66px;
    letter-spacing: -0.64px;
  `}
`;

export const GridToggleText = styled.p`
  font-family: 'HelveticaNeue';
  font-weight: 500;
  font-style: normal;

  margin: 0;
  padding: 0;

  font-size: 12px;
  line-height: 10.46px;
  letter-spacing: -0.48px;
`;

export const CursorText = styled.p`
  font-family: 'HelveticaNeue';
  font-weight: 500;
  font-style: normal;

  margin: 0;
  padding: 0;

  font-size: 18px;
  line-height: 18px;  
`;

export const BackText = styled.p`
  font-family: 'HelveticaNeue';
  font-weight: 500;
  font-style: normal;

  margin: 0;
  padding: 0;

  font-size: 18px;
  line-height: 18px;

  ${mediaDown.sm`
    font-size: 14px;
    line-height: 14px;
  `}
`;

export const VoteHeaderText = styled.p`
  font-family: 'HelveticaNeue';
  font-weight: 400;
  font-style: normal;

  margin: 0;
  padding: 0;

  font-size: 27px;
  line-height: 25px;
  text-transform: uppercase;

  ${media.md2`
    font-size: 47px;
    line-height: 42.43px;
  `}
`;

export const VoteDateText = styled.p`
  font-family: 'HelveticaNeue';
  font-weight: 400;
  font-style: normal;

  margin: 0;
  padding: 0;

  font-size: 18px;
  line-height: 15.75px;
  text-transform: capitalize;

  ${media.md2`
    font-size: 28px;
    line-height: 24px;
  `}
`;

export const VoteCounterText = styled.p`
  font-family: 'HelveticaNeue';
  font-weight: 400;
  font-style: normal;

  margin: 0;
  padding: 0;

  font-size: 48px;
  line-height: 40.85px;
  text-transform: uppercase;

  ${media.md2`
    font-size: 120px;
    line-height: 103.33px;
  `}
`;

export const VoteSuccessText = styled.p`
  font-family: 'HelveticaNeue';
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.4px;

  margin: 0;
  padding: 0;

  font-size: 80.469px;
  line-height: 72.422px;
  
  ${media.md2`
  font-size: 15rem;
  line-height: 14.463rem;
  `}
`;

export const VoteStayTunedText = styled.p`
  font-family: 'HelveticaNeue';
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.04em;

  margin: 0;
  padding: 0;

  font-size: 2rem;
  line-height: 2.414rem;

  ${media.md2`
    font-size: 4rem;
    line-height: 4.821rem;
  `}
`;
