import styled from "styled-components";
import DesignerBiography from "./DesignerBiography";
import DesignerPortrait from "./DesignerPortrait";
import { mediaDown } from "@/utils/breakpoints";

declare interface DesignerDetailsProps {
  name: string
  designers: Array<{ id: string, name: string }>
  websiteUrl: string;
  instagramUrl: string;
  id: string;
  altText: string;
  description: string;
  nationality: string;
  workplace: string;
}

const StyledDesignerDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .description-block{
    margin-top: 66rem;
  }

  ${mediaDown.md2`
    flex-direction: column;

    .description-block{
      margin-top: 20rem;
    }
  `}
`;

export default function DesignerDetails({
  designers,
  name,
  websiteUrl,
  instagramUrl,
  id,
  altText,
  description,
  nationality,
  workplace,
}: DesignerDetailsProps) {

  return (
    <StyledDesignerDetails>
      <DesignerPortrait
        id={id}
        designers={designers}
        name={name}
        nationality={nationality}
        workplace={workplace}
        websiteUrl={websiteUrl}
        instagramUrl={instagramUrl}
        altText={altText}
      />

      <DesignerBiography
        className={"description-block"}
        description={description}
      />
    </StyledDesignerDetails>
  );
}
