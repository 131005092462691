import gsap from "gsap";
import type ButtonBase from "@/components/app/ButtonBase";
import type TextCopy from "@/components/app/TextCopy";
import { fadeFromTo, fadeTo } from "@/utils/animation/fade/fade";
import { findTransitionController } from "@/utils/transition-controller/core";
import { RefObject } from "react";

// BUTTON PRIMARY
export interface ButtonPrimaryRefs {
  elementRef?: RefObject<typeof ButtonBase>;
  labelRef?: RefObject<typeof TextCopy>;
  hoverBackgroundRef?: RefObject<HTMLDivElement>;
  hoverIconRef?: RefObject<HTMLDivElement>;
  iconRef?: RefObject<HTMLDivElement>;
  hoverLabelRef?: RefObject<typeof TextCopy>;
}

export function setupTransitionInTimeline(
  timeline: gsap.core.Timeline,
  { elementRef, labelRef, hoverIconRef, iconRef }: ButtonPrimaryRefs
): void {
  if (hoverIconRef?.current) {
    timeline.add(
      fadeFromTo(hoverIconRef.current, {
        to: 0,
        duration: 0.001,
        clearProps: "",
      }),
      0
    );
  }

  if (elementRef?.current) {
    timeline.add(
      fadeFromTo(elementRef.current, {
        duration: 0.2,
        autoAlpha: false,
        disablePointerEvents: true,
        clearProps: "",
      }),
      0
    );
    timeline.fromTo(
      elementRef.current,
      { width: 0, scale: 1 },
      {
        scale: 1,
        width: "auto",
        clearProps: "scale, width",
        ease: "Quad.easeOut",
        duration: 0.2,
      },
      0
    );
  }

  if (labelRef?.current) {
    const controller = findTransitionController(labelRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline, 0.1);
  }

  if (iconRef?.current) {
    timeline.fromTo(
      iconRef.current,
      { xPercent: -100, yPercent: 100 },
      { xPercent: 0, yPercent: 0, duration: 0.3, ease: "Power2.easeOut" },
      0.2
    );
    timeline.add(fadeFromTo(iconRef.current), 0.2);
  }
}

export function setupTransitionOutTimeline(
  timeline: gsap.core.Timeline,
  {
    elementRef,
    labelRef,
    hoverLabelRef,
    hoverIconRef,
    iconRef,
  }: ButtonPrimaryRefs
): void {
  if (elementRef?.current) {
    timeline.add(
      fadeTo(elementRef.current, {
        duration: 0.2,
        autoAlpha: false,
        disablePointerEvents: true,
      }),
      0
    );
    timeline.to(
      elementRef.current,
      {
        // width: 0,
        scale: 0.7,
        ease: "Quad.easeOut",
        duration: 0.2,
      },
      0
    );
  }

  if (labelRef?.current) {
    const controller = findTransitionController(labelRef.current);
    timeline.add(controller?.getTimeline("out") as gsap.core.Timeline, 0);
  }

  if (hoverLabelRef?.current) {
    const controller = findTransitionController(hoverLabelRef.current);
    timeline.add(controller?.getTimeline("out") as gsap.core.Timeline, 0);
  }

  if (hoverIconRef?.current && iconRef?.current) {
    timeline.add(fadeTo([hoverIconRef.current, iconRef.current]), 0);
  }
}

export function setupMouseEnterTimeline(
  timeline: gsap.core.Timeline,
  {
    hoverBackgroundRef,
    hoverLabelRef,
    hoverIconRef,
    iconRef,
  }: ButtonPrimaryRefs
): gsap.core.Timeline {
  if (hoverBackgroundRef?.current) {
    
    timeline.fromTo(
      hoverBackgroundRef?.current,
      { scaleX: 0, transformOrigin: "left center" },
      {
        scaleX: 1,
        transformOrigin: "left center",
        ease: "Power2.easeOut",
        duration: 0.3,
      },
      0
    );
  }

  if (hoverLabelRef?.current) {
    const controller = findTransitionController(hoverLabelRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline, 0);
  }

  if (hoverIconRef?.current) {
    timeline.fromTo(
      hoverIconRef.current,
      { xPercent: -100, yPercent: 100 },
      { xPercent: 0, yPercent: 0, duration: 0.3, ease: "Power2.easeOut" },
      0.2
    );
    timeline.add(fadeFromTo(hoverIconRef.current), 0.2);
  }

  return timeline;
}

export function setupMouseLeaveTimeline(
  timeline: gsap.core.Timeline,
  {
    hoverBackgroundRef,
    hoverLabelRef,
    hoverIconRef,
    iconRef,
  }: ButtonPrimaryRefs
): gsap.core.Timeline {
  if (hoverBackgroundRef?.current) {
    timeline.to(
      hoverBackgroundRef?.current,
      {
        keyframes: { scaleX: [1, 0] },
        transformOrigin: "right center",
        ease: "Power2.easeOut",
        duration: 0.3,
      },
      0.2
    );
  }

  if (iconRef?.current) {
    timeline.fromTo(
      iconRef.current,
      { xPercent: -100, yPercent: 100 },
      { xPercent: 0, yPercent: 0, duration: 0.3, ease: "Power2.easeOut" },
      0.2
    );
    timeline.add(fadeFromTo(iconRef.current), 0);
  }

  if (hoverLabelRef?.current) {
    const controller = findTransitionController(hoverLabelRef.current);
    timeline.add(controller?.getTimeline("out") as gsap.core.Timeline, 0);
  }

  if (hoverIconRef?.current) {
    timeline.to(
      hoverIconRef.current,
      { xPercent: 100, yPercent: -100, duration: 0.3, ease: "Power2.easeOut" },
      0.2
    );
    timeline.add(fadeTo(hoverIconRef.current), 0);
  }

  return timeline;
}

export function setupClickTimeline({
  elementRef,
}: ButtonPrimaryRefs): gsap.core.Timeline {
  const timeline = gsap.timeline({ paused: true });

  if (elementRef?.current) {
    timeline.to(
      elementRef.current,
      { scale: 0.9, ease: "Power2.easeOut", duration: 0.12 },
      0
    );
    timeline.to(elementRef.current, {
      scale: 1,
      ease: "Power2.easeOut",
      duration: 0.12,
    });
  }

  return timeline;
}
