import { create, type StoreApi, type UseBoundStore } from 'zustand';
import { scrollTargetTo } from '@/utils/scroll/scroll.utils';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// Auto generate selectors
// https://docs.pmnd.rs/zustand/guides/auto-generating-selectors
type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never;

const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
  _store: S
) => {
  const store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (const k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};

interface AppState {
  pageLoaded: boolean;
  setPageLoaded: (pageLoaded: boolean) => void;
  scroller: ScrollSmoother | null;
  setScroller: (scroller: ScrollSmoother) => void;
  scrollY: number;
  setScrollY: (scrollY: number) => void;
  scrollLocked: boolean;
  scrollTo: (
    x: number,
    y: number,
    duration?: number,
    options?: any
  ) => Promise<void>;
  toggleScrollerPause: (val?: boolean) => void;
  shouldWatchHeight: boolean;
  setShouldWatchHeight: (shouldWatchHeight: boolean) => void;
  progress: number;
  setProgress: (progress: number) => void;
  hideFooter: boolean;
  hideFooterVoteCta: boolean;
  toggleHideFooter: (val?: boolean) => void;
  toggleHideFooterVoteCta: (val?: boolean) => void;
  hoverPortrait: { x: number; y: number };
  setHoverPortrait: (hoverPortrait: { x: number; y: number }) => void;
  designerId: string;
  setDesignerId: (designerId: string) => void;
  activeContentOverlayId: string | null;
  setActiveContentOverlayId: (value: string | null) => void;
}

const useAppStoreBase = create<AppState>()((set, get) => ({
  pageLoaded: false,
  setPageLoaded: (pageLoaded) => set({ pageLoaded }),
  scroller: null,
  setScroller: (scroller) => set({ scroller }),
  scrollY: 0,
  setScrollY: (scrollY) => set({ scrollY }),
  scrollLocked: false,
  scrollTo: (x: number, y: number, duration?: number, options?: any) => {
    if (get().scroller) {
      return new Promise((resolve) => {
        gsap.to(get().scroller, {
          scrollTop: y,
          duration: duration ? duration / 1000 : 0,
          ease: options?.easing || 'Power1.easeInOut',
          onComplete: () => {
            resolve();
            if (options?.callback) {
              options.callback();
            }
          },
        });
      });
    } else {
      return scrollTargetTo(window, x, y, duration, options);
    }
  },
  toggleScrollerPause: (val) =>
    set((state) => {
      let scrollLocked;
      if (val !== undefined) {
        scrollLocked = val;
      } else {
        scrollLocked = !state.scrollLocked;
      }

      if (state.scroller) {
        // LOCK SMOOTH SCROLL SCROLLER
        state.scroller?.paused(scrollLocked);
        if (!scrollLocked) {
          ScrollTrigger.refresh();
        }
      } else {
        // LOCK NATIVE SCROLL
        if (scrollLocked) {
          // disableBodyScroll(el || document.querySelector('.the-footer'))
          // console.log('lock')
          // lock()
          document.documentElement.style.touchAction = 'none';
          document.body.style.touchAction = 'none';
          document.documentElement.style.setProperty(
            'overscroll-behavior',
            'none'
          );
        } else {
          // clearAllBodyScrollLocks()
          // console.log('unlock')
          // unlock()
          document.body.style.touchAction = 'unset';
          document.documentElement.style.touchAction = 'unset';
          document.documentElement.style.setProperty(
            'overscroll-behavior',
            null
          );
        }
      }
      return {
        ...state,
        scrollLocked,
      };
    }),
  shouldWatchHeight: false,
  setShouldWatchHeight: (shouldWatchHeight) => set({ shouldWatchHeight }),
  progress: 0,
  setProgress: (progress) => set({ progress }),

  hoverPortrait: { x: 0, y: 0 },
  setHoverPortrait: (hoverPortrait) => set({ hoverPortrait }),

  designerId: '',
  setDesignerId: (designerId) => set({ designerId }),

  // *********************************************
  // FOOTER
  // *********************************************
  hideFooter: false,
  hideFooterVoteCta: false,
  toggleHideFooter: (val) =>
    set((state) => {
      if (val !== undefined) {
        state.hideFooter = val;
      } else {
        state.hideFooter = !state.hideFooter;
      }
      return state;
    }),
  toggleHideFooterVoteCta: (val) =>
    set((state) => {
      if (val !== undefined) {
        state.hideFooterVoteCta = val;
      } else {
        state.hideFooterVoteCta = !state.hideFooterVoteCta;
      }
      return state;
    }),
  activeContentOverlayId: null,
  setActiveContentOverlayId: (activeContentOverlayId) => set({ activeContentOverlayId }),
}));

export const useAppStore = createSelectors(useAppStoreBase);
