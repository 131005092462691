import styled from 'styled-components';
import Image from '@/components/app/Image';
import ButtonPrimary from '@/components/app/ButtonPrimary';
import { useEffect, useRef } from 'react';
import { aspectRatioSize } from '@/styles/mixins';
import TextCopy from '../app/TextCopy';
import {
  setupTransitionInTimeline,
  setupTransitionOutTimeline,
} from '@/hooks/useTransitionsDesignerPortrait';
import { useScrollTransition } from '@/utils/scroll/composables/useScrollTransition';
import { mediaDown } from '@/utils/breakpoints';
import { ChangeTransitionState } from '@/types';
import gsap from 'gsap';
import { defaultCanTransitionIn } from '@/utils/transition-controller/react/types/transitionComponent.types';

declare interface DesignerPortraitProps {
  id: string;
  designers: Array<{ id: string; name: string }>;
  websiteUrl: string;
  instagramUrl: string;
  altText: string;
  nationality: string;
  workplace: string;
  name: string;
  canTransitionIn?: boolean;
  isVisible?: ChangeTransitionState;
  transitionOnMount?: boolean;
  disableTriggerInOnScroll?: boolean;
  mountTransitionDelay?: number;
  transitionInDelay?: number;
}

const DesignerPortraitWrapper = styled.div`
  position: relative;
  width: 50.7rem;
  .ratio-holder {
    ${aspectRatioSize({ width: 668, height: 762 })}
    width: 100%;
    background-color: var(--cl-white);
    border: 1px solid var(--cl-black);
    overflow: hidden;
  }
  .image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .cta-holder {
    position: absolute;
    left: 20px;
    bottom: 20px;
    display: flex;
  }
  .cta {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
  .information {
    position: absolute;
    left: 0;
    top: calc(100%);
    overflow: hidden;
    border: 1px solid var(--cl-black);
    border-top: none;
    width: 100%;
    padding: 2rem;
    list-style: none;
    background: var(--cl-white-alpha);
    backdrop-filter: blur(25px);
  }
  .information-item {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.3rem;
  }

  ${mediaDown.md2`
    width: 100%;

    .cta-holder{
      left: 20px;
      bottom: 15px;
    }

    .cta{
      padding: 0 1.6rem;

      &:not(:first-child){
        margin-left: 10px;
      }
    }

    .information{
      text-align: center;
      width: 100%;
    }

    .information-item{
      text-transform: uppercase;
      font-size: 1.3rem;
      line-height: 1.8rem;
      font-weight: 400;
    }
  `}

  ${mediaDown.md`
    .cta-holder{
      left: 10px;
      bottom: 15px;
    }
  `}
`;

export default function DesignerPortrait({
  id,
  designers,
  name,
  altText,
  websiteUrl,
  instagramUrl,
  nationality,
  workplace,
  disableTriggerInOnScroll = false,
  canTransitionIn = defaultCanTransitionIn,
  ...props
}: DesignerPortraitProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const imageWrapperRef = useRef<HTMLDivElement | null>(null);
  const instagramCtaRef = useRef<typeof ButtonPrimary>(null);
  const websiteCtaRef = useRef<typeof ButtonPrimary>(null);
  const nationalityRef = useRef<typeof TextCopy>(null);
  const workplaceRef = useRef<typeof TextCopy>(null);

  useScrollTransition({
    scroll: {
      canTransitionIn: canTransitionIn,
      disableTriggerInOnScroll: disableTriggerInOnScroll,
      trigger: elementRef,
      transitionInDelay: props.transitionInDelay,
    },
    setupOptions: () => ({
      refs: {
        elementRef,
        imageWrapperRef,
        // wipeRef,
        instagramCtaRef,
        websiteCtaRef,
        nationalityRef,
        workplaceRef,
      },
      ref: elementRef,
      setupTransitionInTimeline,
      setupTransitionOutTimeline,
    }),
  });

  useEffect(() => {
    const images = gsap.utils.toArray(
      imageWrapperRef.current?.querySelectorAll('picture') || []
    );
    let ctx: gsap.Context;
    if (images.length > 1) {
      ctx = gsap.context(() => {
        const tl = gsap.timeline({ repeat: -1 });
        const fadeDuration = 0.3;
        const stayDuration = 5;

        gsap.set(images, { autoAlpha: 0 });
        gsap.set(images[0] as HTMLImageElement, { autoAlpha: 1 });

        tl.to(images.slice(1), {
          delay: stayDuration,
          autoAlpha: 1,
          duration: fadeDuration,
          stagger: stayDuration + fadeDuration,
        })
          .to(
            images.slice(0, images.length - 1),
            {
              autoAlpha: 0,
              duration: 0.01,
              stagger: stayDuration + fadeDuration,
            },
            stayDuration + fadeDuration
          )
          .set(images[0] as HTMLImageElement, { autoAlpha: 1 })
          .to(
            images[images.length - 1] as HTMLImageElement,
            { autoAlpha: 0, duration: fadeDuration },
            '+=' + stayDuration
          );
      });
    }

    return () => {
      ctx?.revert();
    };
  }, []);

  return (
    <DesignerPortraitWrapper ref={elementRef}>
      <div className="ratio-holder">
        <div className={'image-wrapper'} ref={imageWrapperRef}>
          {[...designers].map((designer, index) => {
            return (
              <Image
                key={`${index}-${designer.id}`}
                loading="eager"
                fillAbsolute={true}
                objectFit={'cover'}
                className="image"
                src={`/images/${id}/${designer.id}.jpeg`}
                alt={altText}
              />
            );
          })}
        </div>
        <div className="cta-holder">
          <ButtonPrimary
            ref={instagramCtaRef}
            className={'cta'}
            label="INSTAGRAM"
            ariaL-label="INSTAGRAM`"
            buttonTheme={'secondary'}
            buttonSize={'medium'}
            icon={'arrowUp'}
            transitionOnMount={false}
            disableTriggerInOnScroll={true}
            href={instagramUrl}
          />

          {/http/.test(websiteUrl) && <ButtonPrimary
            ref={websiteCtaRef}
            className={'cta'}
            label="WEBSITE"
            aria-label="WEBSITE"
            buttonTheme={'secondary'}
            button-size={'medium'}
            icon={'arrowUp'}
            transitionOnMount={false}
            disableTriggerInOnScroll={true}
            href={websiteUrl}
          />}
        </div>
      </div>
      <ul className={'information'}>
        <TextCopy
          ref={nationalityRef}
          as="li"
          className={'information-item'}
          label={nationality}
          transitionInType={'splitLinesSlideDownIn'}
          transitionOnMount={false}
          disableTriggerInOnScroll={true}
        />
        <TextCopy
          ref={workplaceRef}
          as="li"
          className={'information-item'}
          label={workplace}
          transitionInType={'splitLinesSlideDownIn'}
          transitionOnMount={false}
          disableTriggerInOnScroll={true}
        />
      </ul>
    </DesignerPortraitWrapper>
  );
}
