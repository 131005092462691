import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MENU_HEIGHT } from "@/constants/global";
import { motion } from "framer-motion";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { media } from "@/utils/breakpoints";
import { useEffect, useRef } from "react";
import { BackText } from "@/styles/typography.style";
import { useAppStore } from "@/store";

declare interface ButtonProps {
  className?: string;
}

const TextWrapper = styled(BackText).attrs({ as: motion.a })`
  position: fixed;
  top: calc(${MENU_HEIGHT}px + 12px);
  left: 20px;
  z-index: 7;

  cursor: pointer;

  ${media.sm`
    left: 44px;
  `}

  ${media.md`
  `}

  > .back-to-char {
    display: inline-block;
    text-decoration: underline;
    white-space: break-spaces;
  }
`;

export default function BackToCandidates({ className }: ButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scrollTriggerRef = useRef<ScrollTrigger>();

  const activeContentOverlayId = useAppStore.use.activeContentOverlayId();
  const setDesignerId = useAppStore.use.setDesignerId();
  const scrollTo = useAppStore.use.scrollTo();

  const copy = t("general.goBackCta").split("");

  // Create scroll trigger
  useEffect(() => {
    const showTl = gsap.timeline({});
    showTl.from(".back-to-char", {
      y: -6,
      opacity: 0,
      textDecoration: "none",
      textDecorationColor: "transparent",
      duration: 0.3,
      stagger: -0.03,
    });
    showTl.from(
      ".back-to-wrapper",
      {
        pointerEvents: "none",
        duration: 0.3,
      },
      "<"
    );
    showTl.progress(1);

    scrollTriggerRef.current = ScrollTrigger.create({
      animation: showTl,
      start: "top top",
      end: "max",
      onUpdate: (self) => {
        self.direction === -1 ? showTl.play() : showTl.reverse();
      },
    });

    return () => scrollTriggerRef.current?.kill();
  }, []);

  // Close nav when overlay opens
  useEffect(() => {
    if (activeContentOverlayId) scrollTriggerRef.current?.animation?.reverse();
  }, [activeContentOverlayId]);

  function onClick() {
    setDesignerId("");
    scrollTo(0, 0);
    const data = { page: "", name: "" };
    window.parent.postMessage(data, "*");
    navigate("/");
  }

  return (
    <TextWrapper
      className={`${className} back-to-wrapper`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      tabIndex={0}
      onClick={onClick}
      onKeyUp={(e) => {
        if (e.key === "Enter") onClick();
      }}
      aria-label={t("general.goBackCta")}
    >
      {copy.map((char, charIndex) => {
        return (
          <span key={charIndex} className="back-to-char" aria-hidden={true}>
            {char}
          </span>
        );
      })}
    </TextWrapper>
  );
}
