import { GridToggleText } from "@/styles/typography.style";
import { media } from "@/utils/breakpoints";
import styled from "styled-components";

declare interface ToggleProps {
  className?: string;
  isToggled: boolean;
  isScrolling: boolean;
  onToggleClick: () => void;
}

const EASING_DURATION = 500;

const ToggleWrapper = styled.div<{ $isScrolling: boolean }>`
  position: fixed;
  right: 20px;
  top: 160px;
  width: 76px;
  display: flex;
  justify-content: space-between;

  opacity: ${({ $isScrolling }) => ($isScrolling ? "0.15" : "1")};
  transition: opacity ease ${EASING_DURATION}ms;

  ${media.sm`
    display: none;
  `}
`;

// prettier-ignore
const ButtonWrapper = styled(GridToggleText).attrs({ as: "a" })<{ $isToggled: boolean; }>`
  position: relative;

  &::after {
    content: "";
    height: 1px;
    width: ${({ $isToggled }) => ($isToggled ? "100%" : "0")};
    background: black;
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: width 0.6s ease;
  }
`;

export default function GridToggle({
  className,
  isToggled,
  isScrolling,
  onToggleClick,
}: ToggleProps) {
  return (
    <ToggleWrapper className={className} onClick={onToggleClick} $isScrolling={isScrolling}>
      <ButtonWrapper $isToggled={isToggled} arial-label="Grid">
        Grid
      </ButtonWrapper>
      <ButtonWrapper $isToggled={!isToggled} arial-label="Column">
        Column
      </ButtonWrapper>
    </ToggleWrapper>
  );
}
