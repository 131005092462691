import { useCallback, useContext, useRef, useState } from "react";
import styled from "styled-components";
import { PortraitBrandText } from "@/styles/typography.style";
import { Brand } from "@/interfaces/Brand";
import { media } from "@/utils/breakpoints";
import Face from "./Face";
import { PORTRAIT_SCALE_DURATION, PORTRAIT_OPACITY_DURATION } from "@/constants/global";
import AppContext from "@/context/AppContext";
import { useAppStore } from "@/store";

declare interface PortraitProps {
  className?: string;
  number: number;
  brand: Brand;
  cursorPosition: {
    cursorX: number;
    cursorY: number;
  };
  scrollY: number;
  onFaceLoaded: (brandId: string) => void;
  onFaceEnter: (brandId: string) => void;
  onFaceLeave: (brandId: string) => void;
  onFaceClick: (brandIndex: number, brandId: string) => void;
  isMobileGrid: boolean;
  isNavBar: boolean;
  isFocusing: boolean;
  isStatic: boolean;
  isVisible: boolean;
  isSelected?: boolean;
  aspectRatio?: "portrait" | "square";
}

// prettier-ignore
const PortraitWrapper = styled.div<{ $isMobileGrid: boolean; $isFocusing: boolean; $isVisible: boolean }>`
  width: ${({ $isMobileGrid }) => $isMobileGrid ? "100%" : "155px"};
  position: relative;
  scroll-snap-align: center;
  transition: opacity ${PORTRAIT_OPACITY_DURATION}ms;

  ${media.sm`
    scroll-snap-align: none !important;
    max-width: none;
  `}

  > .transform-wrapper {
    display: flex;
    flex-direction: column;
    padding: ${({ $isMobileGrid }) =>
      $isMobileGrid ? "10px 0 26px 0" : "40px 0"};
    height: 100%;
    width: 100%;
    position: relative;
    transition: transform ${PORTRAIT_SCALE_DURATION}ms;

    ${media.sm`
      padding-top: 24px;
      padding-bottom: 28px;
    `};

    ${media.md`
      height: 100%;
      padding-top: 20px;
      padding-bottom: 28px;
    `};
  }

  opacity: ${({ $isFocusing, $isVisible }) => {
    switch (true) {
      case $isFocusing && $isVisible:
        return "1";
      case $isFocusing:
        return "0.15";
      default:
        return "1";
    }
  }};

  @media (max-width: 599px) {
    pointer-events: ${({ $isMobileGrid }) => $isMobileGrid ? "auto" : "none"};

    opacity: ${({ $isMobileGrid, $isFocusing, $isVisible }) => {
      switch (true) {
        case !$isMobileGrid && $isFocusing && $isVisible:
          return "1";
        case !$isMobileGrid && $isFocusing:
          return "0";
        case !$isMobileGrid && $isVisible:
          return "0.98";
        case !$isMobileGrid:
          return "0.15";
        default:
          return "0.99";
      }
    }};

    > .transform-wrapper {
      transform: ${({ $isMobileGrid, $isVisible }) =>
        !$isMobileGrid && $isVisible ? "scale(1.4)" : "none"};
    }
  }
`;

const BrandWrapper = styled(PortraitBrandText)<{ $isFocusing: boolean }>`
  position: absolute;
  z-index: 1;
  top: calc(100% - 34px);

  ${media.sm`
    position: absolute;
    top: calc(100% - 27px);
    z-index: 2;
    margin-top: 5px;
  `}

  ${media.md`
    top: calc(100% - 27px);
    z-index: 6;
    margin-top: 4px;
  `}

  &.tiny-text {
    @media (max-width: 600px) {
      position: absolute;
      top: calc(100% - 26px);
      font-size: 9px;
      line-height: 10.46px;
      letter-spacing: -0.36px;
      margin-top: 2px;
    }
  }
`;

const InteractiveArea = styled.span<{ $isMobileGrid: boolean }>`
  @media (max-width: 600px) {
    ${({ $isMobileGrid }) =>
      !$isMobileGrid &&
      `
    display: none;
    `}
  }

  position: absolute;
  width: 100%;
  cursor: pointer;

  // Fallback for aspect-ratio
  // Note: Safari v17 does not size 1fr grid-columns correctly if the content uses aspect-ratio.
  &::before {
    float: left;
    padding-top: 125.4237%;
    content: "";
  }
  &::after {
    display: block;
    content: "";
    clear: both;
  }
`;

export default function Portrait({
  className,
  number = 0,
  brand,
  cursorPosition,
  scrollY,
  onFaceLoaded,
  onFaceEnter,
  onFaceLeave,
  onFaceClick,
  isMobileGrid,
  isNavBar,
  isFocusing,
  isStatic,
  isVisible,
  isSelected,
  aspectRatio = "portrait",
}: PortraitProps) {
  const [isHover, setIsHover] = useState(false);

  const setHoverPortrait = useAppStore.use.setHoverPortrait();

  const handleFaceLoaded = useCallback(
    (brandId: string) => {
      onFaceLoaded(brandId);
    },
    [onFaceLoaded]
  );

  const { activeBrandId } = useContext(AppContext);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLDivElement>(null);

  return (
    <PortraitWrapper
      ref={wrapperRef}
      className={`${className} ${activeBrandId === brand.id ? `in-focus` : ``}`}
      $isMobileGrid={isMobileGrid}
      $isFocusing={isFocusing}
      $isVisible={isVisible}
      data-brand-id={brand.id}
      onMouseEnter={() => {
        setIsHover(true);
        if (wrapperRef.current) {
          setHoverPortrait({
            x: wrapperRef.current?.offsetLeft + wrapperRef.current?.offsetWidth * 0.5,
            y: wrapperRef.current?.offsetTop + wrapperRef.current?.offsetHeight * 0.5,
          });
        }
        onFaceEnter(brand.id);
      }}
      onMouseLeave={() => {
        setIsHover(false);
        setHoverPortrait({ x: 0, y: 0 });
        onFaceLeave(brand.id);
      }}
      id={"portrait-" + brand.id}
    >
      <div className="transform-wrapper">
        <Face
          className="face"
          brand={brand}
          cursorPosition={cursorPosition}
          scrollY={scrollY}
          onFaceLoaded={handleFaceLoaded}
          isMobileGrid={isMobileGrid}
          isNavBar={isNavBar}
          isStatic={isStatic || isHover}
          aspectRatio={aspectRatio}
        />
        {!isNavBar && (
          <BrandWrapper
            ref={nameRef}
            className={isMobileGrid ? "tiny-text" : ""}
            $isFocusing={isFocusing}
          >
            {brand.name}
          </BrandWrapper>
        )}
        <InteractiveArea
          className="interactive-area"
          $isMobileGrid={isMobileGrid}
          tabIndex={0}
          aria-label={`Candidate ${number} - ${brand.name}${isSelected ? " selected" : ""}`}
          onClick={() => onFaceClick(number, brand.id)}
          onKeyUp={(e) => {
            if (e.key === "Enter") onFaceClick(number, brand.id);
          }}
        />
      </div>
    </PortraitWrapper>
  );
}
