import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { ChangeTransitionState, TextTransitionType } from '@/types';
import { useMountTransition } from '@/utils/transition-controller/react';
import { useScrollTransition } from '@/utils/scroll/composables/useScrollTransition';
import {
  setupTransitionInTimeline,
  setupTransitionOutTimeline,
} from '@/hooks/useTransitionsTextCopy';
import {
  defaultCanTransitionIn,
  defaultDisableTriggerInOnScroll,
} from '@/utils/transition-controller/react/types/transitionComponent.types';

type TextCopyProps = {
  as?:
    | 'p'
    | 'li'
    | 'span'
    | 'time'
    | 'label'
    | 'div'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5';
  // size: TextSize;
  className: string;
  label?: string | number;
  ariaHidden?: boolean;
  transitionInType?: TextTransitionType;
  transitionOutType?: TextTransitionType;

  /** transition props **/
  canTransitionIn?: boolean;
  isVisible?: ChangeTransitionState;
  transitionOnMount?: boolean;
  disableTriggerInOnScroll?: boolean;
  mountTransitionDelay?: number;
};

const TextCopy = forwardRef(
  (
    {
      disableTriggerInOnScroll = defaultDisableTriggerInOnScroll,
      canTransitionIn = defaultCanTransitionIn,
      as = 'p',
      // size: defaultTextSize,
      transitionInType = 'splitWordFadeSlideUp',
      transitionOutType = 'fadeOut',
      transitionOnMount = defaultCanTransitionIn,
      ...props
    }: TextCopyProps,
    ref
  ) => {
    const elementRef = useRef(null);
    const canTransitionInRef = useRef(canTransitionIn || null);
    useImperativeHandle(ref, () => elementRef.current, []);

    const { transitionController } = useScrollTransition({
      scroll: {
        canTransitionIn: canTransitionIn, // canTransitionInRef,
        disableTriggerInOnScroll: disableTriggerInOnScroll,
        trigger: elementRef,
      },
      setupOptions: () => ({
        refs: {
          elementRef,
          transitionInType: transitionInType!,
          transitionOutType: transitionOutType!,
        },
        ref: elementRef,
        setupTransitionInTimeline,
        setupTransitionOutTimeline,
      }),
    });

    // if (props.transitionOnMount) {
    //   useMountTransition(transitionController, props.mountTransitionDelay, canTransitionInRef);
    // }
    // useMountTransition(transitionOnMount, transitionController, props.mountTransitionDelay, canTransitionIn);

    const useAriaLabel = useMemo(() => {
      if (!['fadeOut', 'fadeIn', 'none'].includes(transitionInType || '')) {
        return {
          'aria-label': props.label as string,
        };
      } else {
        return {};
      }
    }, [transitionInType]);

    if (as === 'span') {
      return (
        <span
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'p') {
      return (
        <p
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'li') {
      return (
        <li
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'div') {
      return (
        <div
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'h1') {
      return (
        <h1
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'h2') {
      return (
        <h2
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'h3') {
      return (
        <h3
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'h4') {
      return (
        <h4
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'h5') {
      return (
        <h5
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'label') {
      return (
        <label
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else if (as === 'time') {
      return (
        <time
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    } else {
      return (
        <p
          ref={elementRef}
          {...useAriaLabel}
          aria-hidden={props.ariaHidden}
          className={`text-copy ${props.className}`}
          dangerouslySetInnerHTML={{ __html: props.label as string }}
        />
      );
    }
  }
);

export default TextCopy;
