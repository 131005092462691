import sides from "@/constants/sides";

function calculateDistance(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

export default function getCursorDirection(
  cursorX: number,
  cursorY: number,
  centerX: number,
  centerY: number
) {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const distanceFromCenter = calculateDistance(cursorX, cursorY, centerX, centerY);
  const centerRadius = 150 / 2;
  const isCenter = distanceFromCenter <= centerRadius;
  const isMid = !isCenter && distanceFromCenter <= Math.min(screenHeight, screenWidth) / 2.5;

  let direction = sides.CENTER;

  if (!isCenter) {
    const angle = Math.atan2(cursorY - centerY, cursorX - centerX);
    const angleDegrees = (angle * (180 / Math.PI) + 360) % 360;
    const directions = Object.values(sides).slice(1);
    const index = Math.round(angleDegrees / 45) % 8;

    direction = directions[index];
  }

  return {
    direction,
    isMid,
  };
}
