import { t } from 'i18next';

import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import ButtonPrimary from '@/components/app/ButtonPrimary';

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	-webkit-backdrop-filter: contrast(0.6) brightness(2.3) blur(30px);
	backdrop-filter: contrast(0.6) brightness(2.3) blur(30px);

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 20px;
	text-align: center;
	color: black;
	pointer-events: auto;

	.icon {
		width: 92px;

		svg path {
			fill: black;
		}
	}

	.description {
		font-size: 25px;
		line-height: 1;
		text-transform: uppercase;
		max-width: 540px;
		padding: 0 20px;
		text-shadow: 0 0 4px rgba(255, 255, 255, 0.6);
		margin: 4rem auto;
	}
`;

export default function LowBatteryMessage() {
	const videoRef = useRef<HTMLVideoElement>(null);

	const [show, setShow] = useState<boolean>(true);
	const [lowPowerMode, setLowPowerMode] = useState<boolean>(false);

	function onClick(): void {
		setShow(false);
		setLowPowerMode(false);
	}

	const onCanPlayThrough = () => {
		setLowPowerMode(false);
		setShow(false);
	};

	useEffect(() => {
		if (videoRef.current) {
			const playPromise = videoRef.current?.play() || Promise.resolve();

			playPromise
				.then(() => {
					//console.log('Low power mode: false');
				})
				.catch((error: Error) => {
					if (
						error &&
						typeof error === 'object' &&
						error.name === 'NotAllowedError'
					) {
						console.log('------- Low power mode: true', error);
						console.log('Low power mode: true', error);
						setLowPowerMode(true);
					}
				});
		}
	}, [videoRef]);

	return show ? (
		!lowPowerMode ? (
			<video
				src={'/blank.mp4'}
				preload="auto"
				autoPlay
				loop
				muted
				playsInline
				ref={videoRef}
				onCanPlayThrough={onCanPlayThrough}
			/>
		) : (
			<Wrapper>
				<div className="icon-holder">
					<svg
						width="92"
						height="92"
						viewBox="0 0 92 92"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="icon"
					>
						<circle cx="46" cy="46" r="46" fill="white" />
						<path
							d="M39.7148 48.8074L49.2753 36.9707L46.4071 45.9666H52.1434L42.583 56.8564L45.4511 48.8074H39.7148Z"
							fill="black"
						/>
						<rect
							x="23.5554"
							y="33.2429"
							width="44.7428"
							height="26.5143"
							rx="2.9"
							stroke="black"
							strokeWidth="2.48571"
						/>
						<path
							d="M67.0586 39.457H71.2014C72.5743 39.457 73.6872 40.5699 73.6872 41.9427V51.057C73.6872 52.4298 72.5743 53.5427 71.2014 53.5427H67.0586V39.457Z"
							fill="black"
						/>
					</svg>
				</div>

				<div className="description" tabIndex={lowPowerMode ? 0 : -1}>{t('lowBatteryWarning')}</div>

				<ButtonPrimary
					label={t('lowBatteryButton')}
					onClick={onClick}
				/>
			</Wrapper>
		)
	) : (
		<></>
	);
}
