import gsap from "gsap";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { RefObject, useEffect, useRef } from "react";

/**
 * Registering the Draggable plugin when there is no Window will throw errors,
 * therefore we create this little proxy method to import draggable dynamically,
 */
export const getDraggable = async (): Promise<typeof Draggable | null> => {
  if (typeof window === "undefined") return null;

  const { Draggable } = await import("gsap/Draggable");
  gsap.registerPlugin(Draggable, InertiaPlugin);
  return Draggable;
};

/**
 * Hook to easily use draggable in your components
 */
export const useDraggable = (
  target: RefObject<HTMLElement>,
  vars?: Draggable.Vars,
  options?: {
    bounds?: RefObject<HTMLElement>;
    trigger?: RefObject<HTMLElement> | null;
  }
): {
  draggableInstance: RefObject<Draggable | null>;
  draggableIsReady: RefObject<boolean>;
} => {
  const draggableIsReady = useRef(false);
  const draggableInstance = useRef<Draggable | null>(null);

  useEffect(() => {
    let draggable: Draggable;
    const setupDraggable = async () => {
      const Draggable = await getDraggable();

      if (Draggable && target.current) {
        [draggable] = Draggable.create(target.current, {
          bounds: options?.bounds?.current,
          trigger: options?.trigger?.current,
          ...vars,
        });
        draggableIsReady.current = true;
        draggableInstance.current = draggable;
      }
    };
    if (!draggableInstance.current) setupDraggable();
    return () => {
      draggable?.kill();
    };
  }, []);

  return { draggableInstance, draggableIsReady };
};
