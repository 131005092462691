import { Brand } from "@/interfaces/Brand";

const brand: Brand = {
  id: "niccolo-pasqualetti",
  name: "Niccolò Pasqualetti",
  designers: [
    {
      id: "niccolo-pasqualetti",
      name: "Niccolò Pasqualetti",
    },
  ],
  components: [
    {
      componentId: 'DesignerVideoBlock',
      content: {
        id: 'video1',
        videoSrc: '/videos/niccolo-pasqualetti/walk.mp4'
      }
    },
    {
      componentId: 'DesignerShowroom',
      content: {
        id: 'showroom1',
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection1'
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection2'
      }
    },
    {
      componentId: 'DesignerVideoBlock',
      content: {
        id: 'video2',
        videoSrc: '/videos/niccolo-pasqualetti/brand.mp4'
      }
    }
  ]
};
export default brand;
