'use client';
import { useEffect, useState } from 'react';
import getDeviceProperties from "@/utils/get-device-properties";

const useDeviceProperties = () => {
  const [device, setDevice] = useState(getDeviceProperties());

  useEffect(() => {
    const calculateDeviceProperties = () => {
      setDevice(getDeviceProperties());
    };

    if (typeof window !== 'undefined') {
      calculateDeviceProperties(); // Calculate on initial render
      window.addEventListener('resize', calculateDeviceProperties);

      return () => {
        window.removeEventListener('resize', calculateDeviceProperties);
      };
    }
  }, []);

  return device;
};

export default useDeviceProperties;
