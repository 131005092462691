import { useEffect } from "react";
import type { TransitionController } from "../../core";

/**
 * Creates gsap.core.Timeline that trigger transitionIn/Out onMount and or on change of the isVisible object.
 *
 * @example
 * const myRef = ref<HTMLDivElement>(null);
 *
 * const controller = useTransitionController(() => ({
 *   ref: myRef,
 *   refs: {
 *     myRef,
 *   },
 *   setupTransitionInTimeline(timeline, { myRef }) { ... },
 *   setupTransitionInTimeline(timeline, { myRef }) { ... },
 * }));
 *
 * // Connect the transitionController to the lifecycle
 * useChangeTransitionState(controller, isVisible);
 *
 * @parent template
 * <Block
 *  isVisible={{ // ChangeTransitionState
 *    onMount: true,
 *    value: true,
 *  }}
 * />
 *
 */

export type ChangeTransitionState = {
  onMount?: boolean;
  value?: boolean;
};

export function useChangeTransitionState(
  transitionController: TransitionController,
  isVisible?: ChangeTransitionState
): void {
  useEffect(() => {
    if (!isVisible) return;
    if (isVisible?.onMount && isVisible?.value)
      transitionController.transitionIn();
    if (!isVisible?.value) transitionController.transitionOut();
  }, []);
}
