import type TextCopy from "@/components/app/TextCopy";
import { findTransitionController } from "@/utils/transition-controller/core";
import { RefObject } from "react";
import ButtonPrimary from "@/components/app/ButtonPrimary";

export interface DesignerPortraitRefs {
  elementRef?: RefObject<HTMLElement>;
  imageWrapperRef?: RefObject<HTMLDivElement>;
  wipeRef?: RefObject<HTMLElement>;
  instagramCtaRef?: RefObject<typeof ButtonPrimary>;
  websiteCtaRef?: RefObject<typeof ButtonPrimary>;
  nationalityRef?: RefObject<typeof TextCopy>;
  workplaceRef?: RefObject<typeof TextCopy>;
}

export function setupTransitionInTimeline(
  timeline: gsap.core.Timeline,
  {
    imageWrapperRef,
    wipeRef,
    instagramCtaRef,
    websiteCtaRef,
    nationalityRef,
    workplaceRef,
  }: DesignerPortraitRefs
): void {
  if (imageWrapperRef?.current) {
    timeline.fromTo(
      imageWrapperRef.current,
      { scale: 1.2 },
      {
        scale: 1,
        clearProps: "scale",
        duration: 1,
        ease: "Power2.easeOut",
      },
      0
    );
  }

  if (instagramCtaRef?.current) {
    const controller = findTransitionController(instagramCtaRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline, 0.4);
  }

  if (websiteCtaRef?.current) {
    const controller = findTransitionController(websiteCtaRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline, "<0.2");
  }

  if (workplaceRef?.current) {
    const controller = findTransitionController(workplaceRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline, "<0.1");
  }

  if (nationalityRef?.current) {
    const controller = findTransitionController(nationalityRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline, "<0.05");
  }
}

export function setupTransitionOutTimeline(
  timeline: gsap.core.Timeline,
  { elementRef }: DesignerPortraitRefs
): void {}
