import { FooterText } from "@/styles/typography.style";
import styled from "styled-components";
import { media } from "@/utils/breakpoints";
import TermsButton from "@/components/app/TermsButton";

declare interface FooterProps {
  className?: string;
  showGraduates?: boolean;
}

// prettier-ignore
const FooterWrapper = styled(FooterText).attrs({ as: "div" })`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;

  display: grid;
  align-items: center;
  grid-template-columns: 0.8fr 1.4fr;

  padding: 8px 12px;

  pointer-events: none;

  ${media.sm`
    grid-template-columns: fit-content(100%) fit-content(100%);
    justify-content: space-between;
    grid-gap: 16px;
    padding: 16px 40px;
  `}

  ${media.md`
    padding: 16px 32px;
  `}

  >* {
    pointer-events: auto;
  }
`;

const StyledTermsButton = styled(TermsButton)`
  font-family: inherit;
  font-size: inherit;
  color: inherit;
`;

const CopyrightWrapper = styled.span`
  text-align: end;
  ${media.sm`
    text-align: start;
  `}
`;

const GraduatesWrapper = styled.span`
  text-align: center;
  grid-column: 2;
  grid-row: 1;

  ${media.sm`
    text-align: end;
    grid-column: auto;
    grid-row: auto;
  `}
`;

export default function Footer({ className, showGraduates = false }: FooterProps) {
  return (
    <FooterWrapper className={className}>
      <StyledTermsButton focusable={0} />
      <CopyrightWrapper>©LVMH 2024</CopyrightWrapper>
      {showGraduates && (
        <GraduatesWrapper>
          <a
            href="https://application.lvmhprize.com/"
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Graduates Prize
          </a>
        </GraduatesWrapper>
      )}
    </FooterWrapper>
  );
}
