export const VIDEO_SIZE = { width: 1280, height: 720 };
export const MIN_LOADER_DURATION = 2000;
export const VERTICAL_TRANSLATE_DURATION = 1000;

export const MENU_HEIGHT = 88;

export const DESKTOP_FOCUS_DELAY = 800;
export const MOBILE_FOCUS_DELAY = 1200;
export const MAX_FOCUS_DELAY = Math.max(DESKTOP_FOCUS_DELAY, MOBILE_FOCUS_DELAY);

export const PORTRAIT_OPACITY_DURATION = 500;
export const PORTRAIT_SCALE_DURATION = 500;
export const GRID_ANIMATION_DURATION = 300;

export const MAX_VIDEO_DURATION = 15000;
export const DURATION_PER_COMPONENT = 3750;

export const MAX_NUMBER_VOTES = 8;

export const STATUS_KEY = "_nre-s_";
