import { textTransition } from "@/utils/animation/text/text";
import type { TextTransitionType } from "@/utils/animation/text/text.types";
import { RefObject } from "react";

// TEXT COPY
export interface TextRefs {
  elementRef: RefObject<HTMLElement>;
  transitionInType: TextTransitionType;
  transitionOutType: TextTransitionType;
}

export function setupTransitionInTimeline(
  timeline: gsap.core.Timeline,
  { elementRef, transitionInType }: TextRefs
): void {
  elementRef?.current &&
    timeline.add(textTransition(elementRef.current, transitionInType));
}

export function setupTransitionOutTimeline(
  timeline: gsap.core.Timeline,
  { elementRef, transitionOutType }: TextRefs
): void {
  elementRef?.current &&
    timeline.add(textTransition(elementRef?.current, transitionOutType));
}
