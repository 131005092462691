import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --cl-debug: rgba(255,255,255,0.1);
    --cl-debug-dark: rgba(0,0,0,0.1);
    --cl-error: #b30000;
    --cl-success: #2E8540;
    --cl-black: #000;
    --cl-white: #FFF;
    --cl-white-alpha: rgba(255,255,255,0.65);
    --cl-green: #B4F105;
    --cl-outline: #B4F105;
    

    --vh100max: 100vh;
    --vh100: 100vh;
    --vh100min: 100vh;
    --vh: 1vh;

    --jsvh100min: 100vh;

    --roughMin: calc(100vh - (100vw * 0.3));
    --safe100min: max(var(--roughMin), var(--jsvh100min));

    --spacer-designer-block-mb: 11rem;
    --spacer-designer-page-desktop: 8rem;
    --spacer-designer-page-tablet: 5.5rem;
    --spacer-designer-page-mobile: 2.5rem;
  }

  .base-button {
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    text-decoration: none;
    color: currentColor;
    cursor: pointer;
    overflow: visible;
    //text-align: center;;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;

    &:focus{
      outline: 0;
    }

    &[disabled]{
      cursor: default;
    }

    .is-submitting & {
      pointer-events: none;
    }
  }

  html, body{
    overscroll-behavior: none;
  }
`;

export default GlobalStyle;