import { useEffect, useRef } from "react";
import { Brand } from "@/interfaces/Brand";
import styled from "styled-components";

const EASE_TIME = 800;

declare interface VideoBackgroundProps {
  className?: string;
  brand: Brand;
  shouldLoad: boolean;
  shouldPlay: boolean;
  isVisible: boolean;
}

const BrandVideo = styled.video<{ $isSelected: boolean }>`
  opacity: ${({ $isSelected }) => ($isSelected ? "1" : "0")};
  transition: opacity ease ${EASE_TIME}ms;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
`;

export default function VideoBackground({
  className,
  brand,
  shouldLoad,
  shouldPlay,
  isVisible,
}: VideoBackgroundProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  // TODO: Reenable load
  /* useEffect(() => {
    if (shouldLoad) videoRef.current?.load();
  }, [shouldLoad]); */

  useEffect(() => {
    if(videoRef.current) {
      if(shouldPlay) {
        videoRef.current.play().catch((e) => console.log('==> Background video playback error:', e))
      } else {
        videoRef.current.pause()
      }
    }
  }, [shouldPlay]);

  return (
    <BrandVideo
      ref={videoRef}
      className={className}
      src={`videos/${brand.id}/background.mp4?c=DFOMEBHSW`}
      poster={`videos/${brand.id}/background.webp`}
      preload="metadata"
      muted
      loop
      autoPlay={shouldPlay}
      playsInline
      $isSelected={isVisible}
    />
  );
}
