import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { clamp } from "lodash";
import styled from "styled-components";
import BlurOverlay from "@/components/app/BlurOverlay";
import BackToCandidates from "@/components/grid/BackToCandidates";
import CursorFollower from "@/components/grid/CursorFollower";
import Portrait from "@/components/grid/Portrait";
import brands from "@/config/brands";
import { GRID_ANIMATION_DURATION, MENU_HEIGHT } from "@/constants/global";
import AppContext from "@/context/AppContext";
import { media } from "@/utils/breakpoints";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Draggable from "gsap/Draggable";
import InertiaPlugin from "gsap/InertiaPlugin";
import { useAppStore } from "@/store";
import useDebouncedFocus from "@/hooks/useDebouncedFocus";

gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin);

declare interface NavGridProps {
  className?: string;
  onFaceLoaded: (brandId: string) => void;
  isScrubberEnabled?: boolean;
}

const FOCUS_INDEX = 1;
const SCRUBBER_ELEMENTS = 4;
const CANDIDATES_AMOUNT = 8;

const ScrollWrapper = styled.div`
  display: none;

  ${media.sm`
    display: block;
    position: fixed;
    top: calc(${MENU_HEIGHT}px + 44px);
    left: 44px;
    width: calc(48px * ${SCRUBBER_ELEMENTS} + 4.8px * (${SCRUBBER_ELEMENTS} - 1));
    z-index: 10;

    overflow: hidden;
  `}

  ${media.md`
  `}
`;

const GridWrapper = styled(motion.div)`
  position: relative;
  margin: auto auto;
  padding: 0 120px 0 120px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 4.8px;
  padding: 0;
  overflow: visible;
  align-items: center;
  justify-content: flex-start;

  width: fit-content;

  > .portrait-wrapper .face {
    width: 48px;
  }
`;

const StyledPortrait = styled(Portrait)<{ $isOpaque: boolean }>`
  opacity: ${({ $isOpaque }) => ($isOpaque ? "1" : "0.15")};

  > .transform-wrapper {
    ${media.sm`
      padding-top: 0;
      padding-bottom: 0;
    `};
    ${media.md`
      height: auto;
      padding-top: 0;
      padding-bottom: 0;
    `};
  }
`;

export default function NavGrid({
  className,
  onFaceLoaded = () => {},
  isScrubberEnabled = false
}: NavGridProps) {
  const { activeBrandId, device } = useContext(AppContext);
  const activeContentOverlayId = useAppStore.use.activeContentOverlayId();

  const scrollerRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);
  const scrollTriggerRef = useRef<ScrollTrigger>();
  const draggableRef = useRef<Draggable[]>();

  const [isFocusing, setIsFocusing] = useState(false);
  const debouncedSetIsFocusing = useDebouncedFocus(setIsFocusing);
  const [hoveredBrandId, setHoveredBrandId] = useState("");
  const [cursorPosition, setCursorPosition] = useState({
    cursorX: window.innerWidth / 2,
    cursorY: window.innerHeight / 2,
  });

  const activeIndex = brands.findIndex((b) => b.id === activeBrandId);
  const hoveredIndex = brands.findIndex((b) => b.id === hoveredBrandId);
  const hoveredName = hoveredIndex < 0 ? "" : brands[hoveredIndex].name;

  const navigate = useNavigate();

  //
  // H A N D L E R S
  //

  const handleFaceLoaded = useCallback(
    (brandId: string) => {
      onFaceLoaded(brandId);
    },
    [onFaceLoaded]
  );

  const handleFaceClick = useCallback(
    (brandIndex: number, brandId: string) => {
      if (activeBrandId !== brandId) setIsFocusing(true);
      navigate("/" + brandId);
    },
    [navigate, activeBrandId]
  );

  const handleGridEnter = useCallback(() => {
    setIsFocusing(true);
  }, []);

  const handleGridLeave = useCallback(() => {
    setIsFocusing(false);
  }, []);

  const handleFaceEnter = useCallback((brandId: string) => {
    setHoveredBrandId(brandId);
  }, []);

  //
  // E F F E C T S
  //

  useEffect(() => {
    const onMouseMove = (event: MouseEvent) => {
      setCursorPosition({
        cursorX: event.clientX,
        cursorY: event.clientY,
      });
    };

    const onTouchMove = (event: TouchEvent) => {
      setCursorPosition({
        cursorX: event.touches[0].pageX,
        cursorY: event.touches[0].pageY,
      });
    };

    if (device?.isDesktop) {
      document.addEventListener("mousemove", onMouseMove);
      return () => document.removeEventListener("mousemove", onMouseMove);
    } else {
      document.addEventListener("touchmove", onTouchMove);
      return () => document.removeEventListener("touchmove", onTouchMove);
    }
  }, [device?.isDesktop]);

  // Scrub the navbar
  // TODO: Put in separate file.
  useEffect(() => {
    // Make sure we reset the focus state when navigating between pages.
    debouncedSetIsFocusing(false);

    const gridElement = gridRef.current;
    if (gridElement) {
      let portraitWidth = 0;
      let gap = 0;

      if (gridRef.current) {
        const firstChild = gridRef.current.firstChild as HTMLElement;
        // not the most efficient way but it will do for now
        gap = parseFloat(getComputedStyle(gridRef.current).columnGap);
        portraitWidth = firstChild.offsetWidth + gap;
      }

      const targetX = portraitWidth * (FOCUS_INDEX - activeIndex) + gap;
      const clampedX = clamp(targetX, (SCRUBBER_ELEMENTS - CANDIDATES_AMOUNT) * portraitWidth, 0);

      gsap.to(gridElement, {
        x: clampedX,
        duration: GRID_ANIMATION_DURATION / 1000,
      });
    } else {
      gsap.to(gridElement, {
        x: 0,
        duration: GRID_ANIMATION_DURATION / 1000,
      });
    }
  }, [activeIndex, debouncedSetIsFocusing]);

  // Create scroll trigger for navbar
  // TODO: Move to another file?
  useEffect(() => {
    const showTl = gsap.timeline({});
    showTl.from(".navigation-grid-wrapper .portrait-wrapper", {
      yPercent: -100,
      opacity: 0,
      duration: 0.3,
      stagger: -0.03,
      clearProps: "opacity",
    });
    showTl.from(
      ".navigation-drag-wrapper",
      {
        pointerEvents: "none",
        duration: 0.3,
      },
      "<"
    );
    showTl.progress(1);

    scrollTriggerRef.current = ScrollTrigger.create({
      animation: showTl,
      start: "top top",
      end: "max",
      onUpdate: (self) => {
        self.direction === -1 ? showTl.play() : showTl.reverse();
      },
    });

    return () => {
      scrollTriggerRef.current?.kill();
    };
  }, []);

  // Draggable
  useEffect(() => {
    draggableRef.current = Draggable.create(gridRef.current, {
      type: "x",
      bounds: scrollerRef.current,
      dragResistance: 0.5,
      edgeResistance: 0.75,
      inertia: true,
    });

    return () => {
      draggableRef.current?.forEach((d) => d.kill());
    };
  }, []);

  // Close nav when overlay opens
  useEffect(() => {
    if (activeContentOverlayId) scrollTriggerRef.current?.animation?.reverse();
  }, [activeContentOverlayId]);

  return (
    <motion.div
      className={`${className}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.15, duration: 0.3 }}
    >
      <BackToCandidates />
      {isScrubberEnabled && (
        <ScrollWrapper
          ref={scrollerRef}
          className={`navigation-drag-wrapper`}
          onMouseEnter={handleGridEnter}
          onMouseLeave={handleGridLeave}
        >
          <GridWrapper ref={gridRef} className={`navigation-grid-wrapper`}>
            {brands.map((brand, brandIndex) => (
              <StyledPortrait
                key={brandIndex}
                className="portrait-wrapper"
                number={brandIndex + 1}
                brand={brand}
                cursorPosition={{ cursorX: 0, cursorY: 0 }}
                scrollY={0}
                onFaceLoaded={handleFaceLoaded}
                onFaceEnter={handleFaceEnter}
                onFaceLeave={() => {}}
                onFaceClick={handleFaceClick}
                isMobileGrid={true}
                isNavBar={true}
                isFocusing={false}
                isStatic={true}
                isVisible={true}
                $isOpaque={
                  (!isFocusing && activeBrandId === brand.id) ||
                  (isFocusing && hoveredBrandId === brand.id)
                }
              />
            ))}
          </GridWrapper>
        </ScrollWrapper>
      )}
      <AnimatePresence>{isFocusing && <BlurOverlay />}</AnimatePresence>
      <CursorFollower
        className="cursor"
        text={hoveredName}
        cursorPosition={cursorPosition}
        isVisible={isFocusing && !device?.isTouchEnabled()}
      />
    </motion.div>
  );
}
