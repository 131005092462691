import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import AppContext from "@/context/AppContext";
import useDebouncedFocus from "@/hooks/useDebouncedFocus";
import { useAppStore } from "@/store";
import brands from "@/config/brands";
import VideoBackground from "./VideoBackground";

declare interface BackgroundContainerProps {
  className?: string;
  shouldPlayOnScroll: boolean;
  shouldLoad: boolean;
}

const VideoWrapper = styled.div<{ $isBackgroundVisible: boolean }>`
  position: fixed;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  z-index: -1;

  overflow: hidden;
  transition: opacity ease 500ms;

  > .random-stuff {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 11111;
    color: red;
    opacity: 0;
  }
`;

export default function BackgroundContainer({
  className,
  shouldLoad,
}: BackgroundContainerProps) {
  const { activeBrandId, device, previousBrandId } = useContext(AppContext);
  const [isBackgroundVisible, setIsBackgroundVisible] = useState(false);

  const debouncedSetBackgroundIsVisible = useDebouncedFocus(setIsBackgroundVisible);

  //
  // E F F E C T S
  //

  useEffect(() => {
    if (activeBrandId.length > 0) {
      if (device?.isDesktop || device?.isMobileLayout) debouncedSetBackgroundIsVisible(true);
      else setIsBackgroundVisible(true);
    } else {
      debouncedSetBackgroundIsVisible.cancel();
      setIsBackgroundVisible(false);
    }
  }, [
    activeBrandId,
    device?.isDesktop,
    debouncedSetBackgroundIsVisible,
    isBackgroundVisible,
    device?.isMobileLayout,
  ]);

  return (
    <VideoWrapper className={className} $isBackgroundVisible={isBackgroundVisible}>
      {/* <div className="random-stuff">
        active brand: {activeBrandId} <br />
        previous brand: {previousBrandId}
      </div> */}
      {brands.map((brand, brandIndex) => (
        <VideoBackground
          key={brandIndex}
          brand={brand}
          shouldLoad={shouldLoad}
          shouldPlay={previousBrandId === brand.id || activeBrandId === brand.id}
          isVisible={activeBrandId === brand.id && isBackgroundVisible}
        />
      ))}
    </VideoWrapper>
  );
}
