import type DesignerHeader from "@/components/designer/DesignerHeader";
import { findTransitionController } from "@/utils/transition-controller/core";
import { fadeFromTo, fadeTo } from "@/utils/animation/fade/fade";
import { RefObject } from "react";

export interface DesignerIdPageRefs {
  elementRef?: RefObject<HTMLDivElement>;
  introHeaderRef?: RefObject<typeof DesignerHeader>;
  bottomGlowRef?: RefObject<HTMLDivElement>;
}

export function setupTransitionInTimeline(
  timeline: gsap.core.Timeline,
  { elementRef, introHeaderRef, bottomGlowRef }: DesignerIdPageRefs
): void {
  if (elementRef?.current) {
    timeline.add(fadeFromTo(elementRef.current, { duration: 0.01 }));
  }

  if (introHeaderRef?.current) {
    const controller = findTransitionController(introHeaderRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline, 0);
  }

  if (bottomGlowRef?.current) {
    timeline.add(
      fadeFromTo(bottomGlowRef?.current, {
        duration: 0.8,
        to: 0.25,
        clearProps: "",
      }),
      0.1
    );
  }
}

export function setupTransitionOutTimeline(
  timeline: gsap.core.Timeline,
  { elementRef, bottomGlowRef, introHeaderRef }: DesignerIdPageRefs
): void {
  if (elementRef?.current) {
    timeline.add(fadeTo(elementRef.current, { duration: 0.5 }), 0);
    timeline.add(() => {}, 0.8);
  }
  if (introHeaderRef?.current) {
    const controller = findTransitionController(introHeaderRef.current);
    timeline.add(controller?.getTimeline("out") as gsap.core.Timeline, 0);
  }
  if (bottomGlowRef?.current) {
    timeline.add(fadeTo(bottomGlowRef?.current), 0);
  }
}
