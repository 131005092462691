import styled from "styled-components";
import { PropsWithChildren, forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import Image from "@/components/app/Image"
import { useBrowserStore } from "@/store";
import { media, mediaDown } from "@/utils/breakpoints";
import { useTranslation } from "react-i18next";
import { aspectRatioSize, vhProperty } from "@/styles/mixins";

declare interface DesignerShowroomProps {
  id: string;
  designerId: string;
  dirName: string;
  content: {
    id: string;
    videoSrc: string;
  };
  onOverlayOpen: (id: string) => void;
  onOverlayClose: () => void;
  className?: string
}

const StyledDesignerShowroom = styled.div`
  width: 100%;
  margin-top: 11rem;
  overflow: hidden;

  ${mediaDown.md2`
    margin-top: var(--spacer-designer-block-mb);
  `}

  .showroom-block__scroll__wrapper{
    width: 100%;
    height: 100%;
  }

  .showroom-block__container{
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.portrait-ratio{
      ${aspectRatioSize({ width: 1280, height: 1920 })}
    }

    &.landscape-ratio{
      ${media.md`
        ${aspectRatioSize({ width: 1920, height: 1280 })}
      `}
    }
  }

  img {
    position: relative !important;
  }
`;

const DesignerShowroom = forwardRef(({
  designerId,
  dirName,
  content,
  className,
  ...props
}: PropsWithChildren<DesignerShowroomProps>, ref) => {
  const browser = useBrowserStore();
  const wrapperRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => wrapperRef.current, []);
  const containerRef = useRef<HTMLDivElement>(null)

  const device = useMemo(() => {
    return browser.device === "mobile" || browser.orientation === "portrait" || window.innerWidth < window.innerHeight ? "mobile" : "desktop"
  }, [browser.device, browser.orientation])


  const { t } = useTranslation();
  const showroom: any = t(`designers.${designerId}.${content.id}.images`, { returnObjects: true });
  return (
    <StyledDesignerShowroom id={props.id} ref={wrapperRef} className={`showroom-block__wrapper ${className}`}>
      <div className="showroom-block__scroll__wrapper" data-speed="1.3" data-lag="0.15">
        <div
          ref={containerRef}
          className={`showroom-block__container ${device === 'mobile' ? 'portrait-ratio' : 'landscape-ratio'}`}
          style={{
            transform: `scaleX(${device === 'mobile' ? 1 : -1})`
          }}
        >
          {[...showroom].map((component, index) => {
            return <Image
              key={index}
              loading="eager"
              fillAbsolute={true}
              objectFit={"contain"}
              className="image"
              src={`/images/${designerId}/look/look-${device}.jpg`}
              alt={showroom[index].alt}
            />
          })}
        </div>
      </div >
    </StyledDesignerShowroom >
  );
})
export default DesignerShowroom

