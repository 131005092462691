import { Brand } from "@/interfaces/Brand";

const brand: Brand = {
  id: "standing-ground",
  name: "Standing Ground",
  designers: [
    {
      id: "michael-stewart",
      name: "Michael Stewart",
    },
  ],
  components: [
    {
      componentId: 'DesignerVideoBlock',
      content: {
        id: 'video1',
        videoSrc: '/videos/standing-ground/walk.mp4'
      }
    },
    {
      componentId: 'DesignerShowroom',
      content: {
        id: 'showroom1',
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection1'
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection2'
      }
    },
    {
      componentId: 'DesignerVideoBlock',
      content: {
        id: 'video2',
        videoSrc: '/videos/standing-ground/brand.mp4'
      }
    }
  ]
};
export default brand;
