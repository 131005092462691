import gsap from "gsap";
import type { TransitionController } from "../../core";
import { useEffect, useRef } from "react";

/**
 * Creates gsap.core.Timeline that will start as soon the component is mounted
 *
 * @example
 * const myRef = ref<HTMLDivElement>(null);
 *
 * const controller = useTransitionController(() => ({
 *   ref: myRef,
 *   refs: {
 *     myRef,
 *   },
 *   setupTransitionInTimeline(timeline, { myRef }) { ... },
 *   setupTransitionInTimeline(timeline, { myRef }) { ... },
 * }));
 *
 * // Connect the transitionController to the lifecycle
 * useMountTransition(transitionController);
 */
export function useMountTransition(
  transitionOnMount: boolean,
  transitionController: TransitionController,
  delay = 0,
  canTransitionIn: boolean
): void {
  const delayCallbackRef = useRef<gsap.core.Tween>();

  useEffect(() => {
    if (!transitionOnMount) return;
    if (delay > 0) {
      delayCallbackRef.current = gsap.delayedCall(delay, () => {
        (async () => {
          if (canTransitionIn) {
            await transitionController.transitionIn();
          }
        })();
      });
    } else {
      (async () => {
        if (canTransitionIn) {
          await transitionController.transitionIn();
        }
      })();
    }
    return () => {
      if (delayCallbackRef.current) {
        delayCallbackRef.current.kill();
      }
    };
  }, []);

  useEffect(() => {
    if (!transitionOnMount) return;
    const transitionIn = async () => {
      await transitionController.transitionIn();
    };
    if (canTransitionIn) {
      transitionIn();
    }
  }, [canTransitionIn]);
}
