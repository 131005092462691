import { motion } from "framer-motion";
import Header from "@/components/app/Header";

declare interface IntroProps {
  className?: string;
  isScrolling: boolean;
}

export default function IntroPage({ className, isScrolling }: IntroProps) {
  return (
    <motion.div
      className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.15, duration: 0.3 }}
    >
      <Header className="header" isScrolling={isScrolling} />
    </motion.div>
  );
}
