import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MENU_HEIGHT } from "@/constants/global";
import { Brand } from "@/interfaces/Brand";
import { media } from "@/utils/breakpoints";
import fontSizeMap from "@/assets/font-size-map.json";
import { useAppStore } from "@/store";

const TRANSITION_TIME = 2750;
const LINE_HEIGHT = 0.85;

declare interface PreloaderProps {
  className?: string;
  progress: number;
  isDesktop: boolean;
  brands: Brand[];
}

const PreloaderInnerWrapper = styled.div``;

const BrandNameCharacter = styled.span.attrs<{
  $delay: number;
  $loaded: boolean;
}>(({ $delay, $loaded }) => ({
  style: {
    opacity: $loaded ? 1 : 0,
    transitionDelay: `${$delay}ms`,
  },
}))<{ $loaded: boolean }>`
  opacity: 0;
  transition: opacity 10ms;
`;
const BrandNameWrapper = styled.span``;

// prettier-ignore
const PreloaderWrapper = styled.div<{ $isLoaded: boolean; $loaderHidden: boolean; }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: calc(${MENU_HEIGHT}px + 12px) 20px 40px 20px;
  display: ${({ $loaderHidden }) => ($loaderHidden ? "none" : "block")};
  opacity: ${({ $isLoaded }) => ($isLoaded ? 0 : 1)};
  transition: opacity ${TRANSITION_TIME / 2}ms;
  transition-delay: ${TRANSITION_TIME / 5}ms;
  pointer-events: ${({ $isLoaded }) => ($isLoaded ? "none" : "auto")};

  ${PreloaderInnerWrapper} {
    position: relative;
    opacity: ${({ $isLoaded }) => ($isLoaded ? 0 : 1)};
    transition: opacity ${TRANSITION_TIME / 2}ms;

    &:after {
      content: "¨";
      position: absolute;
      bottom: 0;
      right: 0;
      color: #fa0000;
      text-decoration: none;

      ${media.sm`
        content: "";
      `}
    }
  }

  font-family: "HelveticaNeueStar";
  font-weight: 400;
  background: white;

  ${media.sm`
    padding: calc(${MENU_HEIGHT}px + 20px) 36px 44px 36px;
  `}

  ${media.md`
    padding: calc(${MENU_HEIGHT}px + 24px) 48px 44px 48px;
  `}
`;

const TitleWrapper = styled.div`
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 1px;
  margin-bottom: 50px;

  ${media.sm`
    text-decoration-thickness: 3px;
    text-underline-offset: 2px;
  `}

  ${media.md`
    text-decoration-thickness: 4px;
    text-underline-offset: 3px;
  `}

  &:before {
    content: "";
    display: inline-block;
    color: #fa0000;
    text-decoration: none;
    padding-right: 3px;

    ${media.sm`
      content: "¨";
      padding-right: 9px;
    `}
  }
`;

const mainTitle = "2024 LVMH Prize Finalists";

export default function Preloader({ className, progress, brands }: PreloaderProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const innerWrapperRef = useRef<HTMLDivElement>(null);
  const [loaderHidden, setLoaderHidden] = useState(false);
  const setPageLoaded = useAppStore.use.setPageLoaded();

  /* const progressOrder = useMemo(() => {
    return brands.map((_, index) => index).sort(() => Math.random() - 0.5);
  }, [brands]); */

  useEffect(() => {
    if (progress === 1 && !loaderHidden) {
      const hideLoaderTimeout = setTimeout(() => {
        setLoaderHidden(true);
        setPageLoaded(true);
        //window.scrollTo(0, 0)
        // toggleScrollerPause(false)
      }, TRANSITION_TIME);

      return () => clearTimeout(hideLoaderTimeout);
    }
  }, [progress, loaderHidden, setPageLoaded]);

  function getWordLength(word: string, fontSizeRatio: number) {
    const unknownCharWidth = 8;
    word.split("").map((char) => {
      // @ts-ignore
      if (!fontSizeMap?.[char]) {
        console.warn("==> unknown char", char);
      }
      return null;
    });
    return (
      (word
        .split("")
        // @ts-ignore
        .map((char) => fontSizeMap?.[char] || unknownCharWidth)
        .reduce((acc, charLength) => acc + charLength, 0) +
        fontSizeMap[" "]) *
      fontSizeRatio
    );
  }

  const handleGetTextHeight = useCallback((words: any, width: number, fontSize: number) => {
    let lines = 2.25; // including the empty line
    let currentLineLength = 0;
    const fontSizeRatio = fontSize / 10;

    for (const word in words) {
      const wordLength = getWordLength(words[word], fontSizeRatio);
      if (currentLineLength + wordLength <= width) {
        currentLineLength += wordLength;
      } else {
        currentLineLength = wordLength;
        lines++;
      }
    }

    return lines * fontSize;
  }, []);

  const handleFitText = useCallback(() => {
    if (wrapperRef.current) {
      const str = `${mainTitle} ${brands.map((brand) => brand.name).join(". ")}`;
      const words = str.split(" ");

      const cs = getComputedStyle(wrapperRef.current);
      const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
      const paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

      const boxWidth = wrapperRef.current.offsetWidth - paddingX;
      const boxHeight = wrapperRef.current.offsetHeight - paddingY;

      for (let i = 10; ; i += 0.75) {
        const linesHeight = handleGetTextHeight(words, boxWidth, i) * LINE_HEIGHT;
        if (linesHeight >= boxHeight) {
          wrapperRef.current.style.fontSize = `${i - 1}px`;
          wrapperRef.current.style.lineHeight = `${(i - 1) * LINE_HEIGHT}px`;
          break;
        }
      }
    }
  }, [brands, handleGetTextHeight]);

  useEffect(() => {
    window.addEventListener("resize", handleFitText);
    handleFitText();

    return () => window.removeEventListener("resize", handleFitText);
  }, [brands, handleFitText]);

  return (
    <PreloaderWrapper
      ref={wrapperRef}
      className={className}
      $isLoaded={progress >= 1}
      $loaderHidden={loaderHidden}
    >
      <PreloaderInnerWrapper ref={innerWrapperRef} aria-label="Preloading page">
        <TitleWrapper>{mainTitle}</TitleWrapper>
        {brands.map((b, ndx) => {
          const brandName = `${b.name}. `.split("");
          // Random order
          // const loaded = progress >= (progressOrder[ndx] + 1) / brands.length;

          // In order of appearance
          const loaded = progress >= (ndx + 1) / brands.length;

          return (
            <BrandNameWrapper key={ndx} aria-label={b.name}>
              {brandName.map((char, charIndex) => {
                return (
                  <BrandNameCharacter
                    key={`char-${ndx}-${charIndex}`}
                    $delay={charIndex * (200 / brandName.length)}
                    $loaded={loaded}
                    aria-hidden={true}
                  >
                    {char}
                  </BrandNameCharacter>
                );
              })}
            </BrandNameWrapper>
          );
        })}
      </PreloaderInnerWrapper>
    </PreloaderWrapper>
  );
}
