import { Brand } from "@/interfaces/Brand";

const brand: Brand = {
  id: "pauline-dujancourt",
  name: "Pauline Dujancourt",
  designers: [
    {
      id: "pauline-dujancourt",
      name: "Pauline Dujancourt",
    },
  ],
  components: [
    {
      componentId: 'DesignerVideoBlock',
      content: {
        id: 'video1',
        videoSrc: '/videos/pauline-dujancourt/walk.mp4'
      }
    },
    {
      componentId: 'DesignerShowroom',
      content: {
        id: 'showroom1',
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection1'
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection2'
      }
    }
  ]
};
export default brand;
