import { css } from "styled-components";

export const aspectRatioSize = ({ width = 1, height = 1, relative = true }: { width: number, height: number, relative?: boolean }) => `
  ${relative ? "position:relative;" : ""}
  &::before{
    content: "";
    display: block;
    padding-top: ${height / width * 100}%;
  }
`;

export const fillContainer = ({ offset = "0", position = "absolute" }: { offset?: string, position?: string }) => `
  top: ${offset};
  left: ${offset};
  bottom: ${offset};
  right: ${offset};
  position: ${position};
`;

export const a11yFocus = () => `
  &:focus-visible {
    outline: 4px solid var(--cl-outline);
  }
`;

export const vhProperty = ({ property, value }: { property: string, value: string | number }) => `
  ${property}: ${value}vh;
  ${property}: calc(var(--vh) * ${value});
`;

export const breakpointWidthMin = ({ width, content }: { width: string, content: any }) => `
  @media only screen and(min-width: ${width}){
    ${css(content)};
  }
`;
