import { Brand } from '@/interfaces/Brand';
import faceDirections from '@/constants/face-directions';
import getCursorDirection from '@/utils/get-cursor-direction';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { JSX } from 'react/jsx-runtime';
import useFaceTimer from '@/hooks/useFaceTimer';
import AppContext from '@/context/AppContext';
import sides from '@/constants/sides';

declare interface FaceProps {
	className: string;
	brand: Brand;
	cursorPosition: {
		cursorX: number;
		cursorY: number;
	};
	scrollY: number;
	onFaceLoaded: (brandId: string) => void;
	isMobileGrid: boolean;
	isNavBar: boolean;
	isStatic: boolean;
	aspectRatio?: 'portrait' | 'square';
}

const FACE_CHANGE_TRANSITION = 600;

const FaceWrapper = styled.div<{ $aspectRatio: string }>`
	position: relative;

	// Fallback for aspect-ratio
	// Note: Safari v17 does not size 1fr grid-columns correctly if the content uses aspect-ratio.
	&::before {
		float: left;
		padding-top: ${({ $aspectRatio }) =>
			$aspectRatio === 'square' ? '100%' : '125.4237%'};
		content: '';
	}
	&::after {
		display: block;
		content: '';
		clear: both;
	}
`;

// prettier-ignore
const FaceSprite = styled.div<{ $faceIndex: number; $isVisible: boolean; }>`
  background-repeat: no-repeat;
  background-position: 0 ${({ $faceIndex }) => (100 / 16) * ($faceIndex - 1)}%;
  background-size: 102% auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ $isVisible }) => +$isVisible};
  transition: opacity ${FACE_CHANGE_TRANSITION}ms;
`;

const renderPeople = (
	brand: Brand,
	direction: string,
	isMid: boolean,
	visibleFace: number,
	isStatic: boolean
) => {
	const faces: JSX.Element[] = [];
	const dir = isStatic ? sides.CENTER : direction;

	brand.designers.forEach((designer, designerIndex) => {
		faces.push(
			<FaceSprite
				key={designerIndex}
				$faceIndex={faceDirections[dir][isMid ? 'mid' : 'end']}
				$isVisible={visibleFace === designerIndex}
			/>
		);
	});

	return <>{faces}</>;
};

export default function Face({
	className,
	brand,
	cursorPosition,
	scrollY,
	onFaceLoaded,
	isMobileGrid,
	isNavBar,
	isStatic,
	aspectRatio = 'portrait',
}: FaceProps) {
	const faceRef = useRef<HTMLDivElement>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [sizes, setSizes] = useState({ centerY: 0, centerX: 0 });
	const { screenSize } = useContext(AppContext);
	const { direction, isMid } = getCursorDirection(
		cursorPosition.cursorX,
		cursorPosition.cursorY,
		sizes.centerX,
		sizes.centerY
	);
	const visibleFaceIndex = useFaceTimer(brand);

	// Load image backgrounds.
	useEffect(() => {
		const folder =
			process.env.REACT_APP_IS_SHOWCASE === 'true'
				? '/images/spritesheets/'
				: '/images/stills/';

		const images: HTMLImageElement[] = [];
		brand.designers.forEach((designer, ndx) => {
			const img = new Image();
			img.onload = function () {
				if (faceRef.current?.children[ndx]) {
					(
						faceRef.current.children[ndx] as HTMLDivElement
					).style.backgroundImage = 'url(' + img.currentSrc + ')';
				}
				onFaceLoaded(brand.id);
			};
			img.src = `${folder + brand.designers[ndx].id}.webp`;
			// img.srcset = `${folder + brand.designers[ndx].id}.jpg`;
			images.push(img);
		});
	}, [brand.id, brand.designers, onFaceLoaded]);

	useEffect(() => {
		if (faceRef.current) {
			const { width, height, top, left } =
				faceRef.current.getBoundingClientRect();
			const centerX = left + width / 2;
			const centerY = top + height / 2;
			setSizes({
				centerX,
				centerY,
			});
		}
	}, [screenSize.width, isMobileGrid, isNavBar, scrollY]);

	return (
		<FaceWrapper
			ref={faceRef}
			className={className}
			$aspectRatio={aspectRatio}
		>
			{renderPeople(brand, direction, isMid, visibleFaceIndex, isStatic)}
		</FaceWrapper>
	);
}
