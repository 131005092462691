// import brand1 from "./brand1";
import brand2 from "./brand2";
// import brand3 from "./brand3";
// import brand4 from "./brand4";
import brand5 from "./brand5";
// import brand6 from "./brand6";
// import brand7 from "./brand7";
import brand8 from "./brand8";
// import brand9 from "./brand9";
// import brand10 from "./brand10";
// import brand11 from "./brand11";
import brand12 from "./brand12";
import brand13 from "./brand13";
import brand14 from "./brand14";
import brand15 from "./brand15";
// import brand16 from "./brand16";
import brand17 from "./brand17";
// import brand18 from "./brand18";
// import brand19 from "./brand19";
// import brand20 from "./brand20";

const brands = [
  // brand1,
  brand2,
  // brand3,
  // brand4,
  brand5,
  // brand6,
  // brand7,
  brand8,
  // brand9,
  // brand10,
  // brand11,
  brand12,
  brand13,
  brand14,
  brand15,
  // brand16,
  brand17,
  // brand18,
  // brand19,
  // brand20,
];
export default brands;
