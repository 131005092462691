import gsap from "gsap";
import { ChildRef } from "@/components/designer/DesignerCollectionItem";
import type TextCopy from "@/components/app/TextCopy";
import type ButtonPrimary from "@/components/app/ButtonPrimary";
import { findTransitionController } from "@/utils/transition-controller/core";
import { RefObject } from "react";

export interface DesignerCollectionRefs {
  elementRef?: RefObject<HTMLDivElement>;
  designerCollectionItemRefs?: RefObject<Array<ChildRef>>;
  nameRefs?: RefObject<Array<typeof TextCopy>>;
  nameHolderRef?: RefObject<HTMLDivElement>;
  closeCtaRef?: RefObject<typeof ButtonPrimary>;
  viewCtaRef?: RefObject<typeof ButtonPrimary>;
  collectionLabelRef?: RefObject<typeof TextCopy>;
}

export function setupTransitionInTimeline(
  timeline: gsap.core.Timeline,
  {
    designerCollectionItemRefs,
    nameHolderRef,
    closeCtaRef,
    viewCtaRef,
    collectionLabelRef,
  }: DesignerCollectionRefs
): void {
  const yOffset = window.innerWidth < 768 ? 2 : 11.5;
  const xOffset = window.innerWidth < 768 ? 82.5 : 85;

  designerCollectionItemRefs?.current?.forEach((item: ChildRef, index) => {
    timeline.fromTo(
      item.elementRef,
      {
        xPercent: -xOffset * index,
        yPercent: -yOffset * index,
      },
      {
        xPercent: 0,
        yPercent: 0,
        ease: "Power2.easeOut",
        duration: 0.5,
      },
      index * 0.075
    );

    timeline.fromTo(
      item.innerContentRef,
      {
        autoAlpha: index !== 0 ? 0 : 1,
      },
      {
        autoAlpha: 1,
        ease: "none",
        duration: 0.7,
      },
      index * 0.076
    );
    timeline.fromTo(
      item.innerContentRef,
      {
        scale: index !== 0 ? 1.4 : 1,
      },
      {
        scale: 1,
        ease: "Power2.easeOut",
        duration: 0.7,
      },
      index * 0.076
    );
  });

  if (closeCtaRef?.current) {
    const controller = findTransitionController(closeCtaRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline, 0.5);
  }

  if (viewCtaRef?.current) {
    const controller = findTransitionController(viewCtaRef.current);
    timeline.add(controller?.getTimeline("out") as gsap.core.Timeline, 0.2);
  }

  if (collectionLabelRef?.current) {
    const controller = findTransitionController(collectionLabelRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline, 0.3);
  }
}

export function setupTransitionOutTimeline(
  timeline: gsap.core.Timeline,
  {
    designerCollectionItemRefs,
    closeCtaRef,
    viewCtaRef,
    collectionLabelRef,
  }: DesignerCollectionRefs
): void {
  const yOffset = window.innerWidth < 768 ? 2 : 11.5;
  const xOffset = window.innerWidth < 768 ? 82.5 : 85;

  if (closeCtaRef?.current) {
    const controller = findTransitionController(closeCtaRef.current);
    timeline.add(controller?.getTimeline("out") as gsap.core.Timeline, 0);
  }

  if (viewCtaRef?.current) {
    const controller = findTransitionController(viewCtaRef.current);
    timeline.add(controller?.getTimeline("in") as gsap.core.Timeline);
  }

  if (collectionLabelRef?.current) {
    const controller = findTransitionController(collectionLabelRef.current);
    timeline.add(controller?.getTimeline("out") as gsap.core.Timeline, 0);
  }

  designerCollectionItemRefs?.current?.forEach((item, index) => {
    timeline.to(
      item.elementRef,
      {
        xPercent: -xOffset * index,
        yPercent: -yOffset * index,
        ease: "Power2.easeOut",
        duration: 0.5,
      },
      index * 0.05
    );

    timeline.to(
      item.innerContentRef,
      {
        autoAlpha: index !== 0 ? 0 : 1,
        ease: "none",
        duration: 0.5,
      },
      0
    );

    timeline.to(
      item.innerContentRef,
      {
        scale: index !== 0 ? 1.2 : 1,
        ease: "Power2.easeOut",
        duration: 0.5,
      },
      index * 0.05
    );
  });

}

export function setupStackingAnimation({
  nameRefs,
}: DesignerCollectionRefs): gsap.core.Timeline {
  const timeline = gsap.timeline({ paused: true });

  nameRefs?.current?.forEach((ref, index) => {
    timeline.fromTo(
      ref,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.1,
      },
      index * 0.1
    );
  });

  return timeline;
}

export function setupLoopingAnimation({
  nameRefs,
}: DesignerCollectionRefs): gsap.core.Timeline {
  const timeline = gsap.timeline({ paused: true });
  timeline.repeat(-1);

  return timeline;
}

export function addScrollLagToItems(
  items: Array<InstanceType<any>>,
  velocity: number
): void {
  const total = items.length;
  items.forEach((item, index) => {
    if (index !== 0) {
      const yOffset = velocity * (index / total);
      addItemLag(item.elementRef, yOffset);
    }
  });
}

export function addItemLag(item: HTMLElement, yOffset: number): void {
  gsap.to(item, {
    y: yOffset,
    duration: 0.3,
    ease: "none",
    overwrite: false,
    onComplete: () => {
      gsap.to(item, {
        y: 0,
        duration: 0.3,
        ease: "none",
        overwrite: false,
      });
    },
  });
}
