import { useDraggable } from "@/hooks/useDraggable";
import { ChildRef } from "@/components/designer/DesignerCollectionItem";
import gsap from "gsap";
import { RefObject, useCallback, useEffect, useRef } from "react";
import { useBrowserStore } from "@/store";

export const getHammerJS = async (): Promise<typeof Hammer | null> => {
  if (typeof window === "undefined") return null;
  const Hammer = (await import("hammerjs")).default;
  return Hammer;
};
export default function useCollectionCarousel(options: {
  boundsRef: RefObject<HTMLDivElement>;
  triggerRef: RefObject<HTMLDivElement>;
  designerCollectionItemRefs: RefObject<Array<ChildRef>>;
}): {
  carouselContentRef: RefObject<HTMLDivElement>;
  draggableInstance: RefObject<Draggable | null>;
  draggableIsReady: RefObject<boolean>;
  gotoPosition: (snapValue: number, setImmediate?: boolean) => gsap.core.Tween;
  disableEvents: (disable: boolean) => void;
} {
  const browser = useBrowserStore();
  const designerCollectionItemRefs = options.designerCollectionItemRefs;
  const carouselContentRef = useRef<HTMLDivElement>(null);
  const carouselSnapIndex = useRef<number>(0);
  const itemWidth = useRef<number>(0);
  const inProgress = useRef<boolean>(false);
  let hammerManager: HammerManager;
  let hammerSwipe;
  let eventsDisabled = true;

  useEffect(() => {
    const draggable = draggableInstance.current;
    const setupHammer = async () => {
      updateSize();
      inProgress.current = true;
      const Hammer = await getHammerJS();

      if (Hammer && Hammer.Manager && options.triggerRef.current) {
        hammerManager = new Hammer.Manager(options.triggerRef.current);
        hammerSwipe = new Hammer.Swipe();
        hammerManager.add(hammerSwipe);
        hammerManager
          .get("swipe")
          .set({ direction: Hammer.DIRECTION_HORIZONTAL });

        hammerManager.on("swiperight", swipeRightListener);
        hammerManager.on("swipeleft", swipeLeftListener);
      }
    };

    updateSize();
    !hammerManager && !inProgress.current && setupHammer();

    return () => {
      if (draggable) {
        draggable.kill();
      }

      hammerManager?.off("swipeleft", swipeLeftListener);
      hammerManager?.off("swiperight", swipeRightListener);
      hammerManager?.destroy();
    };
  }, []);

  useEffect(() => {
    onWindowResize();
  }, [browser.windowWidth, browser.windowHeight]);

  const { draggableInstance, draggableIsReady } = useDraggable(
    carouselContentRef,
    {
      type: "x",
      inertia: true,
      edgeResistance: 1,
      lockAxis: true,
      maxDuration: 1,
      throwResistance: 2000,
      snap: getSnap,
      zIndexBoost: false,
    }
  );

  function disableEvents(disable: boolean): void {
    eventsDisabled = disable;
  }

  const swipeRightListener = useCallback(() => {
    if (!eventsDisabled) {
      gotoIndex(carouselSnapIndex.current, false, -1);
    }
  }, []);

  const swipeLeftListener = useCallback(() => {
    if (!eventsDisabled) {
      gotoIndex(carouselSnapIndex.current, false, +1);
    }
  }, []);

  function onWindowResize(): void {
    updateSize();
  }

  function getSnap(endValue: number): number {
    carouselSnapIndex.current = Math.round(endValue / itemWidth.current) * -1;
    return carouselSnapIndex.current * -1 * itemWidth.current;
  }

  const updateSize = useCallback(() => {
    if (
      !designerCollectionItemRefs.current ||
      !designerCollectionItemRefs.current.length ||
      !(designerCollectionItemRefs?.current[0] as any).elementRef
    )
      return;

    const style = window.getComputedStyle(
      (designerCollectionItemRefs?.current[1] as any).elementRef as any
    );
    const marginLeft = parseInt(style.marginLeft, 10);
    itemWidth.current =
      ((designerCollectionItemRefs?.current[0] as any).elementRef as any)
        .offsetWidth + marginLeft;
  }, []);

  const onManualUpdate = useCallback((): void => {
    draggableInstance?.current?.update();
  }, []);

  const gotoPosition = useCallback(
    (snapValue: number, setImmediate?: boolean): gsap.core.Tween => {
      return gsap.to(carouselContentRef.current, {
        overwrite: true,
        x: snapValue,
        duration: setImmediate ? 0 : 0.4,
        ease: "Power2.easeOut",
        onUpdate: () => {
          onManualUpdate();
          getSnap(snapValue);
        },
      });
    },
    []
  );

  const gotoIndex = useCallback(
    (
      index: number,
      setImmediate?: boolean,
      incrementer?: number
    ): gsap.core.Tween | Promise<void> => {
      let newSnapIndex = index;
      if (typeof incrementer === "number") {
        newSnapIndex = carouselSnapIndex.current + incrementer;
      }

      if (
        newSnapIndex >= 0 &&
        newSnapIndex <
          ((designerCollectionItemRefs.current &&
            designerCollectionItemRefs.current.length) ||
            0)
      ) {
        return gotoPosition(
          getSnap(newSnapIndex * -1 * itemWidth.current),
          setImmediate
        );
      }

      return Promise.resolve();
    },
    []
  );

  /*watch(
    () => [browser.windowWidth, browser.windowHeight],
    () => {
      onWindowResize();
    }
  );*/

  return {
    carouselContentRef,
    draggableInstance,
    draggableIsReady,
    gotoPosition,
    disableEvents,
  };
}
