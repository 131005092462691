import { media } from "@/utils/breakpoints";
import { motion } from "framer-motion";
import styled from "styled-components";

const Overlay = styled(motion.div)`
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);

  ${media.sm`
    display: block;
  `}
`;

export default function BlurOverlay() {
  return (
    <Overlay
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    ></Overlay>
  );
}
