import { CursorText } from "@/styles/typography.style";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import styled from "styled-components";

declare interface CursorFollowerProps {
  className?: string;
  text: string;
  cursorPosition: {
    cursorX: number;
    cursorY: number;
  };
  isVisible: boolean;
}

const NameWrapper = styled(CursorText).attrs({ as: "div" })<{ $isVisible: boolean }>`
  position: fixed;
  top: 8px;
  left: -2px;
  z-index: 10;

  min-width: 156px;
  height: 39px;
  padding: 0 20px;

  background: white;
  border: solid 1px black;
  border-radius: 52px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  opacity: ${({ $isVisible }) => ($isVisible ? "1" : "0")};
  transition: opacity ease 0.5s;
`;

export default function CursorFollower({
  className,
  text,
  cursorPosition,
  isVisible,
}: CursorFollowerProps) {
  const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cursorRef.current) {
      gsap.to(cursorRef.current, {
        x: cursorPosition.cursorX,
        y: cursorPosition.cursorY,
        duration: 0.8,
      });
    }
  }, [cursorPosition]);

  return (
    <NameWrapper ref={cursorRef} className={className} $isVisible={isVisible}>
      {text}
    </NameWrapper>
  );
}
