import styled from 'styled-components';
import TextCopy from '../app/TextCopy';
import { useTranslation } from 'react-i18next';
import { vhProperty } from '@/styles/mixins';
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { setupScrollOutTimeline } from '@/hooks/useTransitionsHeader';
import { useTransitionController } from '@/utils/transition-controller/react';
import {
  setupTransitionInTimeline,
  setupTransitionOutTimeline,
} from '@/hooks/useTransitionsHeader';
import useScrollTrigger from '@/utils/scroll/composables/useScrollTrigger';
import { gsap } from 'gsap';
import { mediaDown } from '@/utils/breakpoints';
import useResize from '@/hooks/useResize';
import { usePageTransition } from '@/utils/transition-controller/react/composables/usePageTransition';
import { useAppStore, useBrowserStore } from '@/store';

declare interface DesignerHeaderProps {
  className: string;
  designerId: string;
  inverted: boolean;
  leaveProgress: number;
}

const StyledDesignerHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  ${vhProperty({ property: 'min-height', value: 100 })}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  mix-blend-mode: difference;
  z-index: 0;

  &.inverted {
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2));
    mix-blend-mode: normal;
  }

  .header-text {
    position: absolute;
    bottom: 60px;
    padding-left: 25px;
    left: auto;
    top: auto;
    transform: none;
    width: 100%;
    transform-origin: 0% 100%;
    color: white;

    ${mediaDown.md2`
      bottom: 100px;
    `}
  }

  .title {
    font-size: 18.7rem;
    font-weight: 400;
    line-height: 14rem;
    letter-spacing: -0.4rem;
    transform-origin: 0% 100%;
  }

  .subtitle {
    font-size: 3rem;
    line-height: 3.7rem;
    font-weight: 500;
    margin-top: 1rem;
    padding-left: 1rem;
    mix-blend-mode: difference;
  }

  ${mediaDown.md2`
    .header-text{
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
    }

    .title{
      font-size: 7rem;
      line-height: 0.742;
    }

    .subtitle{
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin-top: 1rem;
      padding-left: 5px;
    }
  `}
`;

const DesignerHeader = forwardRef(
  (
    {
      designerId,
      inverted,
      leaveProgress,
      className,
    }: PropsWithChildren<DesignerHeaderProps>,
    ref
  ) => {
    const { t } = useTranslation();
    const app = useAppStore();
    const browser = useBrowserStore();
    const elementRef = useRef<HTMLDivElement>(null);
    const headerCtaHolderRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<typeof TextCopy>(null);
    const subTitleRef = useRef<typeof TextCopy>(null);
    const headerTextRef = useRef<HTMLDivElement>(null);
    const scrollOutTimeline = useRef<gsap.core.Timeline>();
    const nameFontSize = useRef<number>();

    useImperativeHandle(ref, () => elementRef.current, []);

    const setNameFontsize = (e: any) => {
      let wordLength = 0;
      let remSize = 7;

      if (e.breakpoint === 'mobile') {
        const nameText = t(`designers.${designerId}.name`);
        const words = nameText.split(' ');
        const maxLettersInMax = 7;
        const maxLettersInMin = 14;
        const diffLetters = maxLettersInMin - maxLettersInMax;
        const maxRem = 7;
        const minRem = 4.5;
        const diffRem = maxRem - minRem;

        words?.forEach((word) => {
          if (word.length > wordLength) {
            wordLength = word.length;
          }
        });

        if (wordLength > maxLettersInMax) {
          remSize =
            maxRem - (diffRem / diffLetters) * (wordLength - maxLettersInMax);
        }
      } else {
        remSize = 18.7;
      }
      nameFontSize.current = remSize;
    };

    useResize(setNameFontsize);

    useEffect(() => {
      if (titleRef?.current) {
        gsap.set(titleRef?.current, {
          fontSize: `${nameFontSize.current}rem`,
        });
      }
    }, [nameFontSize.current, titleRef]);

    useEffect(() => {
      scrollOutTimeline.current = setupScrollOutTimeline({
        titleRef,
      });
    }, []);

    useEffect(() => {

      const ctx = gsap.context(() => {
        if (browser.breakpoint === "mobile") {
          gsap.fromTo(elementRef.current, { opacity: app.scrollLocked ? 1 : 0 }, { opacity: app.scrollLocked ? 0 : 1 })
        }
      })
      return () => {
        ctx.revert();
      }
    }, [app.scrollLocked, browser.breakpoint]);

    useScrollTrigger({
      trigger: elementRef,
      animation: scrollOutTimeline,
      variables: {
        scrub: true,
        start: 'top top',
        end: 'bottom top',
        markers: false,
      },
    });

    const useTransitionControllerOpts = useCallback(() => {
      return {
        refs: {
          elementRef,
          titleRef,
          subTitleRef,
          headerCtaHolderRef,
        },
        ref: elementRef,
        setupTransitionInTimeline,
        setupTransitionOutTimeline,
      };
    }, []);
    useTransitionController(useTransitionControllerOpts);

    usePageTransition({
      beforeTransitionOut: async (release) => { },
      beforeTransitionIn: async (release) => { },
      crossFlow: () => false,
      setupOptions: () => ({
        refs: {
          titleRef,
          subTitleRef,
        },
        delay: 2,
        onComplete: (direction) => {
          if (direction === 'in') {
            //toggleScrollerPause(false);
          }
        },
        ref: elementRef,
        setupTransitionInTimeline,
        setupTransitionOutTimeline,
      }),
    });

    return (
      <StyledDesignerHeader
        ref={elementRef}
        className={`designer-header ${inverted ? 'inverted' : undefined}`}
      >
        <div ref={headerTextRef} className="header-text">
          <TextCopy
            as="h1"
            ref={titleRef}
            className={'title'}
            label={t(`designers.${designerId}.name`)}
            transitionInType={'splitCharFadeIn'}
            transitionOnMount={true}
          />

          <TextCopy
            as="p"
            ref={subTitleRef}
            className="subtitle"
            label={t(`designers.${designerId}.subTitle`)}
            transitionInType={'splitLinesSlideUp'}
            transitionOnMount={true}
          />
        </div>
      </StyledDesignerHeader>
    );
  }
);

export default DesignerHeader;
function useStores(): { app: any; browser: any; } {
  throw new Error('Function not implemented.');
}

