import { Brand } from '@/interfaces/Brand';
import DesignerDetails from '@/components/designer/DesignerDetails';
import DesignerVideoBlock from '@/components/designer/DesignerVideoBlock';
import DesignerCollection from '@/components/designer/DesignerCollection';
import DesignerShowroom from '@/components/designer/DesignerShowroom';
import { useTranslation } from 'react-i18next';
import brands from '@/config/brands';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { kebabCase } from 'lodash';
import { usePageScrollEffects } from '@/hooks/usePageScrollEffects';
import { useAppStore } from '@/store';
import {
  setupTransitionInTimeline,
  setupTransitionOutTimeline,
} from '@/hooks/useTransitionsPageDesigners';
import { usePageTransition } from '@/utils/transition-controller/react/composables/usePageTransition';
import { fadeTo } from '@/utils/animation/fade/fade';
import { vhProperty } from '@/styles/mixins';
import { mediaDown } from '@/utils/breakpoints';
import { motion } from 'framer-motion';

declare interface DesignerProps {
  className?: string;
  brand: Brand;
}

const StyledDesignerPage = styled(motion.div)`
	position: relative;
	overflow: hidden;

  .content-frame {
  		max-width: 192rem;
  		margin-left: auto;
  		margin-right: auto;
  		padding-left: var(--spacer-designer-page-desktop);
  		padding-right: var(--spacer-designer-page-desktop);
  		padding-top: 100vh;
      padding-top: 100dvh;
      padding-bottom: 12rem;
  		overflow: hidden;
  		width: 100%;
  	}

	.designer-video-block {
		margin-left: auto;
		margin-right: auto;
	}

	.designer-video-block {
    margin-top: 30rem;
		&:last-child {
			margin-top: 0;
			${vhProperty({ property: 'height', value: 100 })};
		}
	}

	// overflow showroom wrapper avoiding .content-frame paddings.
	.showroom-block__wrapper {
		margin-left: calc(var(--spacer-designer-page-desktop) * -1);
		width: calc(100% + var(--spacer-designer-page-desktop) * 2);
	}


  #collection2{
    transform:scale(-1);

    picture{
      transform:scale(-1);
    }

    .name-holder{
      .inner-wrapper{
        transform:scale(-1, -1);
      }
    }


  }

  ${mediaDown.md`
     .content-frame{
        max-width: 100%;
        padding: 0 var(--spacer-designer-page-tablet);
        padding-top: 100vh;
        padding-top: 100dvh;
     }
     .showroom-block__wrapper {
		    margin-left: calc(var(--spacer-designer-page-tablet) * -1);
		    width: calc(100% + var(--spacer-designer-page-tablet) * 2);
	   }
   `}

 	${mediaDown.md2`
     .content-frame{
        max-width: 100%;
        padding: 0 var(--spacer-designer-page-mobile);
        padding-top: 100vh;
        padding-top: 100dvh;
        padding-bottom: 12rem;
     }

     .showroom-block__wrapper {
        margin-left: calc(var(--spacer-designer-page-mobile) * -1);
		    width: calc(100% + var(--spacer-designer-page-mobile) * 2);
	   }

    .designer-details{
      ${vhProperty({ property: 'margin-top', value: 20 })}
    }

    .designer-garment-viewer,
    .designer-video-block{
      margin-top: var(--spacer-designer-block-mb);
    }

    .designer-garment-viewer{
      margin-left: 0;
    }

    #collection2{
      transform:scale(1);
        picture{
          transform:scale(1);
        }

        .name-holder{
          .inner-wrapper{
            transform:none;
          }
        }
        .collection-label{
          text-align: center;
        }
    }

  `}
`;

const COMPONENTS = {
  DesignerVideoBlock: DesignerVideoBlock,
  DesignerCollection: DesignerCollection,
  DesignerShowroom: DesignerShowroom,
};

export default function DesignerPage({ className, brand }: DesignerProps) {
  const { t } = useTranslation();
  const scroller = useAppStore.use.scroller();
  const toggleScrollerPause = useAppStore.use.toggleScrollerPause();
  const setDesignerId = useAppStore.use.setDesignerId();
  const toggleHideFooter = useAppStore.use.toggleHideFooter();
  const elementRef = useRef<HTMLDivElement>(null);
  const bottomGlowRef = useRef<HTMLDivElement>(null);
  const componentRefs = useRef<Array<typeof COMPONENTS>>([]);

  const activeContentOverlayId = useAppStore.use.activeContentOverlayId();
  const setActiveContentOverlayId = useAppStore.use.setActiveContentOverlayId();

  const getComponentClassName = useCallback((componentId: string) => {
    return kebabCase(componentId);
  }, []);

  usePageScrollEffects();

  const designer = useMemo(() => {
    const content = brands.find((designer) => designer.id === brand.id);
    if (content) {
      scroller ? scroller?.scrollTo(0.0, true) : window.scrollTo(0, 0);
      return content;

    } else {
      console.warn(`no content found for [${brand.id}]`);
      return null;
    }
  }, [brand.id, setDesignerId]);

  useEffect(() => {
    setDesignerId(brand.id);
  }, [brand.id, setDesignerId]);


  const onOverlayOpen = useCallback((id: string) => {
    setActiveContentOverlayId(id);
    //app.animBlur(1)
  }, []);

  const onOverlayClose = useCallback(() => {
    setActiveContentOverlayId(null);
    //app.animBlur(0)
  }, []);

  usePageTransition({
    beforeTransitionOut: async (release) => { },
    beforeTransitionIn: async (release) => { },
    crossFlow: () => false,
    setupOptions: () => ({
      refs: {
        elementRef,
        bottomGlowRef,
      },
      onComplete: (direction) => {
        if (direction === 'in') {
          toggleScrollerPause(false);
        }
      },
      ref: elementRef,
      setupTransitionInTimeline,
      setupTransitionOutTimeline,
    }),
  });

  const getInactiveComponents = useCallback(
    (activeId?: string): Array<HTMLElement> => {
      const components = componentRefs?.current
        ?.filter(
          (component) => (component as any).getAttribute('id') !== activeId
        )
        .map((component) => component as any);
      //components.push(introHeaderRef.current);
      // components.push(introHeaderRef.current.headerCtaHolderRef)
      return components;
    },
    []
  );

  useEffect(() => {
    toggleHideFooter(!!activeContentOverlayId);
    const otherComponents = getInactiveComponents(
      activeContentOverlayId as string
    );
    fadeTo(otherComponents, { to: activeContentOverlayId ? 0 : 1 });
  }, [activeContentOverlayId, getInactiveComponents, toggleHideFooter]);

  return (
    <StyledDesignerPage
      ref={elementRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.15, duration: 0.3 }}
    >
      <div className={'content-frame page-content'}>
        <DesignerDetails
          id={designer?.id || ''}
          designers={designer?.designers || []}
          name={t(`designers.${designer?.id}.name`)}
          altText={t(`designers.${designer?.id}.name`)}
          websiteUrl={t(`designers.${designer?.id}.website`)}
          nationality={`${t(`designers.nationality`)} - ${t(
            `designers.${designer?.id}.nationality`
          )}`}
          workplace={`${t(`designers.workplace`)} - ${t(
            `designers.${designer?.id}.workplace`
          )}`}
          instagramUrl={t(`designers.${designer?.id}.instagram`)}
          description={t(`designers.${designer?.id}.biography`)}
        />

        {brand.components.map((component, index) => {
          const Component =
            COMPONENTS[component.componentId as keyof typeof COMPONENTS];
          return (
            <Component
              id={component.content.id}
              ref={(el) =>
                (componentRefs.current[index] = el as typeof COMPONENTS)
              }
              key={component.content.id}
              className={getComponentClassName(component.componentId)}
              designerId={brand.id}
              dirName={designer?.id || ''}
              content={component.content}
              onOverlayOpen={onOverlayOpen}
              onOverlayClose={onOverlayClose}
            />
          );
        })}
      </div>
    </StyledDesignerPage>
  );
}
