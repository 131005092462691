import { Brand } from "@/interfaces/Brand";

const brand: Brand = {
  id: "duran-lantink",
  name: "Duran Lantink",
  designers: [
    {
      id: "duran-lantink",
      name: "Duran Lantink",
    },
  ],
  components: [
    {
      componentId: 'DesignerVideoBlock',
      content: {
        id: 'video1',
        videoSrc: '/videos/duran-lantink/walk.mp4'
      }
    },
    {
      componentId: 'DesignerShowroom',
      content: {
        id: 'showroom1',
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection1'
      }
    },
    {
      componentId: 'DesignerCollection',
      content: {
        id: 'collection2'
      }
    }
  ]
};
export default brand;
