import type {
  SplitCharFadeInOptions,
  SplitLineSlideUpOptions,
  SplitWordFadeSlideInOptions,
  SplitWordFadeSlideOutOptions,
} from './split.types';
// import eases from '@/util/animation/eases';

const defaultOptions = {
  minStaggerDuration: 0.05,
  maxStaggerDuration: 0.5,
  stagger: 0.05,
};

export const defaultSplitLineSlideUpOptions: SplitLineSlideUpOptions = {
  ...defaultOptions,
  splitType: 'lines',
  y: '100%',
  duration: 0.4,
  stagger: 0.1,
  minStaggerDuration: 0.15,
  maxStaggerDuration: 0.5,
  ease: 'Power2.easeOut',
  addInnerChild: true,
};

export const defaultSplitWordFadeSlideInOptions: SplitWordFadeSlideInOptions = {
  ...defaultOptions,
  splitType: 'words',
  y: '100%',
  from: 0,
  duration: 0.6,
  stagger: 0.1,
  minStaggerDuration: 0.07,
  maxStaggerDuration: 0.4,
  ease: 'Power2.easeOut',
  addInnerChild: false,
};

export const defaultSplitWordFadeSlideOutOptions: SplitWordFadeSlideOutOptions = {
  ...defaultOptions,
  splitType: 'words',
  y: '-50%',
  to: 0,
  duration: 0.3,
  stagger: 0.1,
  minStaggerDuration: 0.05,
  maxStaggerDuration: 0.3,
  ease: 'Power2.easeOut',
  addInnerChild: false,
};

export const defaultSplitCharFadeInOptions: SplitCharFadeInOptions = {
  ...defaultOptions,
  splitType: 'chars',
  from: 0,
  duration: 0.3,
  stagger: 0.1,
  minStaggerDuration: 0.05,
  maxStaggerDuration: 0.3,
  ease: 'none',
};