import styled from "styled-components";

import { ReactComponent as ArrowUp } from "@/assets/svg/arrow-up.svg";
import { ReactComponent as Mute } from "@/assets/svg/mute.svg";
import { ReactComponent as UnMute } from "@/assets/svg/unmute.svg";
import { forwardRef, useImperativeHandle, useRef } from "react";


const ICON_COMPONENTS = {
  arrowUp: ArrowUp,
  mute: Mute,
  unmute: UnMute
};

export type IconVariant = keyof typeof ICON_COMPONENTS;
type IconProps = {
  name: IconVariant,
  className: string
};

const IconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  --fill-color: currentColor;

  svg {
    fill: var(--fill-color);
    display: block;
    width: 100%;
  }
`;

const Icon = forwardRef(({
  name,
  className
}: IconProps, ref) => {
  const elementRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => elementRef.current, []);

  if (!(name in ICON_COMPONENTS)) return null;


  const Component = ICON_COMPONENTS[name];

  return (
    <IconWrapper ref={elementRef} className={className}>
      <Component />
    </IconWrapper>
  );
})

Icon.displayName = "Icon";

export default Icon;
