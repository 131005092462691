import { motion } from "framer-motion";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppContext from "@/context/AppContext";

declare interface EnterButtonProps {
  className?: string;
}

const ButtonWrapper = styled(motion.button)`
  position: fixed;
  z-index: 5;
  left: 50%;
  display: flex;
  bottom: 124px;
  transform: translateX(-50%);

  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: white;
  background: rgb(0 0 0 / 70%);
  -webkit-backdrop-filter: blur(80px);
  backdrop-filter: blur(80px);
  border: 1px solid white;
  padding: 12px 48px;
  border-radius: 66px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

export default function EnterButton({ className }: EnterButtonProps) {
  const navigate = useNavigate();
  const { activeBrandId } = useContext(AppContext);

  return (
    <ButtonWrapper
      className={className}
      onClick={() => navigate("/" + activeBrandId)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      Enter Page
    </ButtonWrapper>
  );
}
