import styled from "styled-components";
import ButtonPrimary from "@/components/app/ButtonPrimary";
import { PropsWithChildren, SyntheticEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import Icon from "../app/Icon";
import useEscToClose from "@/hooks/useEscToClose";
import { findTransitionController } from "@/utils/transition-controller/core";
import { ReactComponent as Play } from "@/assets/svg/play.svg";
import { ReactComponent as Pause } from "@/assets/svg/pause.svg";
import { useAppStore } from "@/store";
import { gsap } from "gsap"
import useResize from "@/hooks/useResize";
import { mediaDown } from "@/utils/breakpoints";
import useScrollTrigger from "@/utils/scroll/composables/useScrollTrigger";
import { MENU_HEIGHT } from "@/constants/global";

declare interface DesignerVideoBlockProps {
  id: string;
  designerId: string;
  dirName: string;
  content: {
    id: string;
    videoSrc: string;
  };
  onOverlayOpen: (id: string) => void;
  onOverlayClose: () => void;
  className?: string
}

const StyledButtonPrimary = styled(ButtonPrimary)`
    z-index: 2;
    position: absolute;
`

const StyledDesignerVideoBlock = styled.div`
  position: relative;
  width: 86.4rem;
  height: 54.7rem;

  ${mediaDown.md2`
    width: 32rem;
    height: 20rem;
    position: relative;
  `}

  .video-block__scroll__wrapper{
    width: 100%;
    height: 100%;
  }

  .video-block__container{
    border: 1px solid #000000;
    position: absolute;
    width: 86.4rem;
    height: 54.7rem;
    ${mediaDown.md2`
      width: 32rem;
      height: 20rem;
    `}
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .base-button{
    z-index: 2;
    position: absolute;
  }

  .video-block__button__open{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    ${mediaDown.md2`
      width: 22.3rem;
    `}
  }

  .video-block__button__close{
    pointer-events: none;
    top: 2rem;
    left: 2rem;
    ${mediaDown.md2`
      top: -5.5rem;
    `}
  }
  .video-player__wrapper{
    background: black;
    // display:none;

    video{
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
    }
  }
  .toggle-play {
    position: absolute;
    left: 3.5rem;
    bottom: 3.5rem;
    width: 2.5rem;
    height: 2.5rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms;
    border: none;
    background: none;

    ${mediaDown.sm`
      left: 1.5rem;
      bottom: 0.9rem;
      width: 1.8rem;
      height: 1.8rem;
    `}

    .play-pause-icon{
      ${mediaDown.md2`
        left: 2rem;
        bottom: 2rem;
      `}
    }
  }
  .toggle-mute{
    position: absolute;
    color: black;
    left: 8.3rem;
    bottom: 3.5rem;
    width: 2.5rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms linear;
    border: none;
    background: none;

    .mute-icon svg{
      fill: transparent;
    }

    ${mediaDown.md2`
      left: 5.6rem;
      bottom: 2rem;
    `}

    ${mediaDown.sm`
      left: 4.6rem;
      bottom: 0.9rem;
      width: 1.9rem;
    `}
  }

  &.open{
    .video-block__container{
      height: 77.1875rem;
    }

    .video-block__button__open{
      pointer-events: none;
    }

    .video-block__button__close{
      pointer-events: auto;
    }

    .toggle-mute{
      pointer-events: auto;
      cursor: pointer;
      opacity: 1;
      mix-blend-mode: difference;

      svg{
        path, rect, line{
          fill: white;
          stroke: white;
        }
      }
    }
    .toggle-play{
      pointer-events: auto;
      cursor: pointer;
      opacity: 1;
      mix-blend-mode: difference;
      .play-pause-icon{
          path, rect{
            fill: white;
            stroke: white;
          }
      }
    }
  }
`;

const DesignerVideoBlock = forwardRef(({
  designerId,
  dirName,
  content,
  className,
  onOverlayOpen,
  onOverlayClose,
  ...props
}: PropsWithChildren<DesignerVideoBlockProps>, ref) => {

  const toggleOpen = () => {
    firstInteraction.current = true;
    setIsOpen(!isOpen)
  }
  const { isOpen, setIsOpen } = useEscToClose(toggleOpen)

  const toggleScrollerPause = useAppStore.use.toggleScrollerPause();
  const scrollY = useAppStore.use.scrollY();
  const scrollYRef = useRef<number>(scrollY);
  const scrollTo = useAppStore.use.scrollTo();


  const closeCtaRef = useRef<typeof ButtonPrimary>()
  const playCtaRef = useRef<typeof ButtonPrimary>()

  const wrapperRef = useRef<HTMLDivElement>(null)
  useImperativeHandle(ref, () => wrapperRef.current, []);
  const containerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const width = useRef({ from: "86.4rem", to: "137.1rem" })
  const height = useRef({ from: "54.7rem", to: "72.1rem" })


  const delayedOpenCall = useRef<gsap.core.Tween | null>(null);
  const containerTween = useRef<Omit<gsap.core.Tween, "then"> | null>(null);

  const firstInteraction = useRef<boolean>(false);
  const isOpenRef = useRef<boolean>(false);

  const scrollToCenter = useRef(async () => {
    let currentY = scrollYRef.current || window.scrollY;
    const bounds = wrapperRef.current?.getBoundingClientRect()
    const distance = bounds!.top + bounds!.height / 2 - window.innerHeight / 2
    await scrollTo(0, currentY + distance - (MENU_HEIGHT * 0.3), Math.abs(distance) * 0.9); // 0.3 should be centered between the menu logo
  })

  useEffect(() => {
    scrollYRef.current = scrollY
  }, [scrollY])

  const onOpen = useCallback(async () => {
    if (videoRef?.current) {
      killDelayOpenCall()
      await scrollToCenter.current();

      onOverlayOpen(content.id)

      toggleScrollerPause(true);
      delayedOpenCall.current = gsap.delayedCall(0.4, () => {
        setMuted(false)
        if (videoRef.current) {
          videoRef.current.volume = 1
          videoRef.current.play()
          videoRef.current.currentTime = 0;
        }

        findTransitionController(playCtaRef?.current)?.transitionOut()
        findTransitionController(closeCtaRef?.current)?.transitionIn()
        containerTween.current = gsap.to(containerRef.current, {
          width: width.current.to,
          height: height.current.to,
          duration: 0.4,
          ease: 'easeOutQuad',
          onUpdate: function () {
            videoRef.current && (videoRef.current.volume = this.progress())
          }
        })
      })
    }
  }, [content.id, onOverlayOpen, toggleScrollerPause])

  const onClose = useCallback(async () => {
    killDelayOpenCall()
    if (videoRef?.current) {
      videoRef.current.volume = 1

      delayedOpenCall.current = gsap.delayedCall(0.6, () => {
        onOverlayOpen("")
        toggleScrollerPause(false);
      });

      findTransitionController(playCtaRef?.current)?.transitionIn()
      findTransitionController(closeCtaRef?.current)?.transitionOut()

      containerTween.current && (containerTween.current = await gsap.to(containerRef.current, {
        width: width.current.from,
        height: height.current.from,
        duration: 0.4,
        ease: 'easeOutQuad',
        onUpdate: function () {
          videoRef.current && (videoRef.current.volume = 1 - (this.progress() as number))
        }
      }))
      setMuted(true)
    }
  }, [onOverlayOpen, toggleScrollerPause])

  const { inView } = useScrollTrigger({
    trigger: wrapperRef,
  });


  const [isPlay, setIsPlay] = useState(true)
  function togglePlay(event: SyntheticEvent): void {
    event?.stopPropagation();
    setIsPlay(!isPlay)
  }

  const [muted, setMuted] = useState(true)
  function toggleMute(event: SyntheticEvent): void {
    event?.stopPropagation();
    setMuted(!muted)
  }

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.muted = muted
  }, [muted])

  useEffect(() => {
    if (!videoRef.current) return
    isPlay ? videoRef?.current?.play().catch((e) => { console.log(e) }) : videoRef?.current?.pause()
  }, [isPlay])


  // const preload = useRef('none')
  /*useAnimateIn(() => {
    preload.current = 'auto'
  })*/

  useEffect(() => {
    if (!videoRef.current) return
    inView.current ? videoRef?.current?.play() : videoRef?.current?.pause()

    if (videoRef?.current) {
      videoRef.current.volume = 0
    }
  }, [inView])

  useEffect(() => {
    if (!firstInteraction.current) return;
    isOpenRef.current = isOpen;
    isOpen ? onOpen() : onClose()
  }, [isOpen, onClose, onOpen])


  function killDelayOpenCall(): void {
    if (containerTween.current) {
      containerTween.current.kill();
    }

    if (delayedOpenCall.current) {
      delayedOpenCall.current.kill();
    }
  }

  const onResize = useCallback((e: any) => {
    if (e.breakpoint === 'desktop') {
      const scale = e.orientation === "portrait" ? 0.9 : 1;
      width.current = { from: "86.4rem", to: `${137.1 * scale}rem` }
      height.current = { from: "54.7rem", to: `${77.1875 * scale}rem` }
    } else if (e.breakpoint === 'mobile') {
      width.current = { from: '32rem', to: '39rem' }
      height.current = { from: "20rem", to: "21.9375rem" }
    }

    if (containerRef?.current) {
      containerRef.current.style.width = isOpenRef.current ? width.current.to : width.current.from
      containerRef.current.style.height = isOpenRef.current ? height.current.to : height.current.from
    }
  }, []);

  const resizeHook = useResize(onResize)

  return (
    <StyledDesignerVideoBlock id={props.id} ref={wrapperRef} className={`video-block__wrapper ${isOpen ? 'open' : 'close'} ${className}`}>
      <div className="video-block__scroll__wrapper">
        <div ref={containerRef} className="video-block__container">

          <StyledButtonPrimary
            ref={playCtaRef}
            className={'video-block__button__open'}
            label="play video"
            buttonTheme="secondary"
            buttonSize="medium"
            transitionOnMount={false}
            disableTriggerInOnScroll={false}
            disabled={isOpen}
            aria-hidden={isOpen}
            onClick={toggleOpen} />


          <StyledButtonPrimary
            ref={closeCtaRef}
            className={'video-block__button__close'}
            label="close"
            buttonTheme="secondary"
            buttonSize="medium"
            transitionOnMount={false}
            disabled={!isOpen}
            aria-hidden={!isOpen}
            aria-label="Close video player"
            onClick={toggleOpen}
          />


          <div className="video-player__wrapper">
            <video
              ref={videoRef}
              muted={muted}
              playsInline
              preload="preload"
              poster={content.videoSrc.replace('.mp4', '.webp')}
              src={content.videoSrc}
              loop={true}
            ></video>
          </div>
          <button
            aria-label={isPlay ? "pause" : "play"}
            aria-hidden={!isOpen}
            disabled={!isOpen}
            className="toggle-play"
            onClick={togglePlay}
          >
            {!isPlay && <Play className={"play-pause-icon"} />}
            {isPlay && <Pause className={"play-pause-icon"} />}
          </button>

          <button
            aria-label={muted ? "unmute" : "mute"}
            aria-hidden={!isOpen}
            disabled={!isOpen}
            className="toggle-mute"
            onClick={toggleMute}
          >
            {!muted && <Icon className="mute-icon" name={"mute"} />}
            {muted && < Icon className="mute-icon" name={"unmute"} />}
          </button>
        </div >
      </div >
    </StyledDesignerVideoBlock >
  );
})
export default DesignerVideoBlock
