import { Brand } from '@/interfaces/Brand';

const brand: Brand = {
	id: 'hodakova',
	name: 'Hodakova',
	designers: [
		{
			id: 'ellen-hodakova-larsson',
			name: 'Ellen Hodakova Larsson',
		},
	],
	components: [
		{
			componentId: 'DesignerVideoBlock',
			content: {
				id: 'video1',
				videoSrc: '/videos/hodakova/walk.mp4',
			},
		},
		{
			componentId: 'DesignerShowroom',
			content: {
				id: 'showroom1',
			},
		},
		{
			componentId: 'DesignerCollection',
			content: {
				id: 'collection1',
			},
		},
		{
			componentId: 'DesignerCollection',
			content: {
				id: 'collection2',
			},
		},
		{
			componentId: 'DesignerVideoBlock',
			content: {
				id: 'video2',
				videoSrc: '/videos/hodakova/brand.mp4',
			},
		},
	],
};
export default brand;
