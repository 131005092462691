import { DeviceProperties } from "@/utils/get-device-properties";
import { createContext } from "react";

interface AppContextType {
  device?: DeviceProperties;
  screenSize: { width: number };
  activeBrandId: string;
  previousBrandId: string;
}

const AppContext = createContext<AppContextType>({
  screenSize: { width: 0 },
  activeBrandId: "",
  previousBrandId: "",
});

export default AppContext;
