import { create, type StoreApi, type UseBoundStore } from 'zustand';
import * as env from '@/utils/env';
import ResizeService from '@/services/ResizeService';

// Auto generate selectors
// https://docs.pmnd.rs/zustand/guides/auto-generating-selectors
type WithSelectors<S> = S extends { getState: () => infer T }
	? S & { use: { [K in keyof T]: () => T[K] } }
	: never;

const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(
	_store: S
) => {
	const store = _store as WithSelectors<typeof _store>;
	store.use = {};
	for (const k of Object.keys(store.getState())) {
		(store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
	}

	return store;
};

interface BrowserState {
	isTouch: () => boolean;
	isIos: () => boolean;
	windowWidth: number;
	windowHeight: number;
	screenWidth: number;
	screenHeight: number;
	vw: number;
	vh: number;
	device: string;
	breakpoint: string;
	orientation: string;
	scale: number;
	fontSize: number;
	update: () => void;
}

const useBrowserStoreBase = create<BrowserState>()((set, get) => ({
	isTouch: () => !env.default.desktop,
	isIos: () => env.default.ios,
	windowWidth: 1,
	windowHeight: 1,
	screenWidth: 1,
	screenHeight: 1,
	vw: 1,
	vh: 1,
	device: 'desktop',
	breakpoint: 'mobile',
	orientation: 'landscape',
	scale: 1,
	fontSize: 1,
	update: () =>
		set((state) => {
			const resizeService = ResizeService;
			return {
				...state,
				windowWidth: window.innerWidth,
				windowHeight: window.innerHeight,
				// Window
				vw: Math.max(
					document.documentElement.clientWidth || 0,
					window.innerWidth || 0
				),
				// vh.value = vhCheck().vh
				// @ts-expect-error
				vh: resizeService.vh,

				// Browser
				// @ts-expect-error
				device: resizeService.deviceType,
				// @ts-expect-error
				breakpoint: resizeService.breakpoint,
				// @ts-expect-error
				orientation: resizeService.orientation,
				// @ts-expect-error
				scale: resizeService.scale,
				// @ts-expect-error
				fontSize: resizeService.fontSize,
			};
		}),
}));

export const useBrowserStore = createSelectors(useBrowserStoreBase);
