// The url hardcoded in the LVMH side has a ?clearcache=XXXXXXXX query param
// that needs to be removed in order for deeplinking to work properly
export function deeplinking(fullPath) {
	if (fullPath.indexOf('clearcache') !== -1) {
		let path = fullPath.replace(/\?clearcache=[0-9]+/, '');
		if (path) {
			if (path.slice(-1) !== '/') {
				path = `${path}/`;
			}
			return path;
		}
	}
	return '';
}
