import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import {
    defaultSplitCharFadeInOptions,
    defaultSplitLineSlideUpOptions,
    defaultSplitWordFadeSlideInOptions,
    defaultSplitWordFadeSlideOutOptions,
} from './split.config';
import type {
    SplitLineSlideUpOptions,
    SplitType,
    SplitWordFadeSlideInOptions,
    SplitWordFadeSlideOutOptions,
} from './split.types';
import {getMinimumStaggerDuration} from './split.utils';
import {fadeFromTo, fadeTo} from '@/utils/animation/fade/fade';
import {SplitCharFadeInOptions} from "./split.types";

gsap.registerPlugin(SplitText);

export function splitElement(
    element: HTMLElement,
    splitType: SplitType,
    props?: {
        charsClass?: string;
        wordsClass?: string;
        linesClass?: string;
    },
    addInnerChild?: boolean,
): Array<HTMLElement> {
    let elements: Array<HTMLElement> = element.querySelectorAll(
        `.split-element.split-${splitType}`,
    ) as unknown as Array<HTMLElement>;

    if (elements.length === 0) {
        let type: string = splitType;
        if (splitType === 'chars') {
            type = `${splitType} words`;
        }
        if (splitType === 'lines') {
            type = `${splitType}`;
        }

        const split = new SplitText(element, {
            type, // 'chars,words,lines',
            charsClass: `split-element split-chars ${props?.charsClass ?? ''}`,
            wordsClass: `split-element split-words ${props?.wordsClass ?? ''}`,
            linesClass: `split-element split-lines ${props?.linesClass ?? ''}`,
        });

        elements = split[splitType] as Array<HTMLElement>;

        for(let splitElement of elements ){
            splitElement.setAttribute( 'aria-hidden', 'true' );
        };

        if (addInnerChild) {
            const innerElements: Array<HTMLElement> = [];
            elements.forEach((splitElement) => {
                const div = document.createElement('div');
                div.innerHTML = splitElement.innerHTML;
                // eslint-disable-next-line  no-param-reassign
                splitElement.innerHTML = '';
                splitElement.setAttribute( 'aria-hidden', 'true' );
                splitElement.appendChild(div);
                innerElements.push(div);
            });

            elements = innerElements;
        }
    } else if (addInnerChild) {
        const children: Array<HTMLElement> = [];
        elements.forEach((element) => {
            const child = element.querySelector('div') as HTMLElement;
            if (child) {
                children.push(child);
            }
        });

        if (children.length > 0) {
            elements = children;
        }
    }

    return elements;
}

export function splitLinesSlideUp(
    element: HTMLElement,
    options: SplitLineSlideUpOptions = {},
): gsap.core.Timeline {
    const {splitType, y, duration, reversed, maxStaggerDuration, minStaggerDuration, addInnerChild, ease} = {
        ...defaultSplitLineSlideUpOptions,
        ...options,
    };
    const timeline = gsap.timeline();

    if (splitType) {
        let elements = splitElement(
            element,
            splitType,
            {
                linesClass: 'mask-split-line',
            },
            addInnerChild,
        );

        timeline.fromTo(
            element,
            {
                y: y,
                opacity: 0,
            },
            {
                y: 0,
                ease,
                duration,
                opacity: 1,
                clearProps: 'y',
            },
            0,
        );

        if(reversed) {
            elements = elements.reverse()
        }

        if (elements && elements.length > 0) {
            timeline.fromTo(
                elements,
                {
                    y: y,
                },
                {
                    y: 0,
                    ease,
                    duration,
                    clearProps: 'y',
                    stagger: getMinimumStaggerDuration(
                        elements.length,
                        maxStaggerDuration,
                        minStaggerDuration,
                    ),
                },
                0,
            );
        } else {
            console.log(element, element.innerHTML, 'no text');
        }
    }

    return timeline;
}

export function splitLinesSlideOut(
    element: HTMLElement,
    options: SplitLineSlideUpOptions = {},
): gsap.core.Timeline {
    const {splitType, y, duration, maxStaggerDuration, minStaggerDuration, addInnerChild} = {
        ...defaultSplitLineSlideUpOptions,
        ...options,
    };
    const timeline = gsap.timeline();

    if (splitType) {
        const elements = splitElement(
            element,
            splitType,
            {
                linesClass: 'mask-split-line',
            },
            addInnerChild,
        );

        timeline.to(
            elements,
            {
                y: y,
                duration,
                stagger: getMinimumStaggerDuration(elements.length, maxStaggerDuration, minStaggerDuration),
            },
            0,
        );
    }

    return timeline;
}

export function splitWordFadeSlideIn(
    element: HTMLElement,
    options: SplitWordFadeSlideInOptions = {},
): gsap.core.Timeline {
    const {
        splitType,
        y,
        duration,
        maxStaggerDuration,
        minStaggerDuration,
        addInnerChild,
        ease,
        from,
    } = {
        ...defaultSplitWordFadeSlideInOptions,
        ...options,
    };
    const timeline = gsap.timeline();

    if (splitType) {
        const elements = splitElement(
            element,
            splitType,
            {
                linesClass: '',
            },
            addInnerChild,
        );

        if (element) {
            timeline.add(fadeFromTo(element, {duration: 0.001}), 0);
        }

        if (elements && elements.length > 0) {
            timeline.add(
                fadeFromTo(elements, {
                    from,
                    duration,
                    stagger: getMinimumStaggerDuration(
                        elements.length,
                        maxStaggerDuration,
                        minStaggerDuration,
                    ),
                }),
                0,
            );
            timeline.fromTo(
                elements,
                {
                    y: y,
                },
                {
                    y: 0,
                    ease,
                    duration,
                    clearProps: 'y',
                    stagger: getMinimumStaggerDuration(
                        elements.length,
                        maxStaggerDuration,
                        minStaggerDuration,
                    ),
                },
                0,
            );
        } else {
            console.log(element, element.innerHTML, 'no text');
        }
    }

    return timeline;
}

export function splitWordFadeSlideOut(
    element: HTMLElement,
    options: SplitWordFadeSlideOutOptions = {},
): gsap.core.Timeline {
    const {
        splitType,
        y,
        duration,
        maxStaggerDuration,
        minStaggerDuration,
        addInnerChild,
        ease,
        to,
    } = {
        ...defaultSplitWordFadeSlideOutOptions,
        ...options,
    };
    const timeline = gsap.timeline();

    if (splitType) {
        const elements = splitElement(
            element,
            splitType,
            {
                linesClass: '',
            },
            addInnerChild,
        );

        if (elements && elements.length > 0) {
            timeline.add(
                fadeTo(elements, {
                    to,
                    duration,
                    stagger: getMinimumStaggerDuration(
                        elements.length,
                        maxStaggerDuration,
                        minStaggerDuration,
                    ),
                }),
                0,
            );
            timeline.to(
                elements,
                {
                    y,
                    ease,
                    duration,
                    stagger: getMinimumStaggerDuration(
                        elements.length,
                        maxStaggerDuration,
                        minStaggerDuration,
                    ),
                },
                0,
            );
            if (element) {
                timeline.add(fadeTo(element, {duration: 0.001}));
            }
        } else {
            console.log(element, element.innerHTML, 'no text');
        }
    }

    return timeline;
}

export function splitCharFadeIn(
    element: HTMLElement,
    options: SplitCharFadeInOptions = {},
): gsap.core.Timeline {
    const {
        splitType,
        duration,
        maxStaggerDuration,
        minStaggerDuration,
        ease,
        from,
    } = {
        ...defaultSplitCharFadeInOptions,
        ...options,
    };
    const timeline = gsap.timeline();

    if (splitType) {
        const elements = splitElement(
            element,
            splitType,
            {
                linesClass: '',
            },
        );

        if (element) {
            timeline.add(fadeFromTo(element, {duration: 0.001}), 0);
        }

        if (elements && elements.length > 0) {
            timeline.add(
                fadeFromTo(elements, {
                    from,
                    duration,
                    ease,
                    stagger: getMinimumStaggerDuration(
                        elements.length,
                        maxStaggerDuration,
                        minStaggerDuration,
                    ),
                }),
                0,
            );
        } else {
            console.log(element, element.innerHTML, 'no text');
        }
    }

    return timeline;
}