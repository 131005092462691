import { useEffect, useState } from "react";

export default function useEscToClose(onCloseMethod: () => void) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const EscTest = (e: KeyboardEvent) => {
      if (e.key !== "Escape") return;
      onCloseMethod();
    };

    const onOpen = () => {
      document.addEventListener("keydown", EscTest);
    };
    const onClose = () => {
      document.removeEventListener("keydown", EscTest);
    };

    if (isOpen) onOpen();
    else onClose();

    return () => {
      document.removeEventListener("keydown", EscTest);
    };
  }, [isOpen]);

  return { isOpen, setIsOpen };
}
