import styled from 'styled-components';
import TextCopy from '../app/TextCopy';
import { mediaDown } from '@/utils/breakpoints';

declare interface DesignerBiographyProps {
  className?: string;
  description: string;
}

const StyledDesignerBiography = styled.div`
  width: 54rem;
  padding: 4.5rem;
  background-color: var(--cl-white);
  border: 1px solid var(--cl-black);

  .description {
    font-size: 2rem;
    line-height: 3rem;
  }

  ${mediaDown.md2`
    padding: 2.5rem 2rem;
    width: 100%;
    text-align: center;

    .description{
      font-size: 1.4rem;
      line-height: 1.68rem;
    }
  `}
`;

export default function DesignerBiography({
  className,
  description,
}: DesignerBiographyProps) {
  return (
    <StyledDesignerBiography
      className={className}
      data-speed="1.1"
      data-lag="0.25"
    >
      <TextCopy
        as="p"
        className="description"
        label={description}
        transitionInType={'none'}
      />
    </StyledDesignerBiography>
  );
}
