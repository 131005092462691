import { Brand } from "@/interfaces/Brand";
import { useEffect, useState } from "react";

const useFaceTimer = (brand: Brand) => {
  const [visibleFace, setVisibleFace] = useState(0);

  useEffect(() => {
    if (brand.designers.length > 1) {
      const faceChangeInterval = setInterval(() => {
        const totalFaces = brand.designers.length;
        setVisibleFace((vf) => {
          return (vf + 1) % totalFaces;
        });
      }, 5000);

      return () => clearInterval(faceChangeInterval);
    }
  }, [brand.designers.length]);

  return visibleFace;
};

export default useFaceTimer;
