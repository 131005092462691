import ScrollTrigger from "gsap/ScrollTrigger";
import { useCallback, useEffect } from "react";
import { useAppStore, useBrowserStore } from "@/store";
import { useLocation } from "react-router-dom";

export function usePageScrollEffects(): void {
  // const { app, browser } = useStores();
  const location = useLocation();
  const isTouch = useBrowserStore.use.isTouch();
  const isIos = useBrowserStore.use.isIos();

  const scroller = useAppStore.use.scroller();

  let refreshTimeout!: NodeJS.Timeout;

  const killEffects = useCallback(() => {
    if (scroller && !isTouch()) {
      scroller?.effects().forEach((t: ScrollTrigger) => t.kill());
      ScrollTrigger.refresh();
    }
  }, [scroller, isTouch]);

  const parseEffect = useCallback(() => {
    setTimeout(() => {
      if (scroller && !isTouch()) {
        killEffects();
        scroller?.effects("[data-speed], [data-lag]", {});
      }
      ScrollTrigger.sort();
      ScrollTrigger.refresh();
    }, 1000); // AnimationPresence time
  }, [scroller, killEffects, isTouch, location.pathname]);

  const killTimeout = useCallback(() => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }
  }, [refreshTimeout]);

  useEffect(() => {
    parseEffect();
    return () => {
      killTimeout();
      killEffects();
    };
  }, [killEffects, killTimeout, parseEffect]);
}
