// @ts-nocheck
import { css } from "styled-components";

const device = {
  xs: "400px",
  sm: "600px",
  mdAndUp: "768px",
  mdAndDown: "767px",
  md: "900px",
  lg: "1280px",
  xl: "1440px",
  xxl: "1920px",
};

export const media = {
  xs: (args: any) => css`
    @media (min-width: ${device.xs}) {
      ${css(...args)};
    }
  `,
  sm: (...args: any) => css`
    @media (min-width: ${device.sm}) {
      ${css(...args)};
    }
  `,
  md2: (...args: any) => css`
    @media (min-width: ${device.mdAndUp}) {
      ${css(...args)};
    }
  `,
  md: (...args: any) => css`
    @media (min-width: ${device.md}) {
      ${css(...args)};
    }
  `,
  lg: (...args: any) => css`
    @media (min-width: ${device.lg}) {
      ${css(...args)};
    }
  `,
  xl: (...args: any) => css`
    @media (min-width: ${device.xl}) {
      ${css(...args)};
    }
  `,
  xxl: (...args: any) => css`
    @media (min-width: ${device.xxl}) {
      ${css(...args)};
    }
  `,
};

export const mediaDown = {
  xs: (args: any) => css`
    @media (max-width: ${device.xs}) {
      ${css(...args)};
    }
  `,
  sm: (...args: any) => css`
    @media (max-width: ${device.sm}) {
      ${css(...args)};
    }
  `,
  md2: (...args: any) => css`
    @media (max-width: ${device.mdAndDown}) {
      ${css(...args)};
    }
  `,
  md: (...args: any) => css`
    @media (max-width: ${device.md}) {
      ${css(...args)};
    }
  `,
  lg: (...args: any) => css`
    @media (max-width: ${device.lg}) {
      ${css(...args)};
    }
  `,
  xl: (...args: any) => css`
    @media (max-width: ${device.xl}) {
      ${css(...args)};
    }
  `,
  xxl: (...args: any) => css`
    @media (max-width: ${device.xxl}) {
      ${css(...args)};
    }
  `,
};
