import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import type {
  TransitionController,
  SetupTransitionOptions,
} from "../../transition-controller/core";
import { useTransitionController } from "@/utils/transition-controller/react";
import useScrollTrigger from "@/utils/scroll/composables/useScrollTrigger";
import { RefObject } from "react";
import ButtonBase from "@/components/app/ButtonBase";

gsap.registerPlugin(ScrollTrigger);

/**
 * Creates gsap.core.Timeline that will start as soon the component is mounted and component is in-view.
 *
 * @example
 * const elementRef = Ref<HTMLDivElement>(null);
 *
 * const { inView, transitionController } = useScrollTransition({
 *   scroll: {
 *     trigger: elementRef,
 *     variables: {
 *       onEnter() {
 *         console.log('enter');
 *       }
 *     }
 *   },
 *   setupOptions: () => ({
 *     refs: {
 *       elementRef,
 *     },
 *     setupTransitionInTimeline,
 *   }),
 * });
 *
 * watch(inView, () => {
 *   console.log(inView);
 * });
 */

export function useScrollTransition<T>(options: {
  scroll: {
    canTransitionIn?: boolean;
    trigger: RefObject<HTMLDivElement | typeof ButtonBase>;
    triggerOut?: RefObject<HTMLDivElement | typeof ButtonBase>;
    disableTriggerInOnScroll?: boolean;
    variables?: ScrollTrigger.Vars;
    pin?: RefObject<HTMLElement>;
    endTrigger?: RefObject<HTMLElement>;
    reverseOnLeaveBack?: boolean;
    transitionInDelay?: number;
  };
  setupOptions: () => SetupTransitionOptions<T>;
}): {
  inView: RefObject<boolean>;
  transitionController: TransitionController;
} {
  const transitionController = useTransitionController(options.setupOptions);
  //if (!options?.scroll?.disableTriggerInOnScroll && options.scroll.trigger) {
  // if triggerOutOnScroll

  const { inView } = useScrollTrigger({
    trigger: options.scroll.trigger,
    transition: {
      controller: transitionController,
    },
    variables: options.scroll.variables,
    canTransitionIn: options.scroll.canTransitionIn,
    pin: options.scroll.pin,
    endTrigger: options.scroll.endTrigger,
    transitionInDelay: options.scroll.transitionInDelay,
    disabled: options?.scroll?.disableTriggerInOnScroll,
    reverseOnLeaveBack: options.scroll.reverseOnLeaveBack,
  });

  return {
    inView,
    transitionController,
  };
  //}

  /*return {
    transitionController,
  };*/
}
