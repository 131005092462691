// export default {};
import {gsap} from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export function scrollTargetTo(
    target: HTMLElement | Window,
    x: number,
    y: number,
    duration?: number,
    options?: {
        easing?: string;
        callback?: () => void;
    },
): Promise<void> {
    return new Promise((resolve) => {
        gsap.to(target, {
            duration: duration ? duration / 1000 : 0,
            ease: options?.easing,
            scrollTo: {
                y,
                x,
            },
            onComplete: () => {
                resolve();

                if (options?.callback) {
                    options.callback();
                }
            },
        });
    });
}

