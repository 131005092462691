import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.config({
  ignoreMobileResize: true,
  // autoRefreshEvents: "visibilitychange,DOMContentLoaded,load",
});

ScrollTrigger.config({
  ignoreMobileResize: true,
});

export const defaultScrollTriggerOptions = {
  start: "top bottom",
  end: "bottom top",
  scrub: false,
  markers: false,
};

export const getDefaultScrollTriggerVariables = (
  options?: ScrollTrigger.StaticVars
): ScrollTrigger.StaticVars => ({
  ...defaultScrollTriggerOptions,
  ...options,
});

export const createScrollTrigger = (
  trigger: HTMLElement,
  animation?: gsap.core.Timeline,
  variables?: ScrollTrigger.Vars
): ScrollTrigger =>
  ScrollTrigger.create({
    animation,
    trigger,
    ...getDefaultScrollTriggerVariables(variables),
  });
