import type { SetupTransitionOptions, TransitionController } from "../../core";
import {
  createTransitionController,
  registerTransitionController,
  unregisterTransitionController,
} from "../../core";
import { useEffect, useMemo, useRef, useState } from "react";

/**
 * @example
 * const myRef = ref<HTMLDivElement>(null);
 *
 * const controller = useTransitionController(() => ({
 *   ref: myRef,
 *   refs: {
 *     myRef,
 *   },
 *   setupTransitionInTimeline(timeline, { myRef }) { ... },
 *   setupTransitionInTimeline(timeline, { myRef }) { ... },
 * }));
 *
 */

export function useTransitionController<T>(
  setupOptions: () => SetupTransitionOptions<T>
): TransitionController {
  // const flowProvider = useFlowProvider();
  const [controller] = useState<TransitionController>(
    createTransitionController(setupOptions())
  );
  useEffect(() => {
    registerTransitionController(controller);

    controller.setupTimeline({
      direction: "in",
    });
    return () => {
      controller.dispose();
      unregisterTransitionController(controller);
    };
  }, []);

  return controller;
}
