import ResizeService from "@/services/ResizeService";
import { useEffect, useRef } from "react";

export const useResize = function (
  handler = (e: any) => {},
  invoke = true,
  order = -1
) {
  const unsubscribe = useRef<() => void>();

  useEffect(() => {
    // @ts-expect-error
    unsubscribe.current = ResizeService.subscribeResize(handler, invoke, order);
    return () => {
      unsubscribe.current && unsubscribe.current();
    };
  }, []);

  return unsubscribe as any;
};

export default useResize;
