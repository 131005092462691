import { DESKTOP_FOCUS_DELAY, MOBILE_FOCUS_DELAY } from "@/constants/global";
import AppContext from "@/context/AppContext";
import { useContext, useMemo } from "react";
import { debounce } from "underscore";

const useDebouncedFocus = (callback: (_: any) => any) => {
  const { device } = useContext(AppContext);

  const debouncedCallback = useMemo(() => {
    if (device?.size) {
      return debounce(
        callback,
        device.size.width < 600 ? MOBILE_FOCUS_DELAY : DESKTOP_FOCUS_DELAY
      );
    } else {
      return debounce(callback, DESKTOP_FOCUS_DELAY);
    }
  }, [device?.size, callback]);

  return debouncedCallback;
};
export default useDebouncedFocus;
