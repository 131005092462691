import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import EnterButton from "@/components/grid/EnterButton";
import GridToggle from "@/components/grid/GridToggle";
import Portrait from "@/components/grid/Portrait";
import brands from "@/config/brands";
import { MENU_HEIGHT, PORTRAIT_SCALE_DURATION } from "@/constants/global";
import AppContext from "@/context/AppContext";
import useDebouncedFocus from "@/hooks/useDebouncedFocus";
import { media, mediaDown } from "@/utils/breakpoints";
import { useAppStore } from "@/store";
import gsap from "gsap";

const SCROLL_ADJUSTMENT = -32;

declare interface GridProps {
  className?: string;
  isScrolling: boolean;
  onFaceLoaded: (brandId: string) => void;
  onFaceHover: (brandId: string) => void;
  onGridScroll: () => void;
  forceMobileGrid?: boolean;
}

const ScrollWrapper = styled.div<{ $isMobileGrid: boolean }>`
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: ${({ $isMobileGrid }) => ($isMobileGrid ? "hidden" : "auto")};

  scroll-padding-top: calc(${MENU_HEIGHT}px + ${SCROLL_ADJUSTMENT}px);

  scroll-snap-type: ${({ $isMobileGrid }) => ($isMobileGrid ? "none" : "y")};

  ${media.sm`
    scroll-padding-top: 0px;
    scroll-snap-type: unset;
  `}
`;

const GridWrapper = styled.div<{ $scrollPadding: number }>`
  position: relative;
  margin: auto auto;
  padding: 0 120px 0 120px;

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  height: 100%;
  justify-items: center;
  align-content: center;
  padding: calc(${MENU_HEIGHT}px + 70px - 14px) 48px 0 48px;
  grid-column-gap: 13px;

  @media (max-width: 767px) {
    &::before {
      content: "";
      width: 100%;
      min-height: ${({ $scrollPadding }) => `calc(${$scrollPadding}px)`};
      scroll-snap-align: none;
    }
    &::after {
      content: "";
      width: 100%;
      min-height: ${({ $scrollPadding }) =>
        `calc(${$scrollPadding}px - ${MENU_HEIGHT}px - ${SCROLL_ADJUSTMENT}px)`};
    }

    &.is-grid {
      display: grid;
      justify-content: center;
      scroll-snap-type: none;
      padding-top: calc(${MENU_HEIGHT}px + 36px);

      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }

    &.is-column {
      .transform-wrapper {
        transition: transform ${PORTRAIT_SCALE_DURATION}ms;
      }
    }
  }

  ${mediaDown.md`
    display: grid;
    flex-direction: column;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
    height: 100%;
    padding: calc(${MENU_HEIGHT}px + 20px) 20px 0 20px;
    max-width: 720px;
  `}

  ${mediaDown.sm`
    display: flex;
    flex-direction: column;
    grid-column-gap: 14px;
    grid-row-gap: 0px;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
  `}
`;

export default function Grid({
  className,
  isScrolling,
  onFaceLoaded = () => {},
  onFaceHover = () => {},
  onGridScroll = () => {},
  forceMobileGrid = false,
}: GridProps) {
  const { activeBrandId, device, screenSize } = useContext(AppContext);

  const scrollerRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);

  const [isMobileGrid, setIsMobileGrid] = useState(true);
  const [isFocusing, setIsFocusing] = useState(false);
  const [scrollPadding, setScrollPadding] = useState(0);
  const [portraitHeight, setPortraitHeight] = useState(0);
  const [cursorPosition, setCursorPosition] = useState({
    cursorX: window.innerWidth / 2,
    cursorY: window.innerHeight / 2,
  });
  const [scrollY, setScrollY] = useState(0);

  const hoverPortrait = useAppStore.use.hoverPortrait();

  const navigate = useNavigate();

  //
  // H A N D L E R S
  //

  const debouncedSetIsFocusing = useDebouncedFocus(setIsFocusing);

  const handleComputeOffset = useCallback(() => {
    const scrollerElement = scrollerRef.current;
    if (gridRef.current && scrollerElement?.clientHeight) {
      const h = scrollerElement.clientHeight;
      // const gstyle = window.getComputedStyle(document.documentElement);

      // TODO: Actually retrieve the styles from the components in column layout.
      // 155 = PortraitWrapper.width
      // 125.4237% = Face.aspectRatio
      // 40px = PortraitWrapper.transform-wrapper.paddingTop = paddingBottom
      const pw = 155;
      const ph = pw * (125.4237 / 100) + (40 + 40);

      const offset = (h - ph) / 2 + MENU_HEIGHT + SCROLL_ADJUSTMENT;

      setScrollPadding(offset);
      setPortraitHeight(ph);
    }
  }, []);

  const handleFaceLoaded = useCallback(
    (brandId: string) => {
      onFaceLoaded(brandId);
    },
    [onFaceLoaded]
  );

  const handleFaceEnter = useCallback(
    (brandId: string) => {
      onFaceHover(brandId);

      if (device?.isDesktop || device?.isMobileLayout) debouncedSetIsFocusing(true);
      else setIsFocusing(true);
    },
    [onFaceHover, device?.isDesktop, debouncedSetIsFocusing, device?.isMobileLayout]
  );

  const handleFaceLeave = useCallback(
    (brandId: string) => {
      onFaceHover("");
      debouncedSetIsFocusing.cancel();
      setIsFocusing(false);
    },
    [onFaceHover, debouncedSetIsFocusing]
  );

  const handlePortraitIntersected = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((e) => {
        const brandId = (e.target as HTMLElement).dataset.brandId;

        if (brandId && device?.isMobileLayout && !isMobileGrid) {
          if (e.isIntersecting) {
            handleFaceEnter(brandId);
          }
        }
      });
    },
    [device?.isMobileLayout, isMobileGrid, handleFaceEnter]
  );

  const handleOnToggleClick = useCallback(
    (targetIndex: number = 1) => {
      // Clear active brand when switching to grid. Else, compute scroll padding top.
      if (!isMobileGrid) {
        handleFaceLeave("");
      }

      // TODO: Move animation to a different file.
      const targetOffset = isMobileGrid ? portraitHeight * (targetIndex - 1) : 0;
      const allPortraits = document.querySelectorAll(".portrait-wrapper");
      const toggleAnim = gsap
        .timeline({ paused: true })
        .to(".portrait-wrapper", {
          x: -10,
          opacity: 0,
          duration: 0.08,
          stagger: -0.015,
          immediateRender: false,
          onStart: () => {
            allPortraits.forEach((el) => {
              (el as HTMLElement).style.transition = "none";
            });
          },
          onComplete: () => {
            setIsMobileGrid((v) => !v);
          },
        })
        .fromTo(
          ".portrait-wrapper",
          {
            x: 50,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            duration: 0.08,
            stagger: -0.015,
            immediateRender: false,
            onStart: () => {
              const scrollerElement = scrollerRef.current;
              if (scrollerElement) {
                scrollerElement.scrollTop = targetOffset;
              }

              allPortraits.forEach((el) => {
                (el as HTMLElement).style.transition = "";
              });
            },
            clearProps: "opacity",
          },
          "+=0.02"
        );

      toggleAnim.play();
    },
    [handleFaceLeave, portraitHeight, isMobileGrid]
  );

  const handleFaceClick = useCallback(
    (brandIndex: number, brandId: string) => {
      // Desktop: navigate to page
      if (device?.isDesktop || device?.isDesktopLayout) {
        navigate("/" + brandId);
      }

      // In tablet layout, double tap on a portrait will navigate to the designer page.
      else if (device?.isTabletLayout) {
        const brandFace = document.querySelector(`#portrait-${brandId}`);
        if (brandFace?.classList.contains("in-focus")) navigate("/" + brandId);
      }

      // Mobile grid: switch to column
      else if (device?.isMobileLayout && isMobileGrid && !isScrolling) {
        handleOnToggleClick(brandIndex);
      }
    },
    [
      device?.isMobileLayout,
      device?.isTabletLayout,
      device?.isDesktopLayout,
      device?.isDesktop,
      navigate,
      isMobileGrid,
      handleOnToggleClick,
      isScrolling,
    ]
  );

  //
  // E F F E C T S
  //

  useEffect(() => {
    if (!isMobileGrid && device?.isMobileLayout) {
      const scrollerElement = scrollerRef.current;
      const portraitObserver = new IntersectionObserver(handlePortraitIntersected, {
        root: scrollerElement,
        rootMargin: "-49% 0% -49% 0%",
        threshold: [0],
      });

      if (gridRef.current) {
        const childrenList = gridRef.current.children;
        for (let i = 0; i < childrenList.length; i++) {
          if (childrenList[i].classList.contains("portrait-wrapper"))
            portraitObserver.observe(childrenList[i]);
        }
      }

      return () => {
        portraitObserver.disconnect();
      };
    }
  }, [handlePortraitIntersected, isMobileGrid, device?.isMobileLayout]);

  useEffect(() => {
    const onMouseMove = (event: MouseEvent) => {
      setCursorPosition({
        cursorX: hoverPortrait.x || event.clientX,
        cursorY: hoverPortrait.y || event.clientY,
      });
    };

    const onTouchMove = (event: TouchEvent) => {
      setCursorPosition({
        cursorX: hoverPortrait.x || event.touches[0].pageX,
        cursorY: hoverPortrait.y || event.touches[0].pageY,
      });
    };

    if (device?.isDesktop) {
      document.addEventListener("mousemove", onMouseMove);
      return () => document.removeEventListener("mousemove", onMouseMove);
    } else {
      document.addEventListener("touchmove", onTouchMove);
      return () => document.removeEventListener("touchmove", onTouchMove);
    }
  }, [device?.isDesktop, hoverPortrait]);

  useEffect(() => {
    const onScroll = (event: Event) => {
      onGridScroll();
      const scrollTop = scrollerRef.current?.scrollTop || 0;

      setScrollY(scrollTop);
      setCursorPosition(({ cursorX }) => ({
        cursorX,
        cursorY: window.innerHeight / 2,
      }));
    };

    const scrollContainer = scrollerRef.current;
    scrollContainer?.addEventListener("scroll", onScroll);

    return () => scrollContainer?.removeEventListener("scroll", onScroll);
  }, [onGridScroll]);

  // Clear active brand on resize.
  useEffect(() => {
    handleFaceLeave("");

    // Compute margin for column layout
    handleComputeOffset();
  }, [screenSize, handleFaceLeave, handleComputeOffset]);

  return (
    <motion.div
      className={`${className}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.15, duration: 0.3 }}
      style={{ position: "relative", height: "100%" }}
    >
      <ScrollWrapper
        ref={scrollerRef}
        className={`grid-scroll-container`}
        $isMobileGrid={isMobileGrid}
      >
        <GridWrapper
          ref={gridRef}
          className={`${isMobileGrid ? `is-grid` : `is-column`}`}
          $scrollPadding={scrollPadding}
        >
          {brands.map((brand, brandIndex) => (
            <Portrait
              key={brandIndex}
              className="portrait-wrapper"
              number={brandIndex + 1}
              brand={brand}
              cursorPosition={cursorPosition}
              scrollY={scrollY}
              onFaceLoaded={handleFaceLoaded}
              onFaceEnter={handleFaceEnter}
              onFaceLeave={handleFaceLeave}
              onFaceClick={handleFaceClick}
              isMobileGrid={isMobileGrid}
              isNavBar={false}
              isFocusing={isFocusing}
              isStatic={!!device?.isMobileLayout}
              isVisible={activeBrandId === brand.id}
            />
          ))}
        </GridWrapper>
      </ScrollWrapper>

      <AnimatePresence>{isFocusing && device?.isTouchEnabled() && <EnterButton />}</AnimatePresence>

      {!forceMobileGrid && (
        <GridToggle
          className="layout-toggle"
          isToggled={isMobileGrid}
          isScrolling={isScrolling}
          onToggleClick={() => handleOnToggleClick(1)}
        />
      )}
    </motion.div>
  );
}
