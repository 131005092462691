import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import styled from "styled-components";

const imageObjectFitOptions = ['cover', 'contain', 'scale-down', 'none'];
type ImageObjectFit = typeof imageObjectFitOptions[number];

const ImageWrapper = styled.picture`
  &,img{
    width: 100%;
    display: block;
  }

  &[class*="fit-"]{
    height: 100%;
    position: relative;
    img {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.fit-cover img {
    object-fit: cover;
    font-family: "object-fit: cover";
  }

  &.fit-contain img {
    object-fit: contain;
    font-family: "object-fit: contain";
  }


  &.fit-none img {
    object-fit: none;
    font-family: "object-fit: none";
  }

  &.fit-scale-down img {
    object-fit: scale-down;
    font-family: "object-fit: scale-down";
  }

  &.fill-absolute {
    top: 0;
    left: 0;
    position: absolute;
  }
`;

type ImageProps = {
  src: string;
  alt: string;
  loading?: 'eager' | 'lazy' | 'auto';
  objectFit?: ImageObjectFit;
  className?: string
  fillAbsolute?: boolean;

};

const Image = forwardRef(({
  src,
  alt,
  objectFit,
  fillAbsolute = false,
  loading = 'lazy',
  className
}: ImageProps, ref) => {

  const innerRef = useRef<HTMLElement>(null);
  useImperativeHandle(ref, () => innerRef.current, []);

  return <ImageWrapper ref={innerRef} className={`image fit-${objectFit} ${fillAbsolute ? 'fill-absolute ' : ''}${className}`} >
    <source type="image /avif" srcSet={`${src.replace('.jpg', '.avif')}`} />
    < source type="image/webp" srcSet={`${src.replace('.jpg', '.webp')}`
    } />
    <img src={src} alt={alt} decoding="async" loading={"lazy"} tabIndex={0}/>
  </ImageWrapper >
})


export default Image;