import styled from "styled-components";
import { MENU_HEIGHT } from "@/constants/global";
import { HeaderText } from "@/styles/typography.style";
import { media } from "@/utils/breakpoints";

declare interface HeaderProps {
  className?: string;
  isScrolling: boolean;
}

const EASING_DURATION = 1200;

const HeaderWrapper = styled(HeaderText).attrs({ as: "h2" })<{ $isScrolling: boolean }>`
  position: fixed;
  top: calc(${MENU_HEIGHT}px + 12px);
  left: 0;
  z-index: -1;

  opacity: ${({ $isScrolling }) => ($isScrolling ? "0.15" : "1")};
  transition: opacity ease ${EASING_DURATION}ms;

  padding: 16px 20px;

  ${media.sm`
    padding: 12px 21px;
    width: 300px;
  `}

  ${media.md`
    padding: 12px 44px;
    width: 400px;
  `}
`;

export default function Header({ className, isScrolling }: HeaderProps) {
  return (
    <HeaderWrapper className={className} $isScrolling={isScrolling}>
      2024 LVMH Prize Finalists
    </HeaderWrapper>
  );
}
