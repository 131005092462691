import styled from "styled-components";
import TextCopy from "../app/TextCopy";
import { useTranslation } from 'react-i18next';
import ButtonPrimary from "../app/ButtonPrimary";
import DesignerCollectionItem, { ChildRef } from "./DesignerCollectionItem";
import { PropsWithChildren, forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import useEscToClose from "@/hooks/useEscToClose";
import useCollectionCarousel from "./useCollectionCarousel";
import { useTransitionController } from "@/utils/transition-controller/react";
import gsap from "gsap";
import {
  addScrollLagToItems,
  setupStackingAnimation,
  setupTransitionInTimeline,
  setupTransitionOutTimeline
} from "@/hooks/useTransitionsDesignersCollection";
import { breakpointWidthMin, vhProperty } from "@/styles/mixins";
import useDeviceProperties from "@/hooks/useDeviceProperties";
import useScrollTrigger from "@/utils/scroll/composables/useScrollTrigger";
import { useAppStore, useBrowserStore } from "@/store";
import { mediaDown } from "@/utils/breakpoints";
import ScrollTrigger from "gsap/ScrollTrigger";
import useResize from "@/hooks/useResize";

declare interface DesignerCollectionProps {
  id: string;
  className?: string
  designerId: string;
  dirName: string;
  content: {
    id: string;
  };
  onOverlayOpen: (id: string) => void;
  onOverlayClose: () => void;
}

const StyledDesignerCollection = styled.div`
  position: relative;
  ${vhProperty({ property: "height", value: 100 })};
  display: flex;
  align-items: center;

  .carousel-wrapper{
  position: relative;
  }

.carousel-content{
  position: relative;
  display: flex;
  align-items: flex-start;
  z-index: 0;
}

.collection-label{
  position: absolute;
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 1.875rem;
  max-width: 48.7rem;
  width: 25vw;
  text-align: left;
}

.ui-collection{
  position: absolute;
  top: 1.8rem;
  left: 1.8rem;
}

.close-cta,
.view-collection-cta{
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.collection-item{
  flex-shrink: 0;

  &:not(:first-child){
    margin-left: -3%;
  }

  &:nth-child(1){
    z-index: 3;
  }

  &:nth-child(2){
    z-index: 2;
    margin-top: 7.2rem;
  }

  &:nth-child(3){
    z-index: 1;
    margin-top: 14.4rem;
  }

  &:nth-child(4){
    margin-top: 21.6rem;
  }
}

.name-holder{
  position: absolute;
}

.name{
  font-size: 6rem;
  line-height: 0.75;
  font-weight: 400;
  letter-spacing: -0.012em;
  z-index: 1;
}

${breakpointWidthMin({
  width: "1024px", content: {
    '.nameHolder': {
      left: '-14.1rem;'
    }
  }
})};

  ${mediaDown.md2`
  padding-top: 4rem;

  .carousel-wrapper{
    padding-bottom: 6rem;
  }

  .collection-label{
    width: 100vw;
    max-width: 100vw;
    text-align:center;
    left: calc(var(--spacer-designer-page-mobile) * -1);
  }

  .collection-item{

    &:not(:first-child){
      margin-left: -4.9rem;
    }

    &:nth-child(2){
      margin-top: 1.2rem;
    }

    &:nth-child(3){
      margin-top: 2.4rem;
    }

    &:nth-child(4){
      margin-top: 3.6rem;
    }
  }

  .close-cta{
    // left: -1.5rem;
    // top: -4.5rem;
    // transform: translateY(-100%);
  }

  .name{
    font-size: 4rem;
    line-height: 3rem;
  }
`}

`;

const DesignerCollection = forwardRef(({
  className,
  designerId,
  dirName,
  content,
  onOverlayOpen,
  onOverlayClose,
  ...props
}: PropsWithChildren<DesignerCollectionProps>, ref) => {
  const collectionItems = 4;
  const collectionLabels = 5;
  const device = useDeviceProperties();
  const isTouch = useBrowserStore.use.isTouch();
  const browser = useBrowserStore();
  const toggleScrollerPause = useAppStore.use.toggleScrollerPause();
  const scrollTo = useAppStore.use.scrollTo();
  const scrollY = useAppStore.use.scrollY();
  const scrollYRef = useRef<number>(scrollY);

  const { t } = useTranslation();
  const collections: any = t(`designers.${designerId}.${content.id}.images`, { returnObjects: true });

  const elementRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => elementRef.current, []);
  const nameHolderRef = useRef<HTMLDivElement>(null);
  const closeCtaRef = useRef<typeof ButtonPrimary>(null)
  const viewCtaRef = useRef<typeof ButtonPrimary>(null)
  const uiCollectionRef = useRef<HTMLDivElement>(null)
  const collectionLabelRef = useRef<typeof TextCopy>(null)
  const nameRefs = useRef<Array<typeof TextCopy>>([])
  const carouselWrapperRef = useRef<HTMLDivElement>(null)
  const designerCollectionItemRefs = useRef<
    Array<ChildRef>
  >([]);
  const stackingTimeline = useRef<gsap.core.Timeline>();
  const delayedOpenCall = useRef<gsap.core.Tween | null>(null);

  const firstInteraction = useRef<boolean>(false);
  const isOpenRef = useRef<boolean>(false);

  function toggleCollection(): void {
    firstInteraction.current = true;
    setIsOpen(!isOpen)
  }
  const { isOpen, setIsOpen } = useEscToClose(toggleCollection)

  const {
    carouselContentRef,
    draggableInstance,
    disableEvents,
    draggableIsReady,
    gotoPosition,
  } = useCollectionCarousel({
    boundsRef: elementRef,
    triggerRef: elementRef,
    designerCollectionItemRefs
  });

  const scrollToCenter = useRef(async () => {
    let currentY = scrollYRef.current || window.scrollY;
    const distance = (elementRef.current?.getBoundingClientRect().top as number);
    await scrollTo(0, currentY + distance, Math.abs(distance) * 0.9)
  })

  const transitionController = useTransitionController(() => ({
    refs: {
      elementRef,
      designerCollectionItemRefs,
      nameHolderRef,
      closeCtaRef,
      viewCtaRef,
      collectionLabelRef,
    },
    ref: elementRef,
    setupTransitionInTimeline,
    setupTransitionOutTimeline,
  }));

  const { inView } = useScrollTrigger({
    trigger: elementRef,
  });

  const updateDraggableState = useCallback(() => {
    draggableInstance?.current?.disable()
    if (isOpenRef.current && browser.breakpoint === 'mobile') {
      disableEvents(false)
    } else {
      disableEvents(true)
    }
  }, [browser.breakpoint])

  const scrollTrigger = useRef<ScrollTrigger>();

  useScrollTrigger({
    trigger: nameHolderRef,
    variables: {
      scrub: false,
      start: 'bottom 95%',
      onUpdate: ({ direction }) => {
        if (direction > 0 && !isOpenRef.current) {
          stackingTimeline?.current?.play()
        } else {
          stackingTimeline?.current?.reverse()
        }
      },
      onLeaveBack() {
        stackingTimeline?.current?.reverse()
      },
      onLeave() {
        stackingTimeline?.current?.reverse()
      },
    }
  })

  const onOpen = useCallback(async () => {
    killDelayOpenCall()
    await scrollToCenter.current();
    toggleScrollerPause(true);
    onOverlayOpen(content.id)

    stackingTimeline.current?.reverse()
    delayedOpenCall.current = gsap.delayedCall(0.4, () => {
      transitionController.transitionIn();
      updateDraggableState();
    })
  }, [])

  function killDelayOpenCall(): void {
    delayedOpenCall.current?.kill();
  }

  const onClose = useCallback(async () => {

    killDelayOpenCall()

    if (!device.isDesktop) {
      await gotoPosition(0, false)
    }

    delayedOpenCall.current = gsap.delayedCall(0.6, () => {
      onOverlayClose()
      toggleScrollerPause(false);
    });

    stackingTimeline.current?.play()
    await transitionController.transitionOut();
    updateDraggableState();
  }, [])


  useEffect(() => {
    if (!firstInteraction.current) return;
    isOpenRef.current = isOpen;
    isOpen ? onOpen() : onClose()
  }, [isOpen])

  useEffect(() => {
    updateDraggableState()
  }, [browser.breakpoint])

  useEffect(() => {
    if (draggableIsReady.current) {
      updateDraggableState();
    }
  }, [draggableIsReady.current, updateDraggableState])

  useEffect(() => {
    scrollYRef.current = scrollY
  }, [scrollY])

  useEffect(() => {
    stackingTimeline.current = setupStackingAnimation({
      nameRefs,
    })

    updateDraggableState();

    if (isTouch()) {
      scrollTrigger.current = ScrollTrigger.create({
        onUpdate: () => {
          if (inView.current && designerCollectionItemRefs.current && scrollTrigger.current) {
            addScrollLagToItems(designerCollectionItemRefs.current, scrollTrigger.current.getVelocity() / 10)
          }
        }
      });
    }

    return () => {
      if (stackingTimeline?.current) {
        stackingTimeline.current.kill();
      }

      if (scrollTrigger.current) {
        scrollTrigger.current.kill()
      }

      killDelayOpenCall()
    }

  }, [])

  const onResize = (e: any) => {
    const invert = (elementRef.current?.getAttribute("id") === "collection2") //hacky
    const isMobile = e.breakpoint === "mobile"
    if (nameHolderRef?.current) {
      const fixedOffSettLeft = ((isMobile ? 30 : 60) * ((invert) ? -1 : 1));
      const finalOffset = invert ? fixedOffSettLeft * -1 * (isMobile ? 1 : -1) : fixedOffSettLeft;
      const carouselDiv = (carouselContentRef.current?.children[0] as HTMLDivElement);
      const innerDiv = (nameHolderRef.current?.children[0] as HTMLDivElement);
      const imageHeight = designerCollectionItemRefs.current[0].elementRef.offsetHeight;
      const innerWidth = innerDiv.offsetHeight;
      const innerHeight = innerDiv.offsetHeight;
      const carouselHeight = carouselDiv.offsetHeight;
      const imageWidth = carouselDiv.offsetWidth;

      const rowHeight = -(innerDiv.children[0] as HTMLDivElement).offsetHeight * ((invert) ? -1 : 1);
      const rowHeightInvert = (rowHeight * (invert ? 2 : 0)) * (isMobile ? -1 : 0);

      const leftInvert = -((imageWidth - innerWidth) * (invert ? 1 : 0) * (isMobile ? 0 : 1));
      const left = carouselDiv.offsetLeft - finalOffset - leftInvert;

      const topInvert = ((-(imageHeight + innerHeight) * ((invert) ? 1 : 0)) * (isMobile ? 0 : 1));
      const top = carouselDiv.offsetTop + carouselHeight + rowHeight + rowHeightInvert + topInvert;


      nameHolderRef.current.style.setProperty("top", `${top}px`);
      nameHolderRef.current.style.setProperty("left", `${left}px`);
      nameHolderRef.current.style.setProperty("transform", "none");
    }

    if (carouselWrapperRef.current) {
      if (invert && !isMobile) {
        const imageRefLeft = carouselContentRef.current?.querySelector(`.collection-item:nth-child(${collectionItems})`) as HTMLDivElement;
        const imageRefTop = carouselContentRef.current?.querySelector(`.collection-item:nth-child(${collectionItems - 1})`) as HTMLDivElement;
        const label = collectionLabelRef.current as any;
        label.style.setProperty("top", `${imageRefTop.offsetTop}px`);
        label.style.setProperty("left", `${imageRefLeft.offsetLeft}px`);
        label.style.setProperty("transform", "scale(-1, -1) translateY(0%)");

        const firstImage = carouselContentRef.current?.querySelector(`.collection-item:nth-child(1)`) as HTMLDivElement;
        const uiCollection = uiCollectionRef.current as HTMLDivElement;
        uiCollection.style.setProperty("top", `calc(${firstImage.offsetHeight - uiCollection.offsetHeight}px - 1.8rem)`)
        uiCollection.style.setProperty("left", `calc(${firstImage.offsetWidth}px - 1.8rem)`)
        uiCollection.style.setProperty("transform", `scale(-1, -1) translateX(100%)`)
      } else {
        const targetRef = isMobile ? collectionItems : 2
        const imageRef = carouselContentRef.current?.querySelector(`.collection-item:nth-child(${targetRef})`) as HTMLDivElement;
        const label = collectionLabelRef.current as any
        label.style.setProperty("top", `calc(${imageRef.offsetHeight + (imageRef.offsetTop * (isMobile ? 2 : 1))}px)`);
        label.style.setProperty("transform", "translateY(-100%)");

        const uiCollection = uiCollectionRef.current as HTMLDivElement;
        uiCollection.style.removeProperty("top")
        uiCollection.style.removeProperty("left")
        uiCollection.style.removeProperty("transform")
      }


    }
  }

  useResize(onResize)

  return (
    <StyledDesignerCollection id={props.id} ref={elementRef}>


      <div ref={carouselWrapperRef} className={"carousel-wrapper"}>

        <div className={"block-holder"} data-speed="1.1">


          <div
            ref={carouselContentRef}
            className={"carousel-content"}>

            {[...Array(collectionItems)].map((component, index) => {
              return <DesignerCollectionItem
                key={index}
                dataSpeed={"1"}
                dataLag={`${index * 0.25}`}
                ref={el => designerCollectionItemRefs.current[index] = el as ChildRef}
                src={`/images/${designerId}/collections/${content.id}/image-${index + 1}.jpg`}
                className={"collection-item"}
                altText={collections[index].alt} />
            })}

          </div>
          <div ref={uiCollectionRef} className={"ui-collection"}>
            <ButtonPrimary
              ref={viewCtaRef}
              label={t('general.viewCollectionCta')}
              className="view-collection-cta"
              aria-label={t('general.viewCollectionCta')}
              aria-hidden={isOpen}
              buttonSize="medium"
              buttonTheme="secondary"
              transitionOnMount={false}
              disableTriggerInOnScroll={false}
              onClick={toggleCollection}
            />
            <ButtonPrimary
              ref={closeCtaRef}
              label={t('general.closeCta')}
              className={"close-cta"}
              disabled={!isOpen}
              aria-label={t('general.closeCta')}
              aria-hidden={!isOpen}
              buttonSize={"medium"}
              buttonTheme={"secondary"}
              transitionOnMount={false}
              onClick={toggleCollection}
            />
          </div>
        </div>

        <div
          ref={nameHolderRef}
          data-lag="0.1"
          className={"name-holder"}
        >
          <div className="inner-wrapper">
            {[...Array(collectionLabels)].map((component, index) => {
              return <TextCopy
                aria-hidden={index > 1 ? true : false}
                ref={el => nameRefs.current[index] = el as typeof TextCopy}
                key={index}
                as="div"
                className={"name"}
                label={t(`designers.${designerId}.name`)}
                transitionInType={"none"}
                ariaHidden={true}
              />
            })}
          </div>
        </div>

        <TextCopy
          ref={collectionLabelRef}
          label={t(`designers.${designerId}.${content.id}.name`)}
          transitionInType={"splitCharFadeIn"}
          transitionOutType={"fadeOut"}
          transitionOnMount={false}
          className={"collection-label"}
        />

      </div>
    </StyledDesignerCollection >
  );
})

export default DesignerCollection
