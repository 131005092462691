import styled from "styled-components";
import { media } from "@/utils/breakpoints";

const TermsButtonWrapper = styled.a`
  font-family: var(--helvetica);
  font-size: 3.24rem;
  color: rgba(255, 255, 255, 0.5);
  ${media.sm`
    font-size: 1.62rem;
  `}
`;

type TermsButtonProps = {
  focusable?: number;
  className?: string;
};

export default function TermsButton({
  focusable = -1,
  className,
}: TermsButtonProps) {
  return (
    <TermsButtonWrapper
      className={className}
      href={"/docs/terms_and_conditions_2024_lvmh_prize.pdf"}
      target={"_blank"}
      tabIndex={focusable}
    >
      CONDITIONS
    </TermsButtonWrapper>
  );
}
