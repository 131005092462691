import { t } from 'i18next';
import { useBrowserStore } from '@/store';

import styled from 'styled-components';

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	-webkit-backdrop-filter: contrast(0.6) brightness(2.3) blur(30px);
	backdrop-filter: contrast(0.6) brightness(2.3) blur(30px);

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 20px;
	text-align: center;
	opacity: 0;
	color: black;
	pointer-events: none;

	&.show {
		opacity: 1;
		pointer-events: auto;
	}

	.icon-holder {
		margin: 0 auto 35px;
	}

	.icon {
		filter: drop-shadow(0px 0px 4px #ffffff);
		width: 92px;

		svg path {
			fill: black;
		}
	}

	.description {
		font-size: 25px;
		line-height: 1;
		text-transform: uppercase;
		max-width: 420px;
		padding: 0 20px;
		text-shadow: 0 0 4px rgba(255, 255, 255, 0.6);
	}
`;

export default function RotationMessage() {
	const browser = useBrowserStore();
	const isVisible = browser.orientation === 'landscape' &&
		browser.device === 'mobile'
	return (
		<Wrapper
			className={isVisible ? 'show' : undefined}
			aria-hidden={!isVisible}
		>
			<div className="icon-holder">
				<svg
					width="84"
					height="70"
					viewBox="0 0 84 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className="icon"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M19.0359 11.5976C25.0161 5.68775 33.2458 2.03884 42.3329 2.03884C60.0839 2.03884 74.5697 15.9679 75.38 33.4541L69.6757 27.5002L68.1955 28.908L76.0979 37.1562L84.0002 28.908L82.52 27.5002L77.3967 32.8477C76.2808 14.5201 61.0088 0 42.3329 0C32.6844 0 23.9427 3.87688 17.5949 10.15L19.0359 11.5976ZM7.24637 36.7397L1.44699 42.5177L0 41.076L8.22682 32.8795L16.4536 41.076L15.0066 42.5177L9.30035 36.8324C10.2546 54.1841 24.6789 67.9613 42.3329 67.9613C51.5175 67.9613 59.8264 64.2336 65.8217 58.2114L67.2746 59.6472C60.9108 66.0395 52.0851 70.0001 42.3329 70.0001C23.5171 70.0001 8.15631 55.2615 7.24637 36.7397ZM53.0755 18.009C53.1877 18.4434 53.2474 18.8989 53.2474 19.3682V20.0478H53.2466V47.9129V49.2721V49.2724C53.2466 49.4366 53.2393 49.5991 53.225 49.7596C52.9777 52.534 50.6386 54.7093 47.7896 54.7093H36.1936C33.3683 54.7093 31.0445 52.5701 30.7649 49.8287C30.7462 49.6458 30.7367 49.4602 30.7367 49.2724V49.2721V47.9129V19.3694C30.7367 18.2683 31.0652 17.2437 31.6299 16.3875C32.6051 14.9081 34.2852 13.9313 36.1944 13.9313L47.7904 13.9313C50.3332 13.9313 52.4697 15.664 53.0755 18.009ZM36.1936 15.9713C35.0271 15.9713 33.9973 16.5548 33.3823 17.4449C33.3582 17.4798 33.3347 17.5152 33.3119 17.5511C33.2193 17.6966 33.1375 17.8496 33.0676 18.009L50.9159 18.009C50.3894 16.8095 49.1878 15.9713 47.7896 15.9713H36.1936ZM32.783 20.0478L51.2002 20.0478V47.9129H32.783V20.0478ZM32.8512 49.9517H51.132C50.816 51.5027 49.4397 52.6701 47.7896 52.6701H36.1936C34.5436 52.6701 33.1672 51.5027 32.8512 49.9517Z"
						fill="black"
					/>
				</svg>
			</div>

			<div className="description">{t('landscapeWarning')}</div>
		</Wrapper>
	);
}
