import sides from "./sides";

const faceDirections = {
  [sides.CENTER]: {
    mid: 1,
    end: 1,
  },
  [sides.EAST]: {
    mid: 6,
    end: 7,
  },
  [sides.SOUTH_EAST]: {
    mid: 8,
    end: 9,
  },
  [sides.SOUTH]: {
    mid: 10,
    end: 11,
  },
  [sides.SOUTH_WEST]: {
    mid: 12,
    end: 13,
  },
  [sides.WEST]: {
    mid: 14,
    end: 15,
  },
  [sides.NORTH_WEST]: {
    mid: 16,
    end: 17,
  },
  [sides.NORTH]: {
    mid: 2,
    end: 3,
  },
  [sides.NORTH_EAST]: {
    mid: 4,
    end: 5,
  },
};

export default faceDirections;
