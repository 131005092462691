import gsap from 'gsap';
import TextCopy from '@/components/app/TextCopy';
import ButtonPrimary from '@/components/app/ButtonPrimary';
import { findTransitionController } from '@/utils/transition-controller/core';
import { fadeFromTo, fadeTo } from '@/utils/animation/fade/fade';
import { RefObject } from 'react';

export interface DesignerHeaderRefs {
  elementRef?: RefObject<HTMLDivElement>;
  headerTextRef?: RefObject<HTMLDivElement>;
  titleRef?: RefObject<typeof TextCopy>;
  subTitleRef?: RefObject<typeof TextCopy>;
}

export function setupTransitionInTimeline(
  timeline: gsap.core.Timeline,
  { titleRef, subTitleRef }: DesignerHeaderRefs
): void {
  if (titleRef?.current) {
    const controller = findTransitionController(titleRef.current);
    timeline.add(controller?.getTimeline('in') as gsap.core.Timeline, 0);
  }

  if (subTitleRef?.current) {
    const controller = findTransitionController(subTitleRef.current);
    timeline.add(controller?.getTimeline('in') as gsap.core.Timeline, 0.3);
  }
}

export function setupTransitionOutTimeline(
  timeline: gsap.core.Timeline,
  { elementRef }: DesignerHeaderRefs
): void {
  if (elementRef?.current) {
    timeline.add(fadeTo(elementRef?.current), 1);
  }
}

export function setupScrollOutTimeline({
  titleRef,
}: DesignerHeaderRefs): gsap.core.Timeline {
  const timeline = gsap.timeline({ paused: false });

  if (titleRef?.current) {
    timeline.fromTo(
      titleRef.current,
      {
        scale: 1,
      },
      {
        scale: 0.5,
        duration: 1,
        delay: 2.3,
      }
    );
  }

  return timeline;
}
