import { Brand } from '@/interfaces/Brand';

const brand: Brand = {
	id: 'marie-adam-leenaerdt',
	name: 'Marie Adam-Leenaerdt',
	designers: [
		{
			id: 'marie-adam-leenaerdt',
			name: 'Marie Adam-Leenaerdt',
		},
	],
	components: [
		{
			componentId: 'DesignerVideoBlock',
			content: {
				id: 'video1',
				videoSrc: '/videos/marie-adam-leenaerdt/walk.mp4',
			},
		},
		{
			componentId: 'DesignerShowroom',
			content: {
				id: 'showroom1',
			},
		},
		{
			componentId: 'DesignerCollection',
			content: {
				id: 'collection1',
			},
		},
		{
			componentId: 'DesignerCollection',
			content: {
				id: 'collection2',
			},
		},
		{
			componentId: 'DesignerVideoBlock',
			content: {
				id: 'video2',
				videoSrc: '/videos/marie-adam-leenaerdt/brand.mp4',
			},
		},
	],
};
export default brand;
