const sides = {
  CENTER: "CENTER",
  EAST: "EAST",
  SOUTH_EAST: "SOUTH-EAST",
  SOUTH: "SOUTH",
  SOUTH_WEST: "SOUTH-WEST",
  WEST: "WEST",
  NORTH_WEST: "NORTH-WEST",
  NORTH: "NORTH",
  NORTH_EAST: "NORTH-EAST",
};

export default sides;
