"use client";

export interface DeviceProperties {
  size: { width: number; height: number };
  isIOS: boolean;
  isAndroid: boolean;
  isDesktop: boolean;
  isLandscape: boolean;
  isMobileLayout: boolean;
  isDesktopLayout: boolean;
  isTabletLayout: boolean;
  isTouch: () => boolean;
  isTouchEnabled: () => boolean;
}

const getDeviceProperties = (): DeviceProperties => {
  const size = { width: 0, height: 0 };
  let isIOS = false;
  let isAndroid = false;

  if (typeof window !== "undefined") {
    size.width = window.innerWidth;
    size.height = window.innerHeight;
    isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    isAndroid = /Android/.test(navigator.userAgent);
  }

  const isLandscape = size.width > size.height;
  const isDesktop = !isIOS && !isAndroid && isLandscape;

  // TODO: Retrieve "sm" and "md" breakpoint value from breakpoint.ts
  const isMobileLayout = size.width < 600;
  const isDesktopLayout = size.width >= 900;
  const isTabletLayout = !isMobileLayout && !isDesktopLayout;

  const isTouch = () => {
    return !isDesktop;
  };

  // TODO: Check dependencies and merge isTouch and isTouchEnabled properties.
  const isTouchEnabled = () => {
    return ('ontouchstart' in window) || 
           (navigator.maxTouchPoints > 0);
  };

  return {
    size,
    isIOS,
    isAndroid,
    isDesktop,
    isLandscape,
    isMobileLayout,
    isDesktopLayout,
    isTabletLayout,
    isTouch,
    isTouchEnabled
  };
};

export default getDeviceProperties;
