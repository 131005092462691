import type { ChangeTransitionState } from '../composables/useChangeTransitionState';
import type { TransitionController } from '../../core';

export interface TransitionComponentProps {
  canTransitionIn?: boolean;
  isVisible?: ChangeTransitionState;
  transitionOnMount?: boolean;
  disableTriggerInOnScroll?: boolean;
  mountTransitionDelay?: number;
  scrollTriggerOnce?: boolean;
}

export const defaultCanTransitionIn = true;
export const defaultDisableTriggerInOnScroll = true;

export const transitionComponentDefaults = {
  canTransitionIn: defaultCanTransitionIn,
  disableTriggerInOnScroll: defaultDisableTriggerInOnScroll,
  isVisible: () => ({
    onMount: false,
    value: false,
  }),
};

export type TransitionComponentRef = {
  transitionController: TransitionController;
};
