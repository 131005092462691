/**
 *
 * @EXAMPLE 1
 * const elementRef = Ref<HTMLDivElement>(null);
 * const transitionController = useTransitionController(
 *   () => ({
 *     ref: elementRef,
 *     refs: {
 *       elementRef,
 *     },
 *     setupTransitionInTimeline,
 *   }),
 * );
 *
 * const { inView } = useScrollTrigger({
 *   trigger: elementRef,
 *   transition: {
 *     controller: transitionController,
 *   },
 *   variables:{
 *     onEnter(){
 *       console.log('enter');
 *     },
 *   },
 * });
 *
 * @EXAMPLE 2
 * const elementRef = Ref<HTMLDivElement>(null);
 * const timeline = Ref(gsap.timeline({paused: true}));
 *
 * const { inView } = useScrollTrigger({
 *   trigger: elementRef,
 *   animation: timeline.value,
 *   variables:{
 *     onEnter(){
 *       console.log('enter');
 *     },
 *   },
 * });
 *
 * watch(inView, () => {
 *   console.log(inView);
 *
 * });
 */
import type ScrollTrigger from "gsap/ScrollTrigger";
import type {
  TransitionController,
  TransitionDirection,
} from "@/utils/transition-controller/core";
import { createScrollTrigger } from "@/utils/scroll/scrollTrigger.utils";
import { resetTime } from "@/utils/transition-controller/core/utils/timeline.utils";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useAppStore } from "@/store";

export type TriggerState =
  | "onEnter"
  | "onLeave"
  | "onLeaveBack"
  | "onEnterBack";

export type OptionsType = {
  trigger: any;
  animation?: any;
  transition?: {
    controller: TransitionController;
    direction?: TransitionDirection;
  };
  pin?: any;
  endTrigger?: any;
  variables?: ScrollTrigger.Vars;
  disabled?: boolean;
  transitionInDelay?: number;
  reverseOnLeaveBack?: boolean;
  canTransitionIn?: boolean;
};

export default function useScrollTrigger(options: OptionsType): {
  inView: RefObject<boolean>;
} {
  const inView = useRef<boolean>(false);
  const canTransitionIn = useRef(options.canTransitionIn);

  const {
    animation,
    trigger,
    pin,
    endTrigger,
    variables,
    transition,
    disabled,
    reverseOnLeaveBack,
    transitionInDelay,
  } = options;
  const app = useAppStore();
  // const {$scroller} = useNuxtApp()
  // const scrollContext = injectStrict(CustomScrollerKey);
  const triggerState = useRef<TriggerState>();
  const scrollTrigger = useRef<ScrollTrigger>();
  const timeline = useRef<gsap.core.Timeline | undefined>(undefined);

  const onCanTransitionInChange = useCallback(() => {
    if (
      !variables?.scrub &&
      (triggerState.current === "onEnter" ||
        triggerState.current === "onEnterBack")
    ) {
      timeline?.current?.paused(
        !(typeof options?.canTransitionIn === "boolean"
          ? canTransitionIn.current
          : true)
      );
    }
  }, [options?.canTransitionIn, triggerState, variables?.scrub]);

  useEffect(() => {
    onCanTransitionInChange();
  }, [onCanTransitionInChange]);

  const onTriggerStateChange = useCallback(() => {
    // Reset the timeline, so it can be re-triggered again when it hits the viewport from a normal enter flow.
    const reTriggerTimeline =
      !variables?.scrub && !variables?.once && !reverseOnLeaveBack;

    if (triggerState.current === "onEnter") {
      if (reTriggerTimeline)
        timeline?.current?.paused(
          !(typeof options?.canTransitionIn === "boolean"
            ? canTransitionIn.current
            : true)
        );
    }

    if (triggerState.current === "onLeaveBack") {
      if (reTriggerTimeline) {
        resetTime(timeline?.current, true, -(transitionInDelay || 0));
      }
      if (reverseOnLeaveBack) timeline?.current?.reverse();
    }
  }, [
    options?.canTransitionIn,
    reverseOnLeaveBack,
    transitionInDelay,
    variables?.once,
    variables?.scrub,
  ]);

  useEffect(() => {
    onTriggerStateChange();
  }, [onTriggerStateChange, triggerState]);

  useEffect(() => {
    // setTimeout(() => {
    if (!disabled && trigger.current) {
      timeline.current =
        (transition?.controller?.getTimeline(transition?.direction || "in") ??
          animation?.current) ||
        undefined;

      if (timeline.current && typeof transitionInDelay === "number") {
        timeline.current?.delay(transitionInDelay);
      }

      let pinType: "fixed" | "transform" | undefined;
      if (variables?.pinType) {
        pinType = app.scroller ? "transform" : "fixed";
        // pinType = "fixed";
      }

      const scrollTriggerVariables = {
        // scroller: scrollContext?.getScroller(),
        pin: pin?.current,
        endTrigger: endTrigger?.current,
        ...variables,
        pinType,
      };

      scrollTriggerVariables.onEnter = (data) => {
        inView.current = true;
        triggerState.current = "onEnter";
        if (variables?.onEnter) variables?.onEnter(data);
        onTriggerStateChange();
      };

      scrollTriggerVariables.onLeave = (data) => {
        inView.current = false;
        if (variables?.onLeave) variables?.onLeave(data);
      };

      scrollTriggerVariables.onEnterBack = (data) => {
        inView.current = true;
        if (variables?.onEnterBack) variables?.onEnterBack(data);
      };

      scrollTriggerVariables.onLeaveBack = (data) => {
        inView.current = false;
        triggerState.current = "onLeaveBack";
        if (variables?.onLeaveBack) variables?.onLeaveBack(data);
        onTriggerStateChange();
      };

      scrollTrigger.current = createScrollTrigger(
        // $ScrollTrigger,
        trigger.current,
        timeline.current,
        scrollTriggerVariables
      );
    }
    // });
    return () => {
      if (scrollTrigger.current) {
        scrollTrigger?.current?.kill();
      }
    };
  }, []);

  return {
    inView,
  };
}
