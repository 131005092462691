import { useEffect } from "react";
import type { GuardFunction, SetupTransitionOptions } from "../../core";
import { useTransitionController } from "./useTransitionController";
import { useLocation } from "react-router-dom";

/**
 * Creates gsap.core.Timeline that will start as soon the component is mounted
 *
 * @example
 * const elementRef = ref<HTMLDivElement>(null);
 *
 * usePageTransition({
 *  beforeTransitionOut: (release) => {
 *    release();
 *  },
 *  beforeTransitionIn: (release) => {
 *    release();
 *  },
 *  crossFlow: ()=> false,
 *  setupOptions: () => ({
 *    ref: elementRef,
 *    refs: {
 *      elementRef,
 *    },
 *    setupTransitionInTimeline,
 *    setupTransitionOutTimeline,
 *  }),
 * });
 */
export function usePageTransition<T>(options: {
  setupOptions: () => SetupTransitionOptions<T>;
  beforeTransitionIn?: GuardFunction;
  beforeTransitionOut?: GuardFunction;
  crossFlow: () => boolean;
  isLayout?: () => boolean;
}): void {
  const transitionController = useTransitionController(options.setupOptions);
  const location = useLocation();
  //const provider = useFlowProvider();

  useEffect(() => {
    // execute on location change
    transitionController?.transitionIn();
  }, [location.pathname]);

  /* onBeforeRouteLeave((to, from, next) => {
    provider.setLeaveToRoute(to);
    provider.setLeaveFromRoute(from);

    guard(
      () =>
        provider.start(
          transitionController,
          options.crossFlow(),
          next // , to, from
        ),
      options.beforeTransitionOut
    );
  }); */
}
