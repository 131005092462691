import { AbstractCtaProps, ButtonType } from "@/types";
import { useMemo } from "react";

export function useCta(props: AbstractCtaProps): {
  target: string | undefined;
  url: string | undefined;
  rel: string | undefined;
  disabled?: boolean;
  buttonType?: ButtonType;
  handleClick: (event: Event) => void;
  ariaLabel?: string;
  title?: string;
  isExternalUrl: boolean;
} {
  const buttonType = !props.href ? props.type || "button" : undefined;

  const title = props.title || props.label;
  const ariaLabel = props.ariaLabel ?? props.label;

  const isExternalUrl = useMemo(() => {
    return (
      typeof props.href === "string" &&
      (props.href.startsWith("http") || props.href.startsWith("www."))
    );
  }, [props.href]);

  const target = useMemo(() => {
    let target = props.target || "_self";
    // Force target=_blank when it's an external URL.
    if (isExternalUrl) {
      target = "_blank";
    }

    return target;
  }, [props.target, isExternalUrl]);

  const rel = useMemo(() => {
    // Force a noooper rel when it's an external-link
    if (target === "_blank") {
      return "noopener noreferrer";
    }
    return undefined;
  }, [target]);

  const url = useMemo(() => {
    let url = props.href;

    if (!isExternalUrl && props.href) {
      url = props.href;
    }
    // Is External URL
    else if (isExternalUrl) {
      url = props.href;

      // Add missing 'https://' at start of URL if it's starting with www.
      if (typeof props?.href === "string" && props?.href.startsWith("www.")) {
        url = `https://${props.href}`;
      }
    }

    return url;
  }, [isExternalUrl, props.href]);

  function handleClick(event?: Event): void {
    if (!isExternalUrl && props.href) {
      event?.preventDefault();
      // router.push({ path: url as string });
    }
  }

  return {
    target,
    url,
    rel,
    // disabled,
    buttonType,
    handleClick,
    ariaLabel,
    title,
    isExternalUrl,
  };
}
