import {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
  useRef,
} from 'react';
import { flushSync } from 'react-dom';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { debounce } from 'underscore';
import Footer from '@/components/app/Footer';
import IntroPage from '@/components/app/IntroPage';
import DesignerPage from '@/components/designer/DesignerPage';
import NavGrid from '@/components/designer/NavGrid';
import Grid from '@/components/grid/Grid';
import Preloader from '@/components/grid/Preloader';
import BackgroundContainer from '@/components/designer/BackgroundContainer';
import brands from '@/config/brands';
import AppContext from '@/context/AppContext';
import useDeviceProperties from '@/hooks/useDeviceProperties';
import { media } from '@/utils/breakpoints';
import GlobalStyle from '@/styles/globals';
import '@/config/i18n';
import { useAppStore, useBrowserStore } from '@/store';
import ResizeService from '@/services/ResizeService';
import TheSmoothScroll from '@/components/app/TheSmoothScroll';
import { deeplinking } from '@/utils/deeplink';
import RotationMessage from '@/components/app/RotationMessage';
import LowBatteryMessage from '@/components/app/LowBatteryMessage';
import ls from 'localstorage-slim';
import { STATUS_KEY } from '@/constants/global';
import DesignerHeader from '@/components/designer/DesignerHeader';

const MIN_PROGRESS_INTERVAL = 200;
const IS_NOT_SCROLLING_DELAY = 300;

const ENCRYPTION_ENABLED = false;
const DEFAULT_VOTED_STATUS = false;
const savedStatus = ls.get(STATUS_KEY, { decrypt: ENCRYPTION_ENABLED });

const PageWrapper = styled.main`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;

  ${media.sm`
  `}
`;

type BrandIdState = { active: string; previous: string };
type BrandIdAction = { type: 'update'; next: string };

// TODO: Find a less confusing way of properly preloading the faces in /vote. At the moment, we call
// handleFaceLoaded in both <VotePopup> and <Grid>, with conditions so as not call it twice. This
// doesn't seem to induce bugs, but this has to be further investigated.

function brandIdReducer(
  state: BrandIdState,
  action: BrandIdAction
): BrandIdState {
  switch (action.type) {
    case 'update': {
      if (state.active.length > 0 && state.active !== action.next) {
        state.previous = state.active;
      }
      state.active = action.next;
    }
  }
  return { ...state };
}

function App() {
  function openVoteModal() {
    // setIsVoting(true);
    navigate('/vote');
    setPreviousPathname(location.pathname);
  }

  function closeVoteModal() {
    // setIsVoting(false);
    navigate(previousPathname);
  }

  const device = useDeviceProperties();
  const location = useLocation();
  const pageLoaded = useAppStore.use.pageLoaded();

  const updateBrowserStore = useBrowserStore.use.update();
  const [brandsLoaded, setBrandsLoaded] = useState<{ [key: string]: string }>(
    {}
  );
  const [progress, setProgress] = useState(0);
  const [screenSize, setScreenSize] = useState({ width: 0 });
  const [isScrolling, setIsScrolling] = useState(false);
  // const [isVoting, setIsVoting] = useState(false);
  const [brandIdState, dispatchBrandIdState] = useReducer(brandIdReducer, {
    active: '',
    previous: '',
  });
  const [hasVoted, setHasVoted] = useState(DEFAULT_VOTED_STATUS);

  const designerId = useAppStore.use.designerId();
  const introHeaderRef = useRef<typeof DesignerHeader>(null);

  const navigate = useNavigate();
  const [previousPathname, setPreviousPathname] = useState('/');

  const debouncedDisableIsScrolling = useMemo(() => {
    return debounce(() => setIsScrolling(false), IS_NOT_SCROLLING_DELAY);
  }, []);

  const animatePresenceMode = useMemo(() => {
    return location.pathname !== '/' && location.pathname !== '/vote'
      ? 'sync'
      : 'wait';
  }, [location.pathname]);

  //
  // H A N D L E R S
  //
  const updateVotedStatus = () => {
    setHasVoted(true);
    ls.set(STATUS_KEY, true, { encrypt: ENCRYPTION_ENABLED });
  };

  const handleFaceLoaded = useCallback((brandId: string) => {
    flushSync(() => {
      setBrandsLoaded((brandsLoaded) => ({
        ...brandsLoaded,
        [brandId]: brandId,
      }));
    });
  }, []);

  const handleFaceHover = useCallback(
    (brandId: string) => {
      if (location.pathname === '/') {
        dispatchBrandIdState({ type: 'update', next: brandId });
      }
    },
    [location.pathname]
  );

  const handleGridScroll = useCallback(() => {
    setIsScrolling(true);
    debouncedDisableIsScrolling();
  }, [debouncedDisableIsScrolling]);

  //
  // E F F E C T S
  //

  useEffect(() => {
    setTimeout(() => {
      //*
      setProgress(Object.keys(brandsLoaded).length / brands.length);
      /*/// - Test preloader
      setProgress(0.95);
      //*/
    }, Object.keys(brandsLoaded).length * MIN_PROGRESS_INTERVAL);
  }, [brandsLoaded]);

  useEffect(() => {
    // @ts-expect-error
    ResizeService.triggerResize();
    const onResize = () => {
      updateBrowserStore();
      setScreenSize({ width: window.innerWidth });
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [updateBrowserStore]);

  useEffect(() => {
    // Remove the / at the beginning of the pathname
    dispatchBrandIdState({
      type: 'update',
      next: document.location.pathname.slice(1),
    });

    //
    // R E N D E R I N G
    //
    if (location.pathname !== '/') {
      const path = location.pathname.slice(1).split('/');
      const data = { page: path[0], name: path[1] };
      window.parent.postMessage(data, '*');
    }
  }, [document.location.pathname]);

  useEffect(() => {
    if (document.location.search !== '/' && document.location.search !== '') {
      const path = deeplinking(document.location.search).split('/');
      if (path[0]) {
        const data = { page: path[0], name: path[1] };
        window.parent.postMessage(data, '*');
        // -
        navigate('/' + path[0]);
      }
    }
  }, [document.location.search]);

  // Initialise hasVoted state
  // Note: Page crashes if we initialise the state to the local storage value.
  useEffect(() => {
    if (typeof savedStatus === 'boolean') setHasVoted(savedStatus);
  }, []);

  //
  // R E N D E R I N G
  //
  return (
    <AppContext.Provider
      value={{
        device,
        screenSize,
        activeBrandId: brandIdState.active,
        previousBrandId: brandIdState.previous,
      }}
    >
      <GlobalStyle />
      <PageWrapper>
        <BackgroundContainer
          className="video-background"
          shouldPlayOnScroll={location.pathname !== '/'}
          shouldLoad={pageLoaded}
        />

        {location.pathname !== '/' && location.pathname !== '/vote' && (
          <NavGrid className="navigation-bar" onFaceLoaded={handleFaceLoaded} />
        )}

        {location.pathname !== '/' && designerId && designerId !== '' && (
          <DesignerHeader
            ref={introHeaderRef}
            className="designer-header"
            designerId={designerId}
            inverted={false}
            leaveProgress={0}
          />
        )}

        <TheSmoothScroll smooth={1} paused={location.pathname === '/'}>
          <AnimatePresence mode="sync">
            <Routes location={location} key={location.pathname}>
              <Route
                path="/"
                element={<IntroPage isScrolling={isScrolling} />}
              />
              {brands.map((brand, brandIndex) => (
                <Route
                  key={brandIndex}
                  path={'/' + brand.id}
                  element={<DesignerPage brand={brand} />}
                />
              ))}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </AnimatePresence>
        </TheSmoothScroll>

        {location.pathname === '/' && (
          <Grid
            className="grid"
            isScrolling={isScrolling}
            onGridScroll={handleGridScroll}
            onFaceLoaded={handleFaceLoaded}
            onFaceHover={handleFaceHover}
          />
        )}

        <Preloader
          className="preloader"
          progress={progress}
          brands={brands}
          isDesktop={device.isDesktop}
        />

        <Footer className="footer" showGraduates={false} />
        <LowBatteryMessage />
        <RotationMessage />
      </PageWrapper>
    </AppContext.Provider>
  );
}

export default App;
