import { useAppStore, useBrowserStore } from "@/store";
import { PropsWithChildren, useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import useResize from "@/hooks/useResize";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollSmoother);

type TheSmoothScrollProps = {
  smooth: number | boolean | undefined;
  paused: boolean;
};

const StyledTheSmoothScroll = styled.div``;

const TheSmoothScroll = (props: PropsWithChildren<TheSmoothScrollProps>) => {
  const isIos = useBrowserStore.use.isIos();
  const isTouch = useBrowserStore.use.isTouch();
  const orientation = useBrowserStore.use.orientation();
  const scroller = useAppStore.use.scroller();
  const scrollerLocked = useAppStore.use.scrollLocked();
  const toggleScrollerPause = useAppStore.use.toggleScrollerPause();
  const setScrollY = useAppStore.use.setScrollY();
  const setScroller = useAppStore.use.setScroller();
  const setProgress = useAppStore.use.setProgress();

  const refWrapper = useRef<HTMLDivElement>(null);
  const refContent = useRef<HTMLDivElement>(null);

  const scrollerRefLocked = useRef(scrollerLocked)


  useEffect(() => {
    // Note: This is a port from LVMH0023. The GSAP doc doesn't specify that ScrollSmoother is
    // incompatible with iOS. Is there a reason for this condition?
    if (isIos()) {
      toggleScrollerPause(true);
      return;
    }

    if (!scroller) {
      setScroller(
        ScrollSmoother.create({
          wrapper: refWrapper.current,
          content: refContent.current,
          smooth: props.smooth,
          normalizeScroll: true,
          effects: !isTouch(),
          smoothTouch: 0.01,
          onUpdate: (scroller: ScrollSmoother) => {
            setProgress(scroller.progress);
            setScrollY(scroller.scrollTop());
          },
        })
      );

      toggleScrollerPause(false);
    }

    return () => {
      scroller?.kill();
    };
  }, [
    isIos,
    isTouch,
    props.smooth,
    scroller,
    setProgress,
    setScrollY,
    setScroller,
    toggleScrollerPause,
  ]);

  useEffect(() => {

    if (scroller) {
      scroller.paused(props.paused);
    }
  }, [scroller, props.paused]);

  useEffect(() => {
    scrollerRefLocked.current = scrollerLocked;
  }, [scrollerLocked]);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [orientation])


  // this fix layout issues when the scroll is paused
  useResize(() => {
    if (scrollerRefLocked.current) {
      toggleScrollerPause(false);
      ScrollTrigger.refresh();
      toggleScrollerPause(true);
    }
  });

  return (
    <StyledTheSmoothScroll ref={refWrapper}>
      <div className={"smooth-content"} ref={refContent}>
        {props.children}
      </div>
    </StyledTheSmoothScroll>
  );
};

export default TheSmoothScroll;
