import styled from 'styled-components';
import Image from '@/components/app/Image';
import {
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useRef,
} from 'react';
import { aspectRatioSize, fillContainer } from '@/styles/mixins';
import { mediaDown } from '@/utils/breakpoints';

declare interface DesignerCollectionItemProps {
  src: string;
  altText: string;
  className: string;
  dataSpeed?: string;
  dataLag?: string;
}

export interface ChildRef {
  elementRef: HTMLDivElement;
  innerContentRef: HTMLDivElement;
}

const StyledDesignerCollectionItem = styled.div`
  ${aspectRatioSize({ width: 487, height: 547 })};
  max-width: 48.7rem;
  width: 25vw;
  background-color: var(--cl-white);
  border: 1px solid var(--cl-black);
  overflow: hidden;

  .inner-content {
    ${fillContainer({ offset: '0', position: 'absolute' })}
  }

  ${mediaDown.md2`
    width: calc(100vw - 7rem);

    .image{
      width: calc(100% + 2px);
    }
  `}
`;

const DesignerCollectionItem = forwardRef(
  (
    {
      src,
      altText,
      className,
      dataSpeed,
      dataLag,
    }: PropsWithChildren<DesignerCollectionItemProps>,
    ref
  ) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const innerContentRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(
      ref,
      (): ChildRef => ({
        elementRef: elementRef.current as HTMLDivElement,
        innerContentRef: innerContentRef.current as HTMLDivElement,
      }),
      []
    );

    return (
      <StyledDesignerCollectionItem
        ref={elementRef}
        className={className}
        data-speed={dataSpeed}
        data-lag={dataLag}
      >
        <div ref={innerContentRef} className={'inner-content'}>
          <Image
            objectFit={'cover'}
            className={'image'}
            src={src}
            alt={altText}
          />
        </div>
      </StyledDesignerCollectionItem>
    );
  }
);

export default DesignerCollectionItem;
