export const DEG_TO_RAD = Math.PI / 180
export const RAD_TO_DEG = 180 / Math.PI

export const isOdd = int => {
  return int % 2
}

export const clamp = (num, min = 0, max = 1) => {
  if (num < min) return min
  if (num > max) return max
  return num
}

export const roundToPlaces = (value, decimalPlaces) => {
  const roundMultiplier = Math.pow(10, decimalPlaces)
  return Math.round(value * roundMultiplier) / roundMultiplier
}

export const timeFormat = secs => {
  let secNum = parseInt(secs, 10) // don't forget the second param
  let hours = Math.floor(secNum / 3600)
  let minutes = Math.floor((secNum - hours * 3600) / 60)
  let seconds = secNum - hours * 3600 - minutes * 60

  if (hours < 10) hours = `0 ${hours}`
  if (minutes < 10) minutes = `0 ${minutes}`
  if (seconds < 10) seconds = `0 ${seconds}`
  return `${minutes}:${seconds}`
}

//v = 0:00
export const minutesFromColonFormat = v => {
  if (!v || !v.split) {
    return 0
  }

  let segments = v.split(':')
  if (segments.length === 1) return parseInt(segments[0])

  let val = parseInt(segments[0]) * 60 + parseInt(segments[1])
  return val || 0
}

export const minutes_to_ISO_8601 = m => {
  var days = Math.floor(m / 1440)
  m = m - days * 1440
  var hours = Math.floor(m / 60)
  m = m - hours * 60

  var dur = 'PT'
  if (days > 0) {
    dur += days + 'D'
  }
  if (hours > 0) {
    dur += hours + 'H'
  }
  dur += m + 'M'
  return dur
}

/* POINTS */

export const lerpPoint = (sourcePoint, targetPoint, blendAmount) => {
  let point = {}
  point.x = sourcePoint.x + blendAmount * (targetPoint.x - sourcePoint.x)
  point.y = sourcePoint.y + blendAmount * (targetPoint.y - sourcePoint.y)
  return point
}

export const copyPoint = sourcePoint => {
  return {
    x: sourcePoint.x,
    y: sourcePoint.y,
  }
}

export const getPointDistance = (sourcePoint, targetPoint) => {
  const deltaX = getDistance(targetPoint.x, sourcePoint.x)
  const deltaY = getDistance(targetPoint.y, sourcePoint.y)
  return Math.sqrt(deltaX * deltaX + deltaY * deltaY)
}

export const getDistance = (valueA, valueB) => {
  return valueB - valueA
}

export const getRotatedPoint = (point, angle) => {
  const angleRad = angle * DEG_TO_RAD
  let rotatedPoint = {}
  rotatedPoint.x = point.x * Math.cos(angleRad) + point.y * Math.sin(angleRad)
  rotatedPoint.y = point.y * Math.cos(angleRad) - point.x * Math.sin(angleRad)
  return rotatedPoint
}

/* ANGLES */

export const toRadians = value => {
  return value * DEG_TO_RAD
}

export const toDeg = value => {
  return value * RAD_TO_DEG
}

export const getProjectionOfAngle = angle => {
  const point = {
    x: Math.sin(angle * DEG_TO_RAD),
    y: -Math.cos(angle * DEG_TO_RAD),
  }
  return point
}

export const getAngle = (deltaY, deltaX) => {
  const rad = Math.atan2(deltaX, -deltaY)
  return rad * RAD_TO_DEG
}

export const getAngleBetweenPoints = (sourcePoint, targetPoint) => {
  const deltaX = targetPoint.x - sourcePoint.x
  const deltaY = targetPoint.y - sourcePoint.y
  return getAngle(deltaY, deltaX)
}

/* RANDOM */

/*
this does not create correct distrubtions for integers between start and
end ints (more inclined to round to center numbers, below function more balanced)
*/
export const randomIntInRange = (int1, int2) => {
  return Math.round(int1 + Math.random() * (int2 - int1))
}

export const randomIntRange = (int1, int2) => {
  return Math.floor(int1 + Math.random() * (int2 - int1 + 0.99999))
}

export const randomInRange = (num1, num2) => {
  return num1 + Math.random() * (num2 - num1)
}

export const randomDist = (min, max, iterations) => {
  let total = 0
  for (var i = 0; i < iterations; i += 1) {
    total += randomInRange(min, max)
  }
  return total / iterations
}

export const randomSign = () => {
  return Math.random() < 0.5 ? -1 : 1
}

/* MAPPING */

export const norm = (value, min, max) => {
  return (value - min) / (max - min)
}

export const lerp = (unitary, num1, num2) => {
  return num1 + unitary * (num2 - num1)
}

export const mix = (num1, num2, amount) => {
  return num1 + amount * (num2 - num1)
}

export const map = (value, sourceMin, sourceMax, destMin = 0, destMax = 1, doClamp = true) => {
  value = lerp(norm(value, sourceMin, sourceMax), destMin, destMax)
  if (doClamp) value = clamp(value, destMin, destMax)
  return value
}

export const mapTo = (value, destMin, destMax, doClamp = true) => {
  value = lerp(value, destMin, destMax)
  if (doClamp) value = clamp(value, destMin, destMax)
  return value
}

export const sinBlend = value => {
  return 0.5 - 0.5 * Math.cos(value * Math.PI)
}

export const mapFrom = (value, sourceMin, sourceMax, doClamp = true) => {
  value = lerp(norm(value, sourceMin, sourceMax), 0, 1)
  if (doClamp) value = clamp(value, 0, 1)
  return value
}

/*
  Calculate the dimensions and position of a source rectangle to 'cover' the destination rectangle
  ---
  sW          Number      width of the source rectangle
  sH          Number      height of the source rectangle
  dW          Number      width of the destination (parent) rectangle
  dH          Number      height of the destination (parent) rectangle
  --
  Returns     Object      Object containing width, height, left and top values
*/
/**
 * Calculate the dimensions and position of a source rectangle to 'cover' the destination rectangle
 * @param {number} sW width of the source (child) rectangle
 * @param {number} sH height of the source (child) rectangle
 * @param {number} dW width of the destination (parent) rectangle
 * @param {number} dH height of the destination (parent) rectangle
 * @returns {Object} Object containing width, height, left and top values
 */
export const rectangleCover = (sW, sH, dW, dH) => {
  var sRatio = sW / sH
  var dRatio = dW / dH

  var width, height
  var w, h, l, t

  // Fit to width
  if (sRatio < dRatio) {
    width = dW
    height = dW / sRatio

    w = width
    h = height
    l = 0
    t = (height - dH) * -0.5
  }
  // Fit to height
  else {
    width = dH * sRatio
    height = dH

    w = width
    h = height
    l = (width - dW) * -0.5
    t = 0
  }

  return { width: w, height: h, left: l, top: t }
}

/**
 * Calculate an update step between a target and current value using spring physics
 * https://blog.maximeheckel.com/posts/the-physics-behind-spring-animations
 * @param {number} target       Target value being moved towards
 * @param {number} current      Current value, as stored from last step
 * @param {number} velocity     Current velocity, as stored from last step
 * @param {number} damping      Normalized damping factor (determines how "springy" movement is, with lower numbers taking longer to arrive at equilibrium)
 * @param {number} tension      Normalized tension factor (determines how "stiff" the spring is, with lower numbers moving more slowly)
 * @param {number} deltaFactor  Current framerate scale of a 60fps base (e.g. 120fps = 0.5, 30fps = 2.0), ensures the same velocity regardless of framerate
 * @returns {Object}            The updated `current` and `velocity` values, these should be stored for calculation on next step
 */
export const springStep = (
  target,
  current,
  velocity,
  damping = 0.3,
  tension = 0.1,
  deltaFactor = 1
) => {
  const PRECISION = 0.0001
  const velocityStep = (velocity + (target - current) * tension) * (1 - damping)
  const currentStep =
    Math.round((current + velocityStep * deltaFactor) * (1 / PRECISION)) / (1 / PRECISION)
  return { current: currentStep, velocity: velocityStep }
}

export const modulo = function (value, length) {
  return ((value % length) + length) % length
}
